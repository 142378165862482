import { Component, Input, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ParticipantChoice } from 'src/app/booking/booking.model';
import { getChoiceKey } from './participant-selection.utils';
import { Choice } from 'src/app/events/choice.model';

@Component({
  selector: 'app-participant-selection',
  templateUrl: './participant-selection.component.html',
  styleUrls: ['./participant-selection.component.scss']
})
export class ParticipantSelectionComponent implements OnInit {
  @Input() enabled: boolean = true; // TODO: not needed? (managed by FormGroup object)
  @Input() form: FormGroup;  // Entrypoint for consuming selected values

  @Input() participantIndex: number;
  @Input() participantChoice: ParticipantChoice;

  public readonly NO_PREFERENCE = Choice.NO_PREFERENCE;

  ngOnInit(): void {
    if(!this.participantChoice) {
      throw new Error("ParticipantChoice must be defined");
    }

    // TODO: validate that choices are not undefined (null is ok if choice is not required)
    if(!this.enabled) {
      this.form.disable();
    }
  }

  getChoiceKey(participantIndex: number, sectionIndex: number) {
    return getChoiceKey(participantIndex, sectionIndex);
  }

  getChoiceControl(participantIndex: number, sectionIndex: number) {
    const key = this.getChoiceKey(participantIndex, sectionIndex);
    return this.form.get(key);
  }

}
