import { Injectable } from "@angular/core";
import { Serializer } from "../shared/serializer";
import { ParticipantChoices, ParticipantChoicesSerializer } from "../booking/booking.model";
import { Event } from "../events/event.model";

type ApiResponseTable = {
    id: string
    people_nb: number
    reference_user_id: string
    joining_code: string
    created_datetime: string

    // NOT available when referenced in Payment and Booking
    event_id?: string
    schedule_id?: string
    schedule_start_datetime?: Date
    schedule_end_datetime?: Date
    schedule_max_people_nb?: number

    // number of participants that will raise a UI warning
    // (prompt: the restaurant will try its best to fulfill your request but your table might be split since it might be too big. Contact him directly to be sure you will all be sitting at the same table).
    booking_capacity_warning?: number

    // number of participants that will inhibit a booking (group too big)
    // max number of people that can be part of a table
    booking_capacity_deny?: number

    bookings?: {payment: {participants: ApiResponseParticipant[]}}[],
}

type ApiResponseParticipant = {
    alias?: string,
    order: number,
    choices: {
        id: string,
        name: string,
        description: string | null,
    }[],
};

export class Table {
    constructor(
        public id: string,
        public people_nb: number,
        public reference_user_id: string,
        public joining_code: string,
        public created_datetime: string,

        // NOT available when referenced in Payment and Booking
        public event_id?: string,
        public schedule_id?: string,
        public schedule_start_datetime?: Date,
        public schedule_end_datetime?: Date,
        public schedule_max_people_nb?: number,

        // number of participants that will raise a UI warning
        // (prompt: the restaurant will try its best to fulfill your request but your table might be split since it might be too big. Contact him directly to be sure you will all be sitting at the same table).
        public booking_capacity_warning?: number,

        // number of participants that will inhibit a booking (group too big)
        // max number of people that can be part of a table
        public booking_capacity_deny?: number,

        // choices for each participant
        public participant_choices: ParticipantChoices | null = null,
    ) { }
}

@Injectable({
    providedIn: 'root',
})
export class TableSerializer extends Serializer<ApiResponseTable, Table> {
    private _event: Event;

    /**
     * Tables are always fetched when associated to an event page.
     * This is needed to properly handle the menu choices and correctly display the section names.
     * @param event event to which the booking being deserialized refer to.
     */
    public set event(v : Event) {
        this._event = v;
    }


    public get event() : Event {
        return this._event;
    }

    
    constructor(
        private participantChoicesDeserializer: ParticipantChoicesSerializer
    ) {
      super();
    }
    

    deserialize(table: ApiResponseTable): Table {
        // combine all participants into a list from a list of bookings associated to the table
        const participants: ApiResponseParticipant[] = [];
        if(table.bookings) {
            for (const booking of table.bookings) {
                participants.push(...booking.payment.participants);
            }
        }

        const tableHasParticipants = participants.length > 0;

        if(tableHasParticipants && !this._event) throw new Error("Cannot deserialize a table with choices without a reference Event object");
        this.participantChoicesDeserializer.event = this.event;

        // FIXME: redirecting to a table belonging to a different event won't work if there are choices
        // (need reference to event object)
        if(tableHasParticipants && this._event.id !== table.event_id) throw new Error("Cannot deserialize a table with choices if it belongs to an event that is not the reference one");

        const participantChoices = tableHasParticipants ? this.participantChoicesDeserializer.deserialize(participants) : null;

        return new Table(
            table.id,
            table.people_nb,
            table.reference_user_id,
            table.joining_code,
            table.created_datetime,

            table.event_id,
            table.schedule_id,
            table.schedule_start_datetime,
            table.schedule_end_datetime,
            table.schedule_max_people_nb,

            table.booking_capacity_warning,
            table.booking_capacity_deny,

            participantChoices,
        );
    }
}
