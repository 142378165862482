import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { ParticipantChoice, ParticipantChoices } from 'src/app/booking/booking.model';
import { GtagService } from 'src/app/gtag/gtag.service';
import { BookingFlowService } from '../../booking-flow.service';
import { getChoiceKey, getParticipantKey } from '../participant-selection/participant-selection.utils';
import { Choice } from 'src/app/events/choice.model';

@Component({
  selector: 'app-participants-selection',
  templateUrl: './participants-selection.component.html',
  styleUrls: ['./participants-selection.component.scss']
})
export class ParticipantsSelectionComponent implements OnInit {

  @Input() enabled: boolean = true;
  @Input() participantChoices: ParticipantChoices;
  @Input() tableParticipantChoices?: ParticipantChoices = undefined;

  selectionForm: FormGroup;
  loading: boolean = false;

  constructor(
    private bookingFlowService: BookingFlowService,
    private gtagService: GtagService,
  ) { }

  get nbSelectedParticipants(): number {
    return this.participantChoices.participantChoices.length;
  }

  private buildParticipantFormControls(participantIndex: number, participantChoice: ParticipantChoice) {
    const formControls = {};
    const menu = participantChoice.menu;

    for (let sectionIndex = 0; sectionIndex < (menu?.sections || []).length; sectionIndex++) {
      const section = menu.sections[sectionIndex];

      // set value if already defined in state
      let value = participantChoice.getChoiceFor(sectionIndex);
      const key = getChoiceKey(participantIndex, sectionIndex);
      const validator = section.isChoiceRequired ? Validators.required : undefined;

      // NOTE: non-trivial constraints are checked via the form-level validator
      formControls[key] = new FormControl(value, validator);
    }

    return formControls;
  }

  private buildFormControls() {
    const formControls = {};

    // build form controls for each input (participant x choice to make)
    // each participant is handled by a different FormGroup (indexed by getParticipantKey(participantIndex))
    for (let participantIndex = 0; participantIndex < this.nbSelectedParticipants; participantIndex++) {
      const participantChoice = this.participantChoices.getParticipantChoice(participantIndex);
      formControls[this.getParticipantKey(participantIndex)] = new FormGroup(
        this.buildParticipantFormControls(
          participantIndex, participantChoice
        )
      );
    }

    return formControls;
  }

  // Form-level validator applying errors to individual form controls
  private createParticipantChoicesValidator(): ValidatorFn {
    return (selectionForm: AbstractControl) : ValidationErrors | null => {
        // perform validation of all form controls based on current selection

        // This methods applies the errors flagged by the ParticipanChoices object to the associated form inputs
        const errorsByParticipant = this.participantChoices.getErrors(this.tableParticipantChoices);

        for (let participantIndex = 0; participantIndex < this.nbSelectedParticipants; participantIndex++) {
          const menu = this.participantChoices.getParticipantChoice(participantIndex).menu;
          for (let sectionIndex = 0; sectionIndex < menu.sections.length; sectionIndex++) {
            const choice: Choice = this.participantChoices.getParticipantChoice(participantIndex).getChoiceFor(sectionIndex);

            const controlErrors = {};

            if(choice?.minPeopleNb > 1) {
              if(errorsByParticipant.has(participantIndex)) {
                const participantErrors = errorsByParticipant.get(participantIndex);

                if(participantErrors.errorsBySection.has(sectionIndex)) {
                  const sectionErrorCodes = participantErrors.errorsBySection.get(sectionIndex);
                  for (const errorKey of sectionErrorCodes) {
                    controlErrors[errorKey] = true;
                  }
                }
              }
            }

            const sectionControl = selectionForm.get(this.getParticipantKey(participantIndex)).get(getChoiceKey(participantIndex, sectionIndex));

            let errors = {};
            if(Object.keys(controlErrors).length > 0) {
              // add errors managed here
              errors = controlErrors;
            }

            if(Object.keys(errors).length > 0) {
              // at least an error
              
              // QUICKFIX: avoid Error: NG0100: ExpressionChangedAfterItHasBeenCheckedError
              //           raised when pre-selected values are part of input (control changes status due to validation)
              setTimeout(() => {
                sectionControl.setErrors(errors);
                sectionControl.markAsTouched();
              }, 0);
            } else {
              // no error, remove flag
              sectionControl.setErrors(null);
            }
          }
        }

        return null;
    }
  }

  ngOnInit(): void {
    const formControls = this.buildFormControls();
    this.selectionForm = new FormGroup(formControls, this.createParticipantChoicesValidator());

    // subscribe to each participant subform for scoped updates
    for (let participantIndex = 0; participantIndex < this.nbSelectedParticipants; participantIndex++) {
      const participantKey = this.getParticipantKey(participantIndex)
      const control = this.selectionForm.get(participantKey);

      if(!control) {
        throw new Error(`ERROR: could not find form control '${participantKey}' (this should never happen)`);
      }

      control.valueChanges.subscribe(values => {
        // update participant choice
        const participantChoice = this.participantChoices.getParticipantChoice(participantIndex);

        for (let sectionIndex = 0; sectionIndex < participantChoice.menu.sections.length; sectionIndex++) {
          const choice = values[getChoiceKey(participantIndex, sectionIndex)];
          participantChoice.setChoiceFor(
            sectionIndex, choice
          );
        }
      });
    }
  }

  getParticipantKey(participantIndex: number): string {
    return getParticipantKey(participantIndex);
  }

  isInvalidSelection() {
    return this.selectionForm.invalid;
  }

  previousStep() {
    // TODO: gTag
    this.bookingFlowService.previousStep();
  }

  nextStep() {
    // TODO: gTag

    // NOTE: state.selection is expected to be valid here
    this.bookingFlowService.nextStep();
  }

  counter(i: number) {
    return new Array(i);
  }
}
