<div xmlns="http://www.w3.org/1999/html">
  <form [formGroup]="selectForm">
    <div class="form-group">
      <label
        for="nb_participants"
        class="d-block heading text-uppercase font-weight-bold mt-4 mb-1"
        i18n="booking flow - form how many participants title"
        >How many?</label
      >
      <select
        id="nb_participants"
        class="form-control text-default font-weight-bold"
        (change)="setNbSelectedParticipants($event)"
        placeholder="Number of participants"
      >
        <option *ngFor="let in of counter(20); let i = index" [value]="i + 1" [defaultSelected]="state.nbSelectedParticipants === (i + 1)">{{ i + 1 }}</option>
      </select>
    </div>
    <div>
      <div
        class="alert alert-danger"
        *ngIf="isAnyScheduleBookableGivenUserInput() && !isGroupWithEnoughParticipants()"
      >
        <strong class="d-block" i18n="booking flow - min people nb per table error title">
          <i class="fa fa-users" aria-hidden="true"></i> Minimum {{ event.min_people_nb_per_table }} participants per group required.
        </strong>
        <span i18n="booking flow - min people nb per table error message">
          This restaurant requires at least {{ event.min_people_nb_per_table }} person per group.<br/>
          To continue with this reservation join an existing table or add more participants.
        </span>
      </div>
      <div
        class="alert alert-success fade show"
        *ngIf="isAnyScheduleBookableGivenUserInput() && state.discount.isIntermediateDiscount()"
      >
        <strong class="d-block" i18n="booking flow - form discount almost unlocked title">
          <i class="fa fa-thumbs-up" aria-hidden="true"></i> Almost there!
        </strong>
        <span i18n="booking flow - form discount almost unlocked message">
          This number of participants will unlock a new discount, <br />
          finalize the booking to pay only {{ state.discount.bookingPrice }} €!
        </span>
      </div>
      <div
        class="alert alert-success"
        *ngIf="isAnyScheduleBookableGivenUserInput() && event.next_deals.length > 0 && state.discount.isBestDiscount()"
      >
        <strong class="d-block" i18n="booking flow - form best discount almost unlocked title">
          <i class="fa fa-star" aria-hidden="true"></i> Congratulations!
        </strong>
        <span i18n="booking flow - form best discont almost unlocked message">
          This number of participants will unlock the <em>best discount</em>.
          Finalize the booking to pay only {{ state.discount.bookingPrice }} €!
        </span>
      </div>
      <div class="px-1">
        <small
          class="text-muted"
          *ngIf="state.discount.nextDeals.length > 0"
          i18n="booking flow - form people to next deal message"
        >
          <b>{{ state.discount.remainingPeopleForNextDeal }}</b> {state.discount.remainingPeopleForNextDeal, plural, =1 {person} other {people}} to unlock the next deal!
        </small>
      </div>
      <div
        class="alert alert-success"
        *ngIf="isAnyScheduleBookableGivenUserInput() && event.next_deals.length == 0"
      >
        <strong i18n="booking flow - form best discount unlocked title">
          <i class="fa fa-star" aria-hidden="true"></i> Best price achieved, congratulations!
        </strong>
        <span i18n="booking flow - form best discount unlocked message">This is the best price you can get!</span>
      </div>
    </div>
    <div class="form-group">
      <div class="d-block heading text-uppercase font-weight-bold mt-4 mb-2" i18n>
        When?
      </div>
      <app-booking-select-table
        [event]="event"
        [selectedTable]="state.selectedTable"
        (selectedTableEventEmitter)="selectTable($event)"
        (goToTableEventEventEmitter)="goToTableEvent($event)"
      ></app-booking-select-table>
      <div
        *ngIf="state.isTableAboveWarningThreshold() && !state.isTableAboveDenyThreshold()"
        class="m-2 text-warning"
        >
          <small i18n="Warning table above threshold">This table might be too big. Contact the restaurant to make sure you will sit together otherwise you might end up in 2 or more tables.</small>
      </div>
      <div
        *ngIf="state.isTableAboveDenyThreshold()"
        class="m-2 text-danger"
        >
          <!-- Does this make sense? The warning seems to be the same, anyway you can make a separate reservation with another table... -->
          <small i18n="Deny table too big">This table is too big and the restaurant cannot handle it.</small>
      </div>
      <app-booking-select-schedule
        [schedules]="event.schedules"
        [selectedSchedule]="state.selectedSchedule"
        [scheduleStatesMap]="scheduleStateMap"
        [nbSelectedParticipants]="state.nbSelectedParticipants"
        [disabled]="state.selectedTable !== null"
        (selectedScheduleEventEmitter)="selectSchedule($event)"
      ></app-booking-select-schedule>
      <ng-container *ngIf="state.selectedSchedule !== undefined">
        <div
        *ngIf="isScheduleBookableGivenUserInput(state.selectedSchedule)"
        class="m-2"
        [class.text-warning]="state.selectedSchedule.availablePlaces < 6"
        >
          <small i18n="Selected schedule places left">
            {{ state.selectedSchedule.availablePlaces }} {state.selectedSchedule.availablePlaces, plural, =1 {place left} other {places left}} at this time.
           </small>
        </div>
        <div
        *ngIf="isSelectedScheduleTooEarly()"
        class="m-2 text-danger"
        >
          <small i18n="Selected schedule is not yet available">This schedule cannot be reserved yet.</small>
        </div>
        <div
        *ngIf="isSelectedScheduleTooLate()"
        class="m-2 text-danger"
        >
          <small i18n="Selected schedule is not available anymore">This schedule cannot be reserved anymore.</small>
        </div>
        <div
        *ngIf="isSelectedScheduleFullGivenUserInput()"
        class="m-2 text-danger"
        >
          <small><span i18n="Selected schedule is full">There are not enough places for your selection.</span><span *ngIf="state.selectedTable === null" i18n="Selected schedule is full choose another time"> Please choose another time.</span></small>
        </div>
      </ng-container>
      <div
         *ngIf="!isAnyScheduleBookableGivenUserInput()"
         class="m-2 text-danger"
      >
          <small i18n="No valid schedule available">There are no schedule available for the selected number of participants, check for other events!</small>
      </div>
    </div>
    <div class="form-group mt-5">
      <label i18n="Notes title" for="choices-notes" class="heading text-uppercase font-weight-bold">Notes</label>
      <div>
        <textarea
          name="notesControl"
          formControlName="notesControl"
          class="form-control"
          [class.is-invalid]="selectForm.controls.notesControl.invalid"
          id="notes"
          rows="3"
          aria-describedby="choices-notes-desc"></textarea>
        <small i18n="Notes input description" id="choices-notes-desc" class="form-text text-muted">
          Please specify here preferences, intolerances, allergies or any other information you would like to share with the restaurant (<em>please contact the restaurant directly for any special request</em>)
        </small>

        <div class="invalid-feedback" *ngIf="selectForm.controls.notesControl.invalid">
          <span class="alert-inner--icon mr-2"><i class="fa fa-exclamation-circle"></i></span>
          <ng-container *ngIf="selectForm.controls.notesControl.hasError('required')" i18n="event choice mandatory - choice notes required">Please specify a menu preference in the notes.</ng-container>
        </div>
      </div>
    </div>
    <hr />
    <div class="container">
      <div class="clearfix">
        <div class="col-6 col-md-4 float-right">
          <button
            class="btn btn-primary btn-lg w-100"
            type="button"
            [disabled]="isInvalidSelection()"
            (click)="nextStep()"
            i18n="next"
          >
            Next<i class="fa fa-arrow-right pl-2" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </form>
</div>
