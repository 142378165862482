<div
  [routerLink]="['/events', event.id]"
  class="card shadow shadow-lg--hover mb-0 mb-lg-5 flex-fill border cursor-pointer"
  style="min-height: 18rem;"
>
  <div class="position-absolute" style="left: 5px; top: -15px; z-index: 1">
    <div class="position-relative" style="left: -20px">
      <span (click)="$event.stopPropagation()">
        <span class="h-100 rd-icon fa food-icon m-1 bg-secondary rounded shadow">
          <img src="{{ event.icon_url }}" alt="{{ event.icon }}" />
        </span>
      </span>
      <a
        *ngIf="event.is_takeaway"
        [routerLink]="['/events']"
        [queryParams]="{ is_takeaway: true }"
        (click)="$event.stopPropagation()"
        ><i class="fa fa-cube m-1 p-3 bg-info shadow rounded text-white"></i
      ></a>
      <a
        *ngIf="event.hot"
        title="hot deal"
        [routerLink]="['/events']"
        [queryParams]="{ hot: true }"
        (click)="$event.stopPropagation()"
        ><i class="fa fa-fire m-1 p-3 bg-danger shadow rounded text-white"></i
      ></a>
    </div>
  </div>
  <div class="card-body row p-0">
    <div class="col-md-5 pr-lg-0" [ngClass]="cssClassesLeftColumn" style="min-height: 14.5rem">
      <img
        class="w-100 h-100"
        style="object-fit: cover"
        src="{{ event.image_url }}"
        alt=""
      />
    </div>
    <div class="col-md-7" [ngClass]="cssClassesRightColumn">
      <div class="row h-100 mb-4 mx-3 mx-md-0">
        <div class="col pl-0 h-100">
          <div class="pl-0 pb-5 mb-2 pb-md-0">
            <h4 class="card-title text-primary mt-2 mb-0">
              {{ event.place.name }}
            </h4>
            <p class="text-muted small mb-0">
              <i class="fa fa-map-marker pr-2" aria-hidden="true"></i
              >{{ event.place.address }}
            </p>
            <p class="text-muted small">
              <i class="fa fa-tag pr-2" aria-hidden="true"></i
              >{{ event.short_description }}
            </p>
            <div class="mt-1 mb-3">
              <a
                *ngFor="let tag of event.tags"
                class="text-dark"
                [routerLink]="['/events']"
                [queryParams]="{ category: tag.slug }"
                (click)="$event.stopPropagation()"
              >
                #{{ tag.name.toLocaleLowerCase() }}
              </a>
            </div>
            <app-event-alert-booking-time-list [event]="event"></app-event-alert-booking-time-list>
          </div>
          <div
            class="pl-0 pr-0 pr-md-4 position-absolute w-100"
            style="bottom: 0px"
          >
            <div class="row mt-2">
              <p class="col pr-0 mx-2 text-right">
                <del
                  *ngIf="event.nextBookingPrice() !== event.original_price"
                  class="text-muted"
                  >({{ event.original_price }} €)</del
                >
              </p>
              <p class="display-3 col-auto pl-0 text-right">
                <!--
                  Show price you would get if you booked the event only if it is bookable,
                  otherwise show current price.
                  This matters only for events that are full or closed.
                  If it is too early the base price will be shown in any case.
                -->
                {{ event.nextBookingPrice() }} €
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div
    class="w-100"
    *ngIf="
      event.can_be_booked &&
      event.next_deals !== undefined &&
      event.next_deals.length > 0
    "
  >
    <div class="position-absolute mx-auto w-100" style="bottom: -0.9rem">
      <div class="mx-auto text-center">
        <a
          *ngIf="event.people_nb_to_next_deal > 2"
          class="text-center bg-white text-primary border border-primary rounded-lg m-2 shadow"
          ngbTooltip="Want to save more? Share this deal!"
          placement="bottom"
          (click)="$event.stopPropagation()"
        >
          <span class="lead my-2 px-4 h-100" i18n="compact discount reminder">
            -{{ event.people_nb_to_next_deal }}
            <i class="fa fa-user" aria-hidden="true"></i> to
            {{ event.current_price - event.next_deals[0].amount }} €
          </span>
          <a class="my-2 px-4 text-white bg-primary" (click)="shareEvent()"
            ><i class="fa fa-share-alt" aria-hidden="true"></i
          ></a>
        </a>
        <a
          *ngIf="event.people_nb_to_next_deal <= 2"
          class="text-center bg-danger text-white border border-dark rounded-lg m-lg-2 shadow"
          ngbTooltip="Next deal almost unlocked, share it to save {{
            event.next_deals[0].amount
          }} €!"
          placement="bottom"
          (click)="$event.stopPropagation()"
        >
          <span class="lead my-2 px-4 h-100" i18n="compact discount reminder">
            -{{ event.people_nb_to_next_deal }}
            <i class="fa fa-user" aria-hidden="true"></i> to
            {{ event.current_price - event.next_deals[0].amount }} €
          </span>
          <a class="my-2 px-4" (click)="shareEvent()"
            ><i class="fa fa-share-alt" aria-hidden="true"></i
          ></a>
        </a>
      </div>
    </div>
  </div>
</div>
