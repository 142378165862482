<div>
  <div>
    <div class="heading text-uppercase font-weight-bold mt-4 mb-1" i18n="booking flow - account required title">Login</div>
    <div class="p-4">
      <p i18n="booking flow - account required message">
        In order to proceed with your reservation you need to login or register.
      </p>
      <p i18n="booking flow - account usage message">We will use your profile data as contact info.</p>
    </div>
    <div class="row">
      <div class="mx-auto mb-3">
        <app-auth class="inline-block"></app-auth>
      </div>
    </div>
  </div>
  <!-- <div class="m-5">
    <div class="text-center">OR</div>
  </div>
  <div class="heading text-uppercase font-weight-bold mt-4 mb-1">Enter your booking contacts</div>
  <form>
    <div class="row">
      <div class=" col-md-6">
        <label for="first_name" class="d-block font-weight-bold mt-4 mb-1">First Name</label>
        <input id="first_name"  class="form-control text-default font-weight-bold" type="text" placeholder="First Name" />
      </div>
      <div class=" col-md-6">
        <label for="last_name" class="d-block font-weight-bold mt-4 mb-1">Last Name</label>
        <input id="last_name"  class="form-control text-default font-weight-bold" type="text" placeholder="Last Name" />
      </div>
    </div>
    <div class="">
      <label for="email" class="d-block font-weight-bold mt-4 mb-1">Email</label>
      <input id="email"  class="form-control text-default font-weight-bold" type="email" placeholder="your@email.com" />
    </div>
    <div class="">
      <label for="phone" class="d-block font-weight-bold mt-4 mb-1">Phone</label>
      <input id="phone"  class="form-control text-default font-weight-bold" type="text" placeholder="Phone Number" />
    </div>
  </form> -->
  <hr />
  <div class="container">
    <div class="clearfix">
      <div class="col-6 col-md-4 float-left">
        <button
          class="btn btn-link text-primary btn-lg w-100"
          type="button"
          (click)="previousStep()"
          i18n="back"
        >
          <i class="fa fa-arrow-left pr-2" aria-hidden="true"></i>Back
        </button>
      </div>
      <!-- Allows to go to next step without login (mock data) -->
      <!-- <div class="col-6 col-md-4 float-right">
        <button class="btn btn-primary btn-lg w-100" type="button" (click)="nextStep()">
          Next<i class="fa fa-arrow-right pl-2" aria-hidden="true"></i>
        </button>
      </div> -->
    </div>
  </div>
</div>
