<div *ngIf="isUserConsentRequired" class="alert rounded-0 fixed-bottom shadow-lg bg-primary text-white py-2 m-0" style="border-top: 0.45rem solid #ff3d54;" role="alert" id="consentBanner">
    <div class="float-right my-3 mx-4">
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="consentAll()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="container" style="max-width: 1140px;">
        <div class="row">
            <div class="col-md-2 col-lg-2 text-lg-right pt-4">
                <div class="rounded-circle shadow-lg m-0 d-inline-block" style="width:100px">
                    <img src="https://raw.githubusercontent.com/eosrei/twemoji-color-font/master/assets/twemoji-svg/1f36a.svg" style="margin:-5%;" />
                </div>
            </div>
            <div class="col-md-10 col-lg-8">
                <div class="row">
                    <h2 class="pt-1 col h2 text-white"><strong class="pt-1" i18n>A cookie?</strong></h2>
                </div>
                <div class="row">
                    <div class="col">
                        <p class="text-justify" i18n>
                            This website or its third-party tools process personal data (e.g. browsing data or IP addresses) and use cookies or other identifiers, which are necessary for its functioning and required to achieve the purposes illustrated in the cookie policy.<br/>
                            You accept the use of cookies or other identifiers by pressing "Accept" or dismissing this notice.
                        </p>
                    </div>
                </div>
            </div>
            <div class="offset-md-4 col-md-8 offset-lg-4 col-lg-6 row">
                <div class="col-sm-6">
                    <button type="button" class="w-100 btn btn-link btn-lg text-white underline" aria-label="Configure" data-toggle="modal" data-target="#cookieConfig" (click)="showSettingsModalView()">
                        <span aria-hidden="false"><u i18n>Configure</u></span>
                    </button>
                </div>
                <div class="col-sm-6">
                    <button type="button" class="w-100 btn bg-white btn-lg" data-dismiss="alert" aria-label="Close" (click)="consentAll()">
                        <span aria-hidden="true" i18n>Accept</span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>