import { formatDate } from '@angular/common';
import { Component, Input, LOCALE_ID, Inject, OnInit, EventEmitter, Output, OnChanges, SimpleChanges } from '@angular/core';
import { Schedule } from 'src/app/events/schedule.model';


export class ScheduleState {
  private constructor(
    public available: Boolean,
    public selected: Boolean,
    public cssClasses: string  // CSS classes separated by space
  ) { }

  static readonly UnavailableUnselected = new ScheduleState(false, false, "bg-dashed cursor-not-allowed disabled btn-white");
  static readonly UnavailableSelected = new ScheduleState(false, true, "bg-dashed cursor-not-allowed btn-danger");
  static readonly AvailableUnselected = new ScheduleState(true, false, "btn-outline-default");
  static readonly AvailableSelected = new ScheduleState(true, true, "btn-default");

  static getScheduleState(selected: Boolean, available: Boolean) {
    if (available) {
      return selected ? ScheduleState.AvailableSelected : ScheduleState.AvailableUnselected;
    } else {
      return selected ? ScheduleState.UnavailableSelected : ScheduleState.UnavailableUnselected;
    }
  }
}

export interface ScheduleStateMap {
  [scheduleId: string]: ScheduleState
}


@Component({
  selector: 'app-booking-select-schedule',
  templateUrl: './booking-select-schedule.component.html',
  styleUrls: ['./booking-select-schedule.component.scss']
})
export class BookingSelectScheduleComponent implements OnInit, OnChanges {
  @Input() schedules: Schedule[];
  @Input() scheduleStatesMap: ScheduleStateMap;
  @Input() selectedSchedule?: Schedule;
  @Input() nbSelectedParticipants: number;

  @Input() disabled = false;

  @Output() selectedScheduleEventEmitter = new EventEmitter<Schedule>();

  // restructured event schedules for UI
  public schedulesByDate: {
    key: string;
    scheduleGroupTitle: string;
    scheduleGroup: { schedule: Schedule; selected?: boolean }[];
  }[];

  constructor(
    @Inject(LOCALE_ID) private locale: string,
  ) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes.schedules && changes.schedules.currentValue !== changes.schedules.previousValue) {
      this.schedulesByDate = this.schedulesSortedByDates();
    }

    if(changes.selectedSchedule && changes.selectedSchedule.currentValue !== changes.selectedSchedule.previousValue) {
      if (this.selectedSchedule !== null) {
        this.selectSchedule(this.selectedSchedule);
      }
    }
  }

  schedulesSortedByDates() {
    const schedulesByDate = {};
    for (const s of this.schedules) {
      const key =
        s.start_datetime.getFullYear() +
        '-' +
        (s.start_datetime.getMonth() + 1) +
        '-' +
        s.start_datetime.getDate();

      if (!Object.prototype.hasOwnProperty.call(schedulesByDate, key)) {
        schedulesByDate[key] = [];
      }

      schedulesByDate[key].push({ schedule: s, selected: false });
    }

    const sortedSchedulesByDate = [];
    for (const key in schedulesByDate) {
      if (Object.prototype.hasOwnProperty.call(schedulesByDate, key)) {
        const _schedules = schedulesByDate[key];
        sortedSchedulesByDate.push({
          key,
          scheduleGroupTitle: formatDate(
            _schedules[0].schedule.start_datetime,
            'fullDate',
            this.locale
          ),
          scheduleGroup: _schedules,
        });
      }
    }
    sortedSchedulesByDate.sort((a, b) =>
      a.key === b.key ? 0 : a.key < b.key ? -1 : 1
    );
    return sortedSchedulesByDate;
  }

  selectSchedule(selectedSchedule: Schedule) {
    if(this.disabled) {
      // ignore clicks if component is disabled
      return;
    }

    if(this.selectedSchedule === selectedSchedule) {
      // ignore, no change
      return;
    }

    // change selection if target schedule is available
    // NOTE: user feedback = cursor-not-allowed (shows up as a forbidden sign)
    if(this.scheduleStatesMap[selectedSchedule.id].available) {
      this.selectedScheduleEventEmitter.emit(selectedSchedule);
    }
  }

  getCssClasses(schedule: Schedule) {
    const state = this.scheduleStatesMap[schedule.id];

    return state.cssClasses;
  }
}
