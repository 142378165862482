import { Component, OnInit } from '@angular/core';
import { Meta } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth/auth.service';
import { CookieService } from './cookie/cookie.service';
import { GtagService } from './gtag/gtag.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  constructor(
    private authService: AuthService,
    private gtagService: GtagService,
    private cookieService: CookieService,
    private metaService: Meta,
  ) {}

  ngOnInit() {
    // see https://developers.facebook.com/apps/
    this.setFacebookAppId(environment.FACEBOOK_SSO_ACCESS_KEY);

    this.authService.autoLogin();
    this.cookieService.init();
  }

  setFacebookAppId(appId: string) {
    if(appId) {
      // part of https://developers.facebook.com/docs/sharing/opengraph/using-objects
      this.metaService.addTag({
        property: 'fb:app_id',
        content: appId,
      });
    }
  }
}
