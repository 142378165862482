export const environment = {
  production: false,
  NAME: 'antipasto',
  withMocks: false,
  baseURL: 'https://api-antipasto.restaudeal.com',
  STRIPE_API_KEY: 'pk_test_LEmzy58Zcw5xWqDsqkFFqqjl000yFwq7Wf',
  GOOGLE_SSO_ACCESS_KEY: '355560769956-qeh8sk6j9fppg7jf1ch648bjlbm09mlk.apps.googleusercontent.com',
  GOOGLE_MAPS_API_KEY: 'AIzaSyCKmsRczf1WLYRM4OjCtgANUo8bKex4ya0',
  FACEBOOK_SSO_ACCESS_KEY: '1284914511944143',
  // https://analytics.google.com/analytics/web/?authuser=1#/p312537813
  GOOGLE_ANALYTICS_KEY: 'G-7X42K8T447',
  SENTRY_DSN: 'https://3ba54a5de23b4a6cbb341eddc4e8d7cc@o352792.ingest.sentry.io/6484574',
};
