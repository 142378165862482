<main class="mb-5" style="min-height: 100vh">
  <div class="position-relative">
    <section class="section section-lg section-shaped jumbotron">
      <div class="container py-4">
        <div class="px-0 row">
          <div class="text-center col">
            <h1 i18n>
              {{ place?.name }}
            </h1>
          </div>
        </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 60"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-white" points="2560 0 2560 60 0 60"></polygon>
        </svg>
      </div>
      <!-- Quickfix for background transparency -->
      <div class="jumbotron-bg"></div>
    </section>
    <section class="section">
      <div
        class="container"
        style="position: relative; top: -200px; z-index: 2"
      >
        <div class="row" style="min-height: 17.5rem">
          <div class="col-12 my-5" *ngIf="stripeAccountCompleted === undefined">
            <div class="d-flex justify-content-center mx-auto">
              <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <div class="text-center w-100 my-3" i18n>Loading Stripe status</div>
          </div>

          <!-- STRIPE ACCOUNT COMPLETED -->
          <div
            class="col-12 col-lg-8 offset-lg-2 alert-success mb-5 p-3 rounded shadow"
            *ngIf="stripeAccountCompleted; else whenStripeAccountNotCompleted"
          >
            <h4 class="text-white" i18n="place management - stripe account all set title">
              <i class="fa fa-thumbs-up" aria-hidden="true"></i> You're all set!
            </h4>
            <hr class="my-2" />
            <p i18n="place management - stripe account all set message">Your Stripe account is configured and ready to accept payments!</p>
            <p i18n="place management - go to Stripe dashboard intro">Do you want to know more?</p>
            <p class="text-center btn-group-vertical w-100">
              <button
                class="btn btn-neutral text-success w-100"
                (click)="redirectToStripeAccountLink()"
                >
                  <strong i18n="place management - go to Stripe account">Stripe Account</strong>
              </button>
              <!-- Separator quickfix, see https://stackoverflow.com/a/66065413 (adapted for vertical group) -->
              <button type="button" class="btn btn-white my-0 py-0" disabled></button>
              <button
                class="btn btn-neutral text-success w-100"
                (click)="redirectToStripeLoginLink()"
                >
                  <strong i18n="place management - go to Stripe Express dashboard">Payments Overview</strong>
              </button>
            </p>
          </div>

          <!-- STRIPE ACCOUNT NOT COMPLETED -->
          <ng-template #whenStripeAccountNotCompleted>
            <div
              class="col-12 col-lg-8 offset-lg-2 alert-warning mb-5 p-4 rounded shadow"
              *ngIf="stripeAccountCompleted === false"
            >
              <h4 class="text-white" i18n="place management - stripe account action required title">
                <i class="fa fa-exclamation-triangle" aria-hidden="true"></i> Action required!
              </h4>
              <hr class="my-2" />
              <p i18n="place management - stripe account action required message">Your Stripe account needs to be configured before you can accept payments!</p>
              <div>
                <p class="text-center">
                  <button
                    class="btn btn-neutral w-100"
                    (click)="redirectToStripeAccountLink()">
                      <strong i18n="place management - configure payments">Configure my payments</strong>
                  </button>
                </p>
              </div>
            </div>
          </ng-template>

        </div>
        <div class="row">
          <div class="d-flex justify-content-center my-5 mx-auto" *ngIf="!place">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
        <div class="row" *ngIf="ongoingEvents?.length > 0">
          <div
            class="col col-lg-8 offset-lg-2 alert-primary mb-5 p-4 rounded shadow"
          >
            <h4 class="text-white" i18n="place management - start check-in title">
              <i class="fa fa-star" aria-hidden="true"></i> You have an event today!
            </h4>
            <hr class="my-2" />
            <p i18n="place management - start check-in message">You can validate reservations and know more about who is in front of you.</p>
            <div>
              <p class="text-center">
                <button
                  class="btn btn-neutral w-100"
                  (click)="showCheckInModal()">
                    <strong i18n="place management - start check-in button">Check-in customers!</strong>
                </button>
              </p>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-12"><hr class="w-100"/></div>
          <div class="col-12">
            <nav class="nav nav-pills flex-column flex-sm-row">
              <a class="btn flex-sm-fill text-sm-center nav-link mx-1" routerLink="." fragment="restaurant-details" [ngClass]="{'active': tab === 'restaurant-details'}" (click)="setTab('restaurant-details')" i18n>Restaurant details</a>
              <a class="btn flex-sm-fill text-sm-center nav-link mx-1" routerLink="." fragment="events-active" [ngClass]="{'active': tab === 'events-active'}" (click)="setTab('events-active')" i18n>Active events</a>
              <a class="btn flex-sm-fill text-sm-center nav-link mx-1 disabled bg-light cursor-not-allowed" placement="top" ngbTooltip="Coming soon" i18n-ngbTooltip="Comming soon" i18n>Past events</a>
              <a class="btn flex-sm-fill text-sm-center nav-link mx-1 disabled bg-light cursor-not-allowed" placement="top" ngbTooltip="Coming soon" i18n-ngbTooltip="Comming soon" i18n>Create an event</a>
              <a class="btn flex-sm-fill text-sm-center nav-link mx-1" (click)="showCheckInModal(false)" i18n>Check-in customers</a>
            </nav>
          </div>
          <div class="col-12"><hr class="w-100"/></div>
        </div>
        <div class="row" [ngSwitch]="tab">
          <div class="col-12" id="restaurant-details" *ngSwitchCase="'restaurant-details'">
            <h2 class="mt-2" i18n>Restaurant details</h2>
            <div class="alert alert-info" role="alert" i18n>Something is not right? Contact us at <a class="alert-link" [href]="'mailto:' + supportEmail">{{supportEmail}}</a></div>
            <form class="w-100" *ngIf="place; else loadingPlace">
              <div class="form-group row">
                <label for="restaurantName" class="col-form-label col-form-label-lg col-sm-3" i18n>Name</label>
                <div class="col-sm-9">
                  <input type="text" class="px-3 form-control form-control-lg form-control-plaintext" id="restaurantName" [value]="place.name" readonly />
                </div>
              </div>
              <div class="form-group row">
                <label for="restaurantPhoneNumber" class="col-form-label col-form-label-lg col-sm-3" i18n>Phone number</label>
                <div class="col-sm-9">
                  <input type="text" class="px-3 form-control form-control-lg form-control-plaintext" id="restaurantPhoneNumber" [value]="place.phone_number" readonly />
                </div>
              </div>
              <div class="form-group row">
                <label for="restaurantAddress" class="col-form-label col-form-label-lg col-sm-3" i18n>Address</label>
                <div class="col-sm-9">
                  <input type="text" class="px-3 form-control form-control-lg form-control-plaintext" id="restaurantAddress" [value]="place.address" readonly />
                </div>
              </div>
            </form>
            <ng-template #loadingPlace>
              <div class="d-flex justify-content-center my-5 mx-auto">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </ng-template>
          </div>
          <div class="col-12" id="events-active" *ngSwitchCase="'events-active'">
            <h2 class="mt-2" i18n>Active events</h2>
            <app-management-event-list *ngIf="activeEvents; else loadingEvents" [events]="activeEvents"></app-management-event-list>
            <ng-template #loadingEvents>
              <div class="d-flex justify-content-center my-5 mx-auto">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
