<!-- SVG separator -->
<div class="">
  <svg
    x="0"
    y="0"
    viewBox="0 -1 2560 100"
    preserveAspectRatio="none"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <polygon class="fill-primary" points="2560 0 2560 100 0 100"></polygon>
  </svg>
</div>

<footer class="bg-primary">
  <div class="container">
    <div class="row py-5">
      <div class="col-md-6 order-md-last">
        <ul class="nav flex-column text-right">
          <li class="nav-item">
            <a class="nav-link text-white" [routerLink]="['/privacy-policy']" i18n="privacy-policy">
              Privacy Policy
            </a>
          </li>
          <li class="nav-item">
            <a class="nav-link text-white" [routerLink]="['/general-terms-of-use']" i18n="general-terms-of-use">
              General Terms of Use
            </a>
          </li>
          <!-- #TODO: Show this only to Restaurant Managers? -->
          <li class="nav-item">
            <a class="nav-link text-white" [routerLink]="['/general-terms-of-subscription']" i18n="general-terms-of-subscription">
              General Terms of Subscription
            </a>
          </li>
        </ul>
      </div>
      <div class="col-md-6 mt-5 mt-md-0 order-md-first">
        <div class="text-white">© Restaudeal {{ currentDate | date : "yyyy" }}</div>
      </div>
    </div>
  </div>
</footer>
