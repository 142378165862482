<div class="modal-header bg-gradient-primary">
  <h5 class="modal-title text-center text-white" i18n>Share event</h5>
  <button
    type="button"
    class="close"
    aria-label="Close"
    (click)="activeModal.dismiss('Cross click')"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body pt-0">
  <div class="text-muted text-uppercase text-right mt-2">
    <span *ngIf="!event.is_single_day" class="small" i18n> From </span>
    <span class="badge badge-pill badge-secondary">{{
      event.start_datetime | date: 'longDate'
    }}</span>
    <span *ngIf="!event.is_single_day">
      <span class="small" i18n> to </span>
      <span class="badge badge-pill badge-secondary">{{
        event.end_datetime | date: 'longDate'
      }}</span>
    </span>
  </div>
  <h4 class="card-title text-primary mb-0">
    {{ event.place.name }}
  </h4>
  <p class="mb-1">
    <i class="fa fa-map-marker pr-2" aria-hidden="true"></i
    >{{ event.place.address }}
  </p>
  <p class="">
    <i class="fa fa-tag pr-2" aria-hidden="true"></i>{{ event.name }}
  </p>
  <p class="mb-1">
    <i class="fa fa-angle-right pr-2" aria-hidden="true"></i>
    <span i18n>Current price: </span>
    <span>
      <!--
        Show price you would get if you booked the event only if it is bookable,
        otherwise show current price.
        This matters only for events that are full or closed.
        If it is too early the base price will be shown in any case.
      -->
      {{ event.nextBookingPrice() }} €
    </span>
    <small *ngIf="event.nextBookingPrice() !== event.original_price" class="ml-1">
      <del class="text-muted">({{ event.original_price }} €)</del>
    </small>
  </p>
  <p>
    <i class="fa fa-angle-right pr-2" aria-hidden="true"></i>
    <span i18n>Best price: </span>
    <span>{{ event.best_price }} €</span>
  </p>
  <div class="border-top pt-3">
    <h6 class="pb-2" i18n>How do you want to share it?</h6>
    <div class="text-center">
      <a
        class="w-75 w-md-25 btn text-white pl-3 pr-4 mb-3 mx-0"
        id="shareFacebook"
        style="background-color: rgb(59, 89, 152)"
        href="{{facebookUrl}}"
        (click)="logShareEvent('facebook')"
      >
        <i
          class="fa fa-facebook mr-2 align-middle"
          aria-hidden="true"
          style="font-size: 1.5rem"
        ></i>
        <span class="align-middle">Facebook</span>
      </a>
      <a
        class="w-75 w-md-25 btn text-white pl-3 pr-4 mb-3 mx-0"
        id="shareTwitter"
        style="background-color: #1da1f2"
        i18n-href="Twitter share link with predefined text inviting to share event"
        href="{{twitterUrl}}&text=Let's get the best deal!"
        (click)="logShareEvent('twitter')"
      >
        <i
          class="fa fa-twitter mr-2 align-middle"
          aria-hidden="true"
          style="font-size: 1.5rem"
        ></i>
        <span class="align-middle">Twitter</span>
      </a>
      <a
        class="w-75 w-md-25 btn text-white pl-3 pr-4 mb-3 mx-0"
        id="copyToClipboard"
        style="background-color: #4e4e4e"
        (click)="copyToClipboard()"
      >
        <i
          class="fa fa-link mr-2 align-middle"
          aria-hidden="true"
          style="font-size: 1.5rem"
        ></i>
        <span class="align-middle" i18n>Copy link</span>
      </a>
    </div>
  </div>
</div>
<div class="modal-footer">
  <div class="right-side">
    <button
      type="button"
      class="btn btn-white btn-link"
      (click)="activeModal.close('Close click')"
      i18n
    >
      Cancel
    </button>
  </div>
</div>
