import {Injectable} from "@angular/core";
import {Serializer} from "../shared/serializer";
import {Table, TableSerializer} from "../tables/table.model";
import {Place} from "../events/place.model";
import { Event } from '../events/event.model';
import { Choice } from '../events/choice.model';
import { Section } from '../events/section.model';
import { Menu } from '../events/menu.model';
import {Schedule, ScheduleSerializer} from "../events/schedule.model";
import {Payment} from "../booking-flow/payment.model";
import { ApiResponseParticipant } from "../management/bookings/booking.model";

export class ParticipantChoice {
    // Refactoring: need section info to support null values
    private _sectionChoices: [Section, (Choice | null)][]

    public get sectionChoices(): {section: Section, choice: (Choice | null)}[] {
        return this._sectionChoices.map(t => {
            return {"section": t[0], "choice": t[1]}
        });
    }

    public get choices(): (Choice | null)[] {
        const res: (Choice | null)[] = [];
        for (const sectionChoice of this._sectionChoices) {
            const choice = sectionChoice[1];
            res.push(choice);
        }
        return res;
    }

    public get definedChoices(): Choice[] {
        const res: Choice[] = [];
        for (const sectionChoice of this._sectionChoices) {
            const choice = sectionChoice[1];
            if(choice) {
                // keep only choices that are defined
                res.push(choice);
            }
        }
        return res;
    }

    public get pristine(): boolean {
        return this.definedChoices.length === 0;
    }

    private updateSectionChoices() {
        const sectionChoices: [Section, (Choice | null)][] = [];
        if(this.menu && this.menu.sections) {
            for (const section of this.menu.sections) {
                const defaultValue = section.isChoiceRequired ? null : Choice.NO_PREFERENCE;
                sectionChoices.push([section, defaultValue]);
            }
        }
        this._sectionChoices = sectionChoices;
    }

    // REMINDER: events without menu choices required OR legacy events do not have a menu
    private _menu: Menu | null;

    public get menu(): (Menu | null) {
        return this._menu;
    }

    public set menu(menu: (Menu | null)) {
        if(!menu) {
            this._menu = null;
        } else {
            this._menu = menu;
        }

        // rebuild section choices based on menu
        this.updateSectionChoices();
    }

    constructor(
        public participantIndex: number,
        menu: Menu,
    ) {
        this.menu = menu;
    }

    private isChoiceInSection(section: Section, choice: Choice) {
        if(choice === null) {
            if(!section.isChoiceRequired) {
                // by default we have NO_PREFERENCE if a choice is not mandatory
                throw new Error("[ERROR][ParticipantSelection]: Invalid choice, cannot be null if choice is not required");
            }
            return true;
        }

        if(choice === Choice.NO_PREFERENCE) {
            if(section.isChoiceRequired) {
                throw new Error("[ERROR][ParticipantSelection]: Invalid choice, cannot be NO_PREFERENCE if choice is required");
            }
            return true;
        }

        if(!section.choices) {
            return false;
        }

        for (const sectionChoice of section.choices) {
            if(choice && sectionChoice.id === choice.id) return true;
        }

        return false;
    }

    public getChoiceFor(sectionIndex: number): (Choice | null) {
        if(!this.menu) {
            throw new Error("[Error][ParticipantSelection]: cannot get choice by section index; menu is null");
        }

        const menuSections = this.menu.sections;

        if(!menuSections) {
            throw new Error("[Error][ParticipantSelection]: cannot get choice by section index; menu doesn't have sections");
        }

        if(menuSections.length <= sectionIndex) {
            throw new Error("[ERROR][ParticipantSelection]: cannot get choice by section index; section number mismatch");
        }

        return this._sectionChoices[sectionIndex][1];
    }

    public setChoiceFor(sectionIndex: number, choice: Choice | null): void {
        if(!this.menu) {
            throw new Error("[Error][ParticipantSelection]: cannot set choice by section index; menu is null");
        }

        const menuSections = this.menu.sections;

        if(!menuSections) {
            throw new Error("[Error][ParticipantSelection]: cannot set choice by section index; menu doesn't have sections");
        }

        if(menuSections.length <= sectionIndex) {
            throw new Error("[ERROR][ParticipantSelection]: section number mismatch");
        }

        if(choice !== null && !this.isChoiceInSection(menuSections[sectionIndex], choice)) {
            throw new Error("[ERROR][ParticipantSelection]: Invalid choice, not part of expected menu section");
        }

        this._sectionChoices[sectionIndex][1] = choice;
    }
}

export class ParticipantChoiceError {
    constructor(
        public participantChoice: ParticipantChoice,
        public errorsBySection: Map<number, string[]>,
    ) {

    }
}

export class ParticipantChoices {
    public static build(nbParticipants: number, menu: Menu): ParticipantChoices {
        const res = new ParticipantChoices();
        for (let index = 0; index < nbParticipants; index++) {
            res._participantChoices.push(new ParticipantChoice(index, menu));
        }
        return res;
    }

    private readonly _participantChoices: ParticipantChoice[] = [];

    public get participantChoices(): ParticipantChoice[] {
        // TODO: return copy?
        return this._participantChoices;
    }

    constructor(
        participantChoices?: ParticipantChoice[],
    ) {
        if(participantChoices) {
            for (const participantChoice of participantChoices) {
                this._participantChoices.push(participantChoice);
            }
        }
    }

    public get pristine(): boolean {
        for (const participantChoice of this._participantChoices) {
            if(!participantChoice.pristine) return false;
        }
        return true;
    }

    public getParticipantChoice(participantIndex: number) {
        return this._participantChoices[participantIndex];
    }

    public addParticipants(nbParticipants: number, menu: Menu) {
        for (let i = 0; i < nbParticipants; i++) {
            this.addParticipant(menu);
        }
    }

    public addParticipant(menu: Menu) {
        this._participantChoices.push(new ParticipantChoice(this._participantChoices.length, menu));
    }

    public removeParticipant() {
        this._participantChoices.pop();
    }

    private isConstraintRequiredChoicesValid() : boolean {
        for (const participantChoice of this._participantChoices) {
            if(!participantChoice.menu.isWithChoices) continue;

            for (let sectionIndex = 0; sectionIndex < participantChoice.menu.sections.length; sectionIndex++) {
                const section = participantChoice.menu.sections[sectionIndex];

                if(!section.isChoiceRequired) continue;

                if(!participantChoice.getChoiceFor(sectionIndex)) return false;
            }
        }

        return true;
    }

    private isConstraintMinPeopleNbValid(): boolean {
        const choicesToCheck = [];
        const choicesWithMinPeopleNb = {};

        // count how many selections are there for each choice requiring a min of selections
        for (const participantChoice of this._participantChoices) {
            if(participantChoice.choices) {
                for (const choice of participantChoice.choices) {
                    if(choice?.minPeopleNb > 1) {
                        choicesToCheck.push(choice);
                        if(choicesWithMinPeopleNb[choice.id] === undefined) {
                            choicesWithMinPeopleNb[choice.id] = 0;
                        }
                        choicesWithMinPeopleNb[choice.id] += 1;
                    }
                }
            }
        }

        // check that each choice associated to a min of selections has the required number of selections
        for (const choiceToCheck of choicesToCheck) {
            if(choiceToCheck.minPeopleNb > choicesWithMinPeopleNb[choiceToCheck.id]) {
                return false;
            }
        }

        return true;
    }

    public isValid(tableParticipantChoices?: ParticipantChoices | null): boolean {
        if(tableParticipantChoices) {
            // create a new ParticipantChoices object with all participant choices combined and then compute errors as a whole
            const allParticipantChoices = new ParticipantChoices(this.participantChoices.concat(tableParticipantChoices.participantChoices));
            return allParticipantChoices.isValid();
        }

        return this.isConstraintRequiredChoicesValid() && this.isConstraintMinPeopleNbValid();
    }

    // Method useful to check which form controls should be marked as invalid
    // returns errors by participantIndex
    public getErrors(tableParticipantChoices?: ParticipantChoices): Map<number, ParticipantChoiceError> {
        // output:
        // {
        //     <participantIndex>: ParticipantChoiceError
        // }
        const res = new Map<number, ParticipantChoiceError>();

        if(tableParticipantChoices) {
            // create a new ParticipantChoices object with all participant choices combined and then compute errors as a whole
            const allParticipantChoices = new ParticipantChoices(this.participantChoices.concat(tableParticipantChoices.participantChoices));
            const allErrors = allParticipantChoices.getErrors();

            // keep only the errors belonging to the participants part of the booking
            // Assumption: table participants are valid
            for (const [participantIndex, participantChoiceError] of allErrors.entries()) {
                if(participantIndex >= this.participantChoices.length) {
                    // table participant
                    continue;
                }

                res.set(participantIndex, participantChoiceError);
            }
        } else {
            // step 1. identify the choices that require a min_people_nb > 1 and count their occurrences
            // Details:
            //         for each choice in each participantChoice:
            //           if choice.min_people_nb > 1 then:
            //             store participantIndex, section of each choice requiring a check

            // [choiceId, [participantIndex, sectionIndex, Choice][]][]
            const acc: Map<string, [Choice, [number, number][]]> = new Map();

            for (let participantChoiceIndex = 0; participantChoiceIndex < this.participantChoices.length; participantChoiceIndex++) {
                const participantChoice: ParticipantChoice = this.participantChoices[participantChoiceIndex];

                if(participantChoice.choices) {
                    for (let sectionIndex = 0; sectionIndex < participantChoice.choices.length; sectionIndex++) {
                        const choice = participantChoice.choices[sectionIndex];

                        if(choice?.minPeopleNb > 1) {
                            if(!acc.has(choice.id)) {
                                acc.set(choice.id, [choice, []]);
                            }
                            acc.get(choice.id)[1].push([participantChoiceIndex, sectionIndex]);
                        }
                    }
                }
            }

            // step 2. validate choices
            // Details:
            //          for each choice to check:
            //            if len(entries) < choice.min_people_nb then:
            //              add error flag to result
            for (const accEntry of acc.entries()) {
                const choiceId = accEntry[0];
                const [choice, participantSectionIndexes] = accEntry[1];
                if(participantSectionIndexes.length < choice?.minPeopleNb) {
                    // not enough participants has chosen this choice
                    // set "noMinPeople" flag for each participant's section with this choice
                    // REMINDER: participantChoiceIndex = participantIndex in this context
                    for (const [participantChoiceIndex, sectionIndex] of participantSectionIndexes) {
                        if(!res.has(participantChoiceIndex)) {
                            const participantChoice = this.getParticipantChoice(participantChoiceIndex);

                            res.set(
                                participantChoiceIndex,
                                new ParticipantChoiceError(
                                    participantChoice,
                                    new Map(),
                                )
                            );
                        }

                        const participantChoiceError = res.get(participantChoiceIndex).errorsBySection;
                        if(!participantChoiceError.has(sectionIndex)) {
                            participantChoiceError.set(sectionIndex, []);
                        }
                        participantChoiceError.get(sectionIndex).push("noMinPeople");
                    }
                }
            }
        }

        return res;
    }
}


@Injectable({
    providedIn: 'root'
})
export class ParticipantChoicesSerializer extends Serializer<ApiResponseParticipant[], (ParticipantChoices | null)> {
    private _event: Event;

    /**
     * ParticipantChoices are always associated to an event page for now.
     * This is needed to properly handle the menu choices and correctly display the section names.
     * @param event event to which the booking being deserialized refer to.
     */
    public set event(v : Event) {
        this._event = v;
    }


    public get event() : Event {
        return this._event;
    }

    public deserialize(participants: ApiResponseParticipant[] | null): (ParticipantChoices | null) {
        if(!this.event) {
            throw new Error("ERROR: must provide a reference event to deserialize choices");
        }

        if(!this.event.menu) {
            if(participants !== null) {
                throw new Error("ERROR: expecting event.menu to deserialize participant chocies");
            }

            // No menu, no choices
            return null;
        } 

        if(participants === null) {
            if(this.event.menu_preference_required) {
                throw new Error("ERROR: expecting participant choices if event.menu_preference_required is True");
            }

            // Optional choices not provided
            return null;
        }
        
        const participantChoices = ParticipantChoices.build(participants.length, this.event.menu);

        // Resolve participant choices by choice id
        for (let participantIndex = 0; participantIndex < participants.length; participantIndex++) {
            const participant = participants[participantIndex];

            // NOTE: participant.order starts with 1
            // NOTE: cannot use participant.order since it is associated to the booking, not the table (mulitple participants can have order = 1)  
            //       using progressive index instead as a quickfix
            const participantChoice = participantChoices.getParticipantChoice(participantIndex);

            for (const choiceId of participant.choices.map(c => c === null ? null : c.id)) {
                if(choiceId === null) {
                    // NOTE: choice not defined, keep default value (NO_PREFERENCE)
                    //       this might need to change in future if we need to handle extras and the default is "no extra". 
                    continue;
                }

                const sectionChoice = this.event.getSectionChoiceById(choiceId);
                if(!choiceId) {
                    throw new Error(`ERROR: could not find choice with id '${choiceId}' in menu '${this.event.menu.name}' (id: ${this.event.menu.id})`);
                }

                // NOTE: section.order starts with 1
                const [section, choice] = sectionChoice as [Section, Choice];
                participantChoice.setChoiceFor(section.order - 1, choice);
            }
        }

        return participantChoices;
    }
}


export class Booking {
    constructor(
        public id: string,
        public code: string,
        public created_datetime: Date,
        public people_nb: number,
        public place: Place,
        public event: Event,
        public schedule: Schedule,
        public payment: Payment,
        public table: Table,
        public phone_number: string,
        public is_cancelled: boolean,
    ) {

    }

    public isPast(): boolean {
        return this.schedule.isPast();
    }

    public getStatus(): string {
        if (this.is_cancelled) {
            return $localize `cancelled`;
        }

        return this.isPast() ? $localize `closed` : $localize `confirmed`;
    }
}

@Injectable({
    providedIn: 'root',
})
export class BookingSerializer extends Serializer<Booking, Booking> {
    constructor(
        private tableSerializer: TableSerializer,
        private scheduleSerializer: ScheduleSerializer
    ) {
      super();
    }

    deserialize(booking: Booking): Booking {
        let table: Table = null;
        if (booking.table) {
            table = this.tableSerializer.deserialize(booking.table);
        }

        let schedule: Schedule = null;
        // NOTE: quickfix since payment API returns only schedule_id
        if(booking.schedule) {
            schedule = this.scheduleSerializer.deserialize(booking.schedule);
        }

        return new Booking(
            booking.id,
            booking.code,
            booking.created_datetime,
            booking.people_nb,
            booking.place,
            booking.event,
            schedule,
            booking.payment,
            table,
            booking.phone_number,
            booking.is_cancelled,
        );
    }
}
