<div>
  <div [hidden]="error">
    <div>
      <div class="heading text-uppercase font-weight-bold mt-4 mb-1" i18n="Reservation summary title">Summary</div>
      <div class="px-2 row">
        <div class="col-12 lead m-0 pb-2">
          <div class="">{{ event.place.name }}</div>
          <small class="text-uppercase text-muted">
            <i class="ni ni-pin-3"></i>
            {{ event.place.address }}
          </small>
        </div>
        <div class="col-12 row justify-content-start">
          <div class="col-auto lead pr-1">
            <span i18n>Reservation for </span>
            <span
              class="border border-default text-default p-2"
              *ngIf="state.nbSelectedParticipants > 1"
              i18n
              >{{ state.nbSelectedParticipants }} persons</span
            >
            <span
              class="border border-default text-default p-2"
              *ngIf="state.nbSelectedParticipants === 1"
              i18n
              >{{ state.nbSelectedParticipants }} person</span
            >
          </div>
          <div class="lead pl-3 pl-md-0">
            <span i18n="date article prefix"> the </span>
            <span
              class="border border-default text-default p-2"
              placement="top"
              [ngbTooltip]="state.selectedSchedule.start_datetime | date: 'longDate'"
            >{{ state.selectedSchedule.start_datetime | date: 'shortDate' }}</span>
            <span i18n="time article prefix"> at </span>
            <span class="border border-default text-default p-2">{{ state.selectedSchedule.start_datetime | date: 'shortTime' }}</span>
          </div>
        </div>
      </div>

      <div *ngIf="event.menu_preference_required" class="row">
        <div class="col-12 mt-4">
          <div class="border border-default m-2">
            <app-participants-selection-collapsible-summary [participantChoices]="state.participantChoices"></app-participants-selection-collapsible-summary>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <div class="display-4 m-5 text-center">
            {{ state.nbSelectedParticipants }} x {{ state.discount.eventPrice }} EUR =
            {{ state.discount.bookingPrice }} EUR
            <del
              class="text-muted font-weight-light"
              *ngIf="state.discount.totalDiscount > 0"
              >({{
                state.discount.bookingOriginalPrice
              }}
              EUR)</del
            >
          </div>
        </div>
      </div>

      <div class="mb-5" *ngIf="state.selectedTable">
        <app-table-card-compact [showTableAs]="'linked'" [table]="state.selectedTable" [nbSelectedParticipants]="state.nbSelectedParticipants"></app-table-card-compact>
      </div>

    </div>
    <div class="mb-5 alert-info p-3 rounded shadow">
      <h5 class="text-white">
        <i class="fa fa-info-circle mr-3" aria-hidden="true"></i
        ><strong i18n>You might pay less!</strong>
      </h5>
      <span class="d-block" i18n>
        We will charge you with the final amount when the event ends.
      </span>
      <small i18n>
        If meanwhile new discounts are unlocked, we will apply them
        automatically (and notify you by email with the final invoice).
      </small>
    </div>

    <div class="heading text-uppercase font-weight-bold mt-4 mb-1" i18n="payment details">
      Payment details
    </div>
    <div i18n class="mb-2">
      Something is not right? Check your <a [routerLink]="['/account']">account settings</a>.
    </div>
    <form [formGroup]="paymentForm" (submit)="processPayment()" class="pt-3 px-2 row">
      <div class="col-12 form-group">
        <label for="name" class="text-uppercase" i18n="name label">Name</label>
        <div class="border-bottom border-default mx-2">
          <input
            id="nameInput"
            type="text"
            class="border-0 text-default form-control"
            formControlName="name"
            ngbTooltip="name can be changed in account section"
            i18n-ngbTooltip="name can be changed in account section tooltip"
            />
        </div>
      </div>
      <div class="col-md-6 form-group">
        <label for="email" class="text-uppercase" i18n="email label">Email</label>
        <div
          class="border-bottom border-default mx-2"
          ngbTooltip="email cannot be changed"
          i18n-ngbTooltip="email cannot be changed tooltip"
          >
          <input
            id="emailInput"
            type="email"
            class="border-0 text-default form-control"
            formControlName="email"
            />
        </div>
      </div>
      <div class="col-md-6 form-group">
        <label for="phoneNumberInput" class="text-uppercase" i18n="phone label">Phone</label>
        <span class="text-danger font-weight-bold">*</span>
        <div
          class="border-bottom mx-2"
          [ngClass]="{
          'border-danger':
            paymentForm.controls.phoneNumber.invalid,
          'border-default':
            !paymentForm.controls.phoneNumber.invalid
          }"
        >
          <input
            id="phoneNumberInput"
            type="tel"
            class="border-0 text-default form-control"
            formControlName="phoneNumber"
            [ngClass]="{
              'is-invalid':
                paymentForm.controls.phoneNumber.invalid,
              'is-valid':
                !paymentForm.controls.phoneNumber.invalid &&
                paymentForm.controls.phoneNumber.dirty
            }"
            ng2TelInput
            [ng2TelInputOptions]="{initialCountry: countryCode, preferredCountries: PREFERRED_COUNTRIES}"
            (intlTelInputObject)="getNg2TelInput($event)"
            (countryChange)="onCountryChange()"
            />
        </div>
      </div>
      <div class="col-12 form-group">
        <label class="text-uppercase" i18n="credit card label">Card</label>
        <div
          class="m-2 border-bottom"
          [ngClass]="{
            'border-danger':
              cardErrors,
            'border-default':
              !cardErrors
          }"
        >
          <div
            #cardElement
            class="form-control border-0 pt-3"
            [ngClass]="{
              'stripe-disabled':
                loading
            }"
          >
            <!-- Stripe Element will be inserted here -->
          </div>
        </div>
      </div>
      <div class="col-12 d-flex flex-row justify-content-end mr-5">
        <div class="pr-3" style="border-right: 1px solid #adb5bd;">
          <a href="https://stripe.com" target="_blank">
            <span class="small text-light">
              Powered by <svg class="align-bottom" style="fill: #adb5bd;height: 1.3rem;" focusable="false" viewBox="0 0 33 15"><g fill-rule="evenodd"><path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></g></svg>
            </span>
          </a>
        </div>
        <div class="pl-3">
          <a class="mr-3" href="https://stripe.com/checkout/terms" target="_blank">
            <span class="small text-light" i18n="Stripe's terms and conditions">Terms</span>
          </a>
          <a href="https://stripe.com/privacy" target="_blank">
            <span class="small text-light" i18n="Stripe's privacy policy">Privacy</span>
          </a>
        </div>
      </div>
      <!-- Used to display Stripe Element errors -->
      <div *ngIf="cardErrors" class="col-12">
        <div class="alert alert-danger m-4" role="alert">
          <span class="alert-inner--icon"><i class="fa fa-exclamation-circle" aria-hidden="true"></i></span>
          <span class="alert-inner--text">{{ cardErrors }}</span>
        </div>
      </div>

      <span *ngIf="!user.subscribed_to_newsletter">
        <div class="heading text-uppercase font-weight-bold mt-4 mb-1" i18n="payment details">
          Notifications
        </div>
        <div class="col-12 justify-content-start d-flex flex-row mt-3">
          <label class="custom-toggle">
            <input
              id="wantsToSubscribeToNewsletterInput"
              type="checkbox"
              formControlName="wantsToSubscribeToNewsletter">
            <span class="custom-toggle-slider rounded-circle"></span>
          </label>
          <div class="pull-right ml-3" i18n>I would like to be informed of future events around me</div>
        </div>
      </span>
    </form>
    <hr />
    <div class="container">
      <div class="clearfix">
        <div class="col-6 col-md-4 float-left">
          <button
            class="btn btn-link text-primary btn-lg w-100"
            type="button"
            (click)="previousStep()"
            [disabled]="loading"
          >
            <i class="fa fa-arrow-left pr-2" aria-hidden="true"></i><span i18n="back">Back</span>
          </button>
        </div>
        <div class="col-6 col-md-4 float-right">
          <button
            class="btn btn-primary btn-lg w-100"
            type="button"
            (click)="processPayment()"
            [disabled]="loading || !cardFormIsValid || !payment || paymentForm.invalid"
          >
            <span i18n="payment button">Pay</span>
            <i *ngIf="!loading" class="fa fa-shopping-cart pl-2" aria-hidden="true"></i>
            <i *ngIf="loading" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div [hidden]="!error">
    <div class="container">
      <div>
        <span i18n="Unknown error, generic message">An unexpected error occured</span>
        <button
          class="btn btn-link text-primary text-capitalize btn-lg"
          type="button"
          (click)="initPayment()"
        >
          <i class="fa fa-refresh pr-2" aria-hidden="true"></i><span i18n="Retry operation">Retry</span>
        </button>
      </div>
      <div class="clearfix">
        <div class="col-6 col-md-4 float-left">
          <button
            class="btn btn-link text-primary btn-lg w-100"
            type="button"
            (click)="previousStep()"
          >
            <i class="fa fa-arrow-left pr-2" aria-hidden="true"></i><span i18n="back">Back</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
