import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../../event.model';
import { EventShareService } from '../../event-share/event-share.service';

@Component({
  selector: 'app-event-compact',
  templateUrl: './event-item-compact.component.html',
})
export class EventItemCompactComponent implements OnInit {
  @Input() event: Event;
  @Input() style?: "two-columns";

  cssClassesLeftColumn: string[];
  cssClassesRightColumn: string[];

  ngOnInit() {
    switch (this.style) {
      case "two-columns":
        // NOTE: 2 column layout is supported only on xl screens
        this.cssClassesLeftColumn = ["col-xl-6"];
        this.cssClassesRightColumn = ["col-xl-6"];
        break;
      default:
        break;
    }
  }

  constructor(private eventShareService: EventShareService) {}

  shareEvent() {
    this.eventShareService.showModalView(this.event);
  }
}
