import { Component, OnInit } from '@angular/core';
import { Place } from '../place.model';
import { PlaceService } from '../place.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Event } from '../../events/event.model';
import { EventService } from '../../events/event.service';
import { CheckinModalComponent } from '../../checkin-flow/checkin-modal/checkin-modal.component';
import { settings } from '../../../shared/settings';
import { ViewportScroller } from '@angular/common';
import { first } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SeoService } from 'src/app/shared/components/seo/seo.service';
import { SeoImage } from 'src/app/shared/components/seo/seo.model';

@Component({
  selector: 'app-place-detail',
  templateUrl: './place-detail.component.html',
  styleUrls: ['./place-detail.component.scss']
})
export class PlaceDetailComponent implements OnInit {
  place: Place;
  stripeAccountCompleted: boolean;

  tab = 'restaurant-details';

  activeEvents?: Event[] = undefined;
  ongoingEvents?: Event[] = undefined;

  supportEmail: string = settings.SUPPORT_EMAIL;

  constructor(
    private placeService: PlaceService,
    private eventService: EventService,
    private router: Router,
    private route: ActivatedRoute,
    private readonly viewportScroller: ViewportScroller,
    private readonly modalService: NgbModal,
    private readonly seoService: SeoService,
  ) { }
  
  /////////////////////////////////////////////////////////////////////////////
  // SEO METADATA
  /////////////////////////////////////////////////////////////////////////////
  getSeoTitle(): string {
    return $localize `:management place page SEO title:Restaudeal | ${this.place.name} management`;
  }

  getSeoDescription(): string {
    return $localize `:management place page SEO description:Restaudeal | Page for managing ${this.place.name}`;
  }

  getSeoImage(): SeoImage {
    return settings.DEFAULT_SEO_IMAGE;
  }

  getSeoUrl(): string | null {
    return null;
  }

  renderSeoMetadata(): void {
    this.seoService.renderSeoMetadata(this);
  }
  /////////////////////////////////////////////////////////////////////////////

  ngOnInit(): void {
    const placeId: string = this.route.snapshot.params.id;
    this.placeService.getPlace(placeId).subscribe((place: Place) => {
      this.place = place;
      this.renderSeoMetadata();

      this.route.queryParams.subscribe(params => {
        if (params.refresh) {
          this.redirectToStripeAccountLink();
        } else {
          this.placeService.checkStripeAccountCompleted(placeId).subscribe((completed: boolean) => {
            this.stripeAccountCompleted = completed;
          });
        }
      });

      this.route.fragment.pipe(first()).subscribe(fragment => {
        if(!fragment || !["restaurant-details", "events-active", "events-past"].includes(fragment)) {
          this.tab = "restaurant-details";
        } else {
          this.setTab(fragment as "restaurant-details" | "events-active" | "events-past");
        }
      });
    });
    this.eventService.loadActiveEvents(placeId).subscribe((events: Event[]) => {
      this.activeEvents = events;

      const now = new Date();
      this.ongoingEvents = events.filter((v, i, a) => {
        // add some margin for people arriving earlier or late
        const start_datetime = new Date(v.start_datetime);
        start_datetime.setHours(start_datetime.getHours() - 1);
        const end_datetime = new Date(v.end_datetime);
        end_datetime.setHours(end_datetime.getHours() + 1);

        return (start_datetime <= now && now <= end_datetime)
      })
    });
  }

  redirectToStripeAccountLink() {
    const returnURL: string = window.location.origin + window.location.pathname;
    const refreshURL: string = returnURL + '?refresh=true';
    this.placeService
        .getStripeAccountLink(this.place.id, returnURL, refreshURL)
        .subscribe((link: string) => {
      window.location.href = link;
    });
  }

  redirectToStripeLoginLink() {
    this.placeService
        .getStripeLoginLink(this.place.id)
        .subscribe(link => {
      window.open(link, '_blank');
    });
  }

  setTab(tab: "restaurant-details" | "events-active" | "events-past") {
    this.tab = tab;
    // go to section (quickfix)
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor(tab);
    }, 100);
  }

  showCheckInModal(selectSingleEvent: boolean = true) {
    const modalRef = this.modalService.open(CheckinModalComponent, {
      windowClass: 'modal',
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.selectSingleEvent = selectSingleEvent;
    modalRef.componentInstance.events = this.ongoingEvents;
    return modalRef;
  }

}
