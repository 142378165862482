import { InjectionToken } from '@angular/core';

export interface Gtag {
    (cmd: 'set', params: CustomParams): void;
    (cmd: 'config', targetId: string, config?: ConfigParams): void;
    (cmd: 'event', action: string, params?: EventParams): void;
}

export interface CustomParams {
    [key: string]: any;
}

//@see: https://developers.google.com/gtagjs/reference/parameter#control_parameters
export interface ConfigParams extends CustomParams {
    groups?: string | string[];
    send_to?: string | string[];
    event_callback?: () => void;
    event_timeout?: number;

    //@see: https://developers.google.com/analytics/devguides/collection/gtagjs#disable_pageview_measurement
    send_page_view?: boolean;

    //@see: https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-link-attribution
    link_attribution?: boolean;

    //@see: https://developers.google.com/analytics/devguides/collection/gtagjs/ip-anonymization
    anonymize_ip?: boolean;

    //@see: https://developers.google.com/analytics/devguides/collection/gtagjs/display-features
    allow_ad_personalization_signals?: boolean;
}

export interface EventParams extends CustomParams {
    non_interaction?: boolean;
    event_category?: string;
    event_label?: string;
    value?: number;
}

// See https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item_list_item
export interface Item {
    // NOTE: one of item_id and item_name MUST be defined.
    item_id?: string;
    item_name?: string;
    affiliation?: string;
    coupon?: string;
    currency?: string;
    discount?: number;
    index?: number;
    item_brand?: string;
    item_category?: string;
    item_category2?: string;
    item_category3?: string;
    item_category4?: string;
    item_category5?: string;
    item_list_id?: string;
    item_list_name?: string;
    item_variant?: string;
    location_id?: string;
    price?: number;
    quantity?: number;

    // custom properties

    /**
     * Number of hours between the reservation and the actual event date.
     */
    lead_time?: number;

    /**
     * Date for which a reservation is made in YYYY-MM-DD format.
     */
    check_in_date?: string;

    /**
     * Time for which a reservation is made in HH:MM format.
     */
    check_in_time?: string;
}

export interface CheckoutAction {
    value: number;
    currency: string;
    transaction_id?: string;
    affiliation?: string;
    coupon?: string;
    shipping?: number;
    tax?: number;
    items: Item[];
    payment_type?: string;
    shipping_tier?: string;
}

/**
 * An event that refers to a single item.
 * 
 * view_item: This event signifies that some content was shown to the user. Use this event to discover the most popular items viewed.
 * add_to_cart: This event signifies that an item was added to a cart for purchase.
 * 
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#select_item
 */
export interface ItemAction {
    /**
     * Currency of the items associated with the event, in 3-letter ISO 4217 format.
     *  Ignored if set at the item-level.
     */
    currency?: string;

    /**
     * The monetary value of the event.
     *  Ignored if set at the item-level.
     */
    value?: number;

    /**
     * The items for the event.
     */
    items: Item[];
}

/**
 * Log this event when the user has been presented with a list of items of a certain category.
 * 
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#view_item_list 
 */
export interface ViewItemListAction {
    /**
    *  The ID of the list in which the item was presented to the user.
    *  Ignored if set at the item-level.
    */
    item_list_id?: string;

    /**
    *  The name of the list in which the item was presented to the user.
    *  Ignored if set at the item-level.
    */
    item_list_name?: string;

    /**
    *  The items for the event.
    */
    items: Item[];
}

/**
 * Use this event when a user has shared content.
 * 
 * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#share 
 */
export interface ShareItemAction {
    /**
     *  The method in which the content is shared.
     */
    method?: string;

    /**
     * 	The type of shared content.
     */
    content_type?: string;

    /**
     * The ID of the shared content.
     */
    item_id?: string;
}

export interface GtagConfig {
    targetId: string; // gtag('config', 'targetId', configInfo )
    configInfo?: ConfigParams;
    setParams?: CustomParams; // gtag('set', setParams)
    moreIds?: string[];
    timeout?: number;
}

export const GtagConfigToken = new InjectionToken<GtagConfig>('restaudeal.gtag.config');
