<nav
  id="navbar-main"
  class="navbar navbar-horizontal navbar-expand-lg navbar-dark flex-row align-items-md-center ct-navbar shadow fixed-top"
>
  <div class="container">
    <a class="navbar-brand mr-lg-5" [routerLink]="['/']">
      <img src="./assets/img/logo-hr-minimal-white.svg" />
    </a>
    <button
      class="navbar-toggler"
      type="button"
      (click)="isCollapsed = !isCollapsed"
      [attr.aria-expanded]="!isCollapsed"
      aria-controls="navbar_global"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div
      class="navbar-collapse collapse"
      id="navbar_global"
      [ngbCollapse]="isCollapsed"
    >
      <div class="navbar-collapse-header">
        <div class="row">
          <div class="col-6 collapse-brand">
            <a [routerLink]="['/']">
              <img src="./assets/img/logo-hr-minimal-black.svg" />
            </a>
          </div>
          <div class="col-6 collapse-close">
            <button
              type="button"
              class="navbar-toggler"
              (click)="isCollapsed = !isCollapsed"
              [attr.aria-expanded]="!isCollapsed"
              aria-controls="navbar_global"
            >
              <span></span>
              <span></span>
            </button>
          </div>
        </div>
      </div>
      <ul class="navbar-nav navbar-nav-hover align-items-lg-center d-lg-none">
        <li
          class="nav-item btn-dark px-5 px-lg-0 btn btn-dark rounded-2 py-0"
          id="location-btn"
        >
          <a
            [routerLink]="['/events']"
            class="nav-link"
            role="button"
            style="color: white !important"
          >
            <i class="fa fa-compass d-lg-none"></i>
            <span class="nav-link-inner--text" i18n>Events</span>
            <i class="fa fa-compass d-none d-lg-inline px-2"></i>
          </a>
        </li>
      </ul>
      <ul class="navbar-nav align-items-lg-center ml-lg-auto">
        <li class="nav-item ml-lg-4" *ngIf="user?.is_manager">
          <a
            [routerLink]="['/management/places']"
            class="btn btn-neutral btn-icon py-3 text-dark w-100"
          >
            <span class="btn-inner--icon">
              <i class="fa fa-cutlery mr-2"></i>
            </span>
            <span class="nav-link-inner--text" i18n>My restaurant</span>
          </a>
        </li>
        <li class="nav-item ml-lg-1" *ngIf="isAuthenticated">
          <a
            [routerLink]="['/account']"
            class="btn btn-neutral btn-icon py-3 text-dark w-100"
          >
            <span class="btn-inner--icon">
              <i class="fa fa-user mr-2"></i>
            </span>
            <span class="nav-link-inner--text" i18n>Account</span>
          </a>
        </li>
        <li class="nav-item ml-lg-4" *ngIf="isAuthenticated">
          <button
            class="btn btn-neutral btn-icon py-3 text-dark w-100"
            (click)="onLogout()"
          >
            <span class="btn-inner--icon">
              <i class="fa fa-power-off"></i>
            </span>
          </button>
        </li>
      </ul>
    </div>
  </div>
</nav>
