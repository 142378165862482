import { Component, Input, OnInit } from '@angular/core';
import { Booking, Table, indexBookingsByTableJoiningCode, indexBookingTableJoiningCodesByDate, indexTablesByTableJoiningCode } from '../booking.model';
import { FormControl, FormGroup } from '@angular/forms';
import { DateMap } from 'src/app/shared/utils/map';


@Component({
  selector: 'app-management-booking-list',
  templateUrl: './booking-list.component.html',
  styleUrls: ['./booking-list.component.scss']
})
export class BookingListComponent implements OnInit {

  @Input() bookings: Booking[];

  form!: FormGroup;
  selectedSchedule: number | null = null;

  dates!: Date[];
  tablesDateList!: {date: Date, tables: Set<string>}[];

  readonly bookingsByTable: Map<string, Booking[]> = new Map();
  readonly tablesByJoiningCode: Map<string, Table> = new Map();

  constructor() { }

  buildIndexes() {
    const tablesByDate: DateMap<Set<string>> = new DateMap();
    const indexFns: [Map<any, any>, (map: Map<any, any>, booking: Booking) => void][] = [
      [this.tablesByJoiningCode, indexTablesByTableJoiningCode],
      [tablesByDate, indexBookingTableJoiningCodesByDate],
      [this.bookingsByTable, indexBookingsByTableJoiningCode],
    ];
    
    for(const booking of this.bookings) {
      for(let [map, fn] of indexFns) {
        fn(map, booking);
      }
    }

    this.tablesDateList = Array.from(tablesByDate.entries()).map(t => { return {date: t[0], tables: t[1]} });
    this.dates = Array.from(tablesByDate.keys()).sort();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      schedule: new FormControl(this.selectedSchedule)
    });

    this.buildIndexes();
  }
  
  onFilterChange($event): void {
    this.selectedSchedule = this.form.controls.schedule.value;
  }
}
