import { Injectable } from "@angular/core";
import { Serializer } from "../shared/serializer";
import { Booking, BookingSerializer } from "../booking/booking.model";

export class Payment {
    constructor(
        public id: string,
        public client_secret: string,
        public notes: string,
        public people_nb: number,
        public schedule_id: string,
        public status: string,
        public booking: Booking | null = null,
    ) {}
}

@Injectable({
    providedIn: 'root',
})
export class PaymentSerializer extends Serializer<Payment, Payment> {
    constructor(
        private bookingSerializer: BookingSerializer,
    ) {
      super();
    }

    deserialize(payment: Payment): Payment {
        var booking = null;
        if(payment.booking) {
            booking = this.bookingSerializer.deserialize(payment.booking);
        }

        return new Payment(
            payment.id,
            payment.client_secret,
            payment.notes,
            payment.people_nb,
            payment.schedule_id,
            payment.status,
            booking,
        )
    }
}
