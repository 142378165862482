import { ParticipantChoices } from '../booking/booking.model';
import { Discount, Event } from '../events/event.model';
import { Schedule } from '../events/schedule.model';
import { Table } from '../tables/table.model';

export class BookingFlowState {
  static getTotalTableParticipants(table: Table | null, nbSelectedParticipants: number): number {
    const currentTableParticipants = table ? table.people_nb : 0;
    const totalTableParticipants = nbSelectedParticipants + currentTableParticipants;
    return totalTableParticipants;
  }

  private _nbSelectedParticipants: number;
  public discount: Discount;
  public participantChoices: ParticipantChoices;

  constructor(
    public event: Event,
    public step?: number,
    nbSelectedParticipants?: number,
    public selectedSchedule?: Schedule,
    public selectedTable: Table | null = null,
    public isNewTable: boolean | null = null,
    public notes: string = "",
    public transactionId?: string, // the transaction id is supposed to identify an order
  ) {
    if (step === undefined) {
      this.step = 1;
    }

    if (nbSelectedParticipants === undefined) {
      nbSelectedParticipants = 1;
    }
    this.participantChoices = ParticipantChoices.build(nbSelectedParticipants, this.event.menu);
    this.nbSelectedParticipants = nbSelectedParticipants;
  }

  get nbSelectedParticipants() {
    return this._nbSelectedParticipants;
  }

  set nbSelectedParticipants(nbSelectedParticipants: number) {
    if(this._nbSelectedParticipants > nbSelectedParticipants) {
      const participantsToRemove = this._nbSelectedParticipants - nbSelectedParticipants;
      for(let i = 0; i < participantsToRemove; i++) {
        this.participantChoices.removeParticipant();
      }
    } else {
      const participantsToAdd = nbSelectedParticipants - this._nbSelectedParticipants;
      this.participantChoices.addParticipants(participantsToAdd, this.event.menu);
    }

    this.discount = this.event.getDiscountFor(nbSelectedParticipants);

    this._nbSelectedParticipants = nbSelectedParticipants;
  }

  /**
   * Number of hours between the reservation (now) and the actual event date.
   */
  get leadTime() {
    if(this.selectedSchedule) {
      return Math.ceil(
        this.selectedSchedule.start_datetime.getTime() - Date.now() /
        (1000 * 60 * 60)
      );
    } else {
      return undefined;
    };
  }

  getTotalTableParticipants(): number {
    const currentTableParticipants = this.selectedTable ? this.selectedTable.people_nb : 0;
    const totalTableParticipants = this.nbSelectedParticipants + currentTableParticipants;
    return totalTableParticipants;
  }

  isTableAboveWarningThreshold() {
    return this.getTotalTableParticipants() >= this.event.booking_capacity_warning;
  }

  isTableAboveDenyThreshold() {
    return this.getTotalTableParticipants() >= this.event.booking_capacity_deny;
  }
}


