import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { EventsQuery } from 'src/app/events/events-query.model';

import { EventsService as _EventService } from 'src/app/events/events.service';

import { Event } from './event.model';

@Injectable({
  providedIn: 'root'
})
export class EventService {
  constructor(
    private eventService: _EventService,
  ) { }


  loadActiveEvents(placeId: string): Observable<Event[]> {
    // MISSING API to query events of a place!
    // This is a quickfix logic to handle this type of requests

    const allEventsQuery = new EventsQuery(
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
      undefined,
    );

    // FIXME: EventService handles only a page!
    // FIXME: EventService returns only ongoing and future events!
    return this.eventService
      .loadEvents(allEventsQuery)
      .pipe(
        map((events, index) => {
          return events
            .filter((e, i, a) => e.place.id === placeId)
            .sort((a, b) => a.start_datetime.valueOf() - b.start_datetime.valueOf());
          }
        )
      );
  }

  loadEvent(eventId: string): Observable<Event> {
    return this.eventService.loadEvent(eventId);
  }
}
