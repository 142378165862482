import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../../events/event.model';
import { BookingFlowState } from '../booking-flow-state.model';

@Component({
  selector: 'app-booking-summary-mobile',
  templateUrl: './booking-summary-mobile.component.html',
  styleUrls: ['./booking-summary-mobile.component.scss'],
})
export class BookingSummaryMobileComponent implements OnInit {
  @Input() event: Event;
  @Input() state: BookingFlowState;

  constructor() {}

  ngOnInit(): void {}
}
