import { NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

export class User {
  // NOTE: properties are in snake case to match the API schema
  constructor(
    public id: string,
    public email: string,
    public first_name: string,
    public last_name: string,
    public subscribed_to_newsletter: boolean,
    public is_manager: boolean,
    public birth_date?: NgbDateStruct,
    public phone_number?: string,
    public telegram_username?: string,
  ) {}

  full_name() {
    return this.first_name + ' ' + this.last_name;
  }
}
