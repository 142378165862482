import { Injectable } from '@angular/core';
import {PaginatedListResponse} from '../../shared/interfaces/paginated-list-response';
import {Place, PlaceSerializer} from './place.model';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PlaceService {
  places: Place[] = [];
  count: number;
  next: string;
  previous: string;

  constructor(
    private http: HttpClient,
    private placeSerializer: PlaceSerializer) { }

  getPlaces() {
    return this.http
      .get<PaginatedListResponse<Place>>('/api/v1/management/places')
      .pipe(
        map((response: PaginatedListResponse<Place>) => {
          this.count = response.count;
          this.next = response.next;
          this.previous = response.previous;
          this.places = [];

          for (const place of response.results) {
            this.places.push(this.placeSerializer.deserialize(place));
          }

          return this.places;
        })
      );
  }

  getPlace(id: string): Observable<Place> {
    return this.http.get<Place>('/api/v1/management/places/' + id).pipe(
      map((place: Place) => {
        return this.placeSerializer.deserialize(place);
      })
    );
  }

  checkStripeAccountCompleted(placeId: string): Observable<boolean> {
    return this.http.get<any>('/api/v1/management/places/' + placeId + '/check-stripe-account-completed/').pipe(
      map((data) => {
        return data.stripe_account_completed;
      })
    );
  }

  getStripeAccountLink(placeId: string, returnURL: string, refreshURL: string): Observable<string> {
    const queryParams = new HttpParams().append('return_url', returnURL).append('refresh_url', refreshURL);
    return this.http.get<any>('/api/v1/management/places/' + placeId + '/get-stripe-account-link/', {params: queryParams}).pipe(
      map((data) => {
        return data.link;
      })
    );
  }

  /**
   * Create a link to access the Stripe dashboard associated to a Restaurant
   * See https://stripe.com/docs/connect/integrate-express-dashboard#create-login-link
   * @param placeId: the ID of the place for which we want the login link
   * @returns: the Stripe login link to access the Stripe Express dashboard for the given Place
   */
  getStripeLoginLink(placeId: string): Observable<string> {
    return this.http.get<any>('/api/v1/management/places/' + placeId + '/get-stripe-login-link/').pipe(
      map((data) => {
        return data.link;
      })
    );
  }
}
