import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Table, TableSerializer } from './table.model';
import { Event } from '../events/event.model';


export class TableNotFoundError implements Error {
  constructor(tableJoiningCode: string) {
    this.name = `TableNotFoundError(tableJoiningCode="${tableJoiningCode}")`;
    this.message = `Could not find a table with tableJoiningCode="${tableJoiningCode}"`;
  }

  name: string;
  message: string;
}

@Injectable({
  providedIn: 'root'
})
export class TableService {

  constructor(
    private http: HttpClient,
    private tableSerializer: TableSerializer,
  ) { }

  loadTable(tableJoiningCode: string, event?: Event): Observable<Table> {
    // Using joining_code as secondary identifier, see https://stackoverflow.com/a/9743414
    return this.http.get<Table | null>('/api/v1/tables/' + tableJoiningCode).pipe(
      map((table: Table | null) => {
        this.tableSerializer.event = event;

        return table === null ? null : this.tableSerializer.deserialize(table);
      }),
      catchError((err: HttpErrorResponse) => {
        if(err.status === 404) {
          return throwError(new TableNotFoundError(tableJoiningCode));
        } else {
          return throwError(err);
        }
      })
    );
  }
}
