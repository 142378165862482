import { Component, Input, OnInit } from '@angular/core';
import { Table } from '../booking.model';

@Component({
  selector: 'app-management-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.scss']
})
export class TableHeaderComponent implements OnInit {

  @Input() table: Table;

  constructor() { }

  ngOnInit(): void { }
}
