<div class="p-2">
    <button
        type="button"
        class="btn btn-white btn-sm shadow-none"
        (click)="isParticipantChoicesCollapsed = !isParticipantChoicesCollapsed"
        [attr.aria-expanded]="!isParticipantChoicesCollapsed"
        aria-controls="collapseParticipantChoice"
    >
        <span *ngIf="isParticipantChoicesCollapsed" class="mx-2">
            <span i18n>Show choices</span>
            <span class="btn-inner--icon"><i class="fa fa-caret-down"></i></span>
        </span>
        <span *ngIf="!isParticipantChoicesCollapsed" class="mx-2">
            <span i18n>Hide choices</span>
            <span class="btn-inner--icon"><i class="fa fa-caret-up"></i></span>
        </span>
    </button>
</div>
<div #collapse="ngbCollapse" [(ngbCollapse)]="isParticipantChoicesCollapsed" class="p-2 p-md-3">
    <app-participants-selection [enabled]="false" [participantChoices]="participantChoices"></app-participants-selection>
</div>
