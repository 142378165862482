<h2 class="border-bottom py-1"
    i18n="account bookings title|A title for the section for managing reservations">
    Reservations
</h2>
<p
    i18n="account bookings details|More details about the section for managing reservations">
    You can find here all your future and past reservations.
</p>

<div class="mt-5">
    <h3 class="border-bottom py-1"
        i18n="account bookings upcoming title|A title for the section for managing future reservations">
        Current reservations
    </h3>
    <p
        i18n="account bookings upcoming details|More details about the section for managing future reservations">
        Future and currently open bookings.
    </p>
    <div *ngIf="openBookings?.length > 0; else noOpenBookings">
        <app-booking
            [booking]="booking"
            *ngFor="let booking of openBookings"
        ></app-booking>
    </div>
    <ng-template #noOpenBookings i18n>
        No open bookings.
    </ng-template>
</div>
<div class="my-5">
    <h3 class="border-bottom py-1"
        i18n="account bookings past title|A title for the section for viewing past reservations">
        Past reservations
    </h3>
    <p
        i18n="account bookings past details|More details about the section for viewing past reservations">
        Closed reservations.
    </p>
    
    <div *ngIf="closedBookings?.length > 0; else noClosedBookings">
        <app-booking
            [booking]="booking"
            *ngFor="let booking of closedBookings"
        ></app-booking>
    </div>
    <ng-template #noClosedBookings i18n>
        You have not booked any event yet.
    </ng-template>
</div>
