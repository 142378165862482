<div [ngSwitch]="state?.step">
    <app-checkin-select-event *ngSwitchCase="CheckinFlowState.STEP_SELECT_EVENT" [modal]="this" [events]="events"></app-checkin-select-event>
    <app-checkin-start *ngSwitchCase="CheckinFlowState.STEP_CHECKIN_START" [modal]="this" [event]="selectedEvent" [showBackButton]="isCheckInStartBackButtonVisible()"></app-checkin-start>
    <app-checkin-confirmation *ngSwitchCase="CheckinFlowState.STEP_CHECKIN_CONFIRMATION" [modal]="this"></app-checkin-confirmation>
    <!-- TODO: not yet supported -->
    <!-- <app-checkin-cancellation *ngSwitchCase="CheckinFlowState.STEP_CHECKIN_DENY" [modal]="this"></app-checkin-cancellation> -->
    <app-checkin-failure *ngSwitchCase="CheckinFlowState.STEP_CHECKIN_FAILURE" [modal]="this"></app-checkin-failure>
    <div class="d-flex justify-content-center my-5 mx-auto" *ngSwitchDefault>
        <div class="spinner-border" role="status">
            <span class="sr-only">Loading...</span>
        </div>
    </div>
</div>
