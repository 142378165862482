<div
  [routerLink]="['/management/events', event.id]"
  class="card shadow shadow-lg--hover mt-0 mt-lg-3 flex-fill"
>
  <div class="position-absolute" style="right: 5px; top: -15px">
    <a
      *ngIf="event.is_takeaway"
      ><i class="fa fa-cube m-1 p-3 bg-info shadow rounded text-white"></i
    ></a>
    <a
      *ngIf="event.hot"
      title="hot deal"
      ><i class="fa fa-fire m-1 p-3 bg-danger shadow rounded text-white"></i
    ></a>
  </div>
  <div class="text-center">
    <span class="text-uppercase">
        <span *ngIf="!event.is_single_day" class="small" i18n> From </span>
        <span class="badge badge-pill badge-secondary">{{
          event.start_datetime | date: 'longDate'
        }}</span>
        <span *ngIf="!event.is_single_day">
          <span class="small" i18n> to </span>
          <span class="badge badge-pill badge-secondary">{{
            event.end_datetime | date: 'longDate'
          }}</span>
        </span>
      </span>
  </div>
  <img
    class="card-img-top pb-4"
    style="min-height: 200px"
    src="{{ event.image_url }}"
    alt=""
  />
  <div class="card-body pt-0">
    <div class="position-relative float-right" style="top: -50px; right: -10px">
      <div
        class="rd-icon icon icon-shape bg-secondary rounded-circle text-white shadow"
      >
        <img src="{{ event.icon_url }}" alt="{{ event.icon }}" />
      </div>
    </div>
    <div class="pb-4">
      <div class="pl-4 pl-md-0 pl-lg-4">
        <div class="mb-3">
          <span
            *ngFor="let tag of event.tags"
            class="text-dark"
          >
            #{{ tag.name.toLocaleLowerCase() }}
          </span>
        </div>
        <div class="mt-4">
          <h4 class="card-title text-primary mt-2 mb-0">
            {{ event.name }}
          </h4>
          <p
            class="text-muted mb-0"
            [innerHTML]="event.short_description | safeHtml"
          ></p>
        </div>
        <div class="row pr-4 mt-2">
          <p class="col mx-2 text-right px-0">
            <del
              *ngIf="event.nextBookingPrice() !== event.original_price"
              class="text-muted"
              >({{ event.original_price }} €)</del
            >
          </p>
          <p class="display-3 col-auto text-right px-0">
            <!--
              Show price you would get if you booked the event only if it is bookable,
              otherwise show current price.
              This matters only for events that are full or closed.
              If it is too early the base price will be shown in any case.
            -->
            {{ event.nextBookingPrice() }} €
          </p>
        </div>
      </div>
    </div>
  </div>
</div>
