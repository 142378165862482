<div class="container">
  <div class="form-group">
    <label i18n>Find deals near you</label>
    <app-location-input></app-location-input>
  </div>

  <app-location-map></app-location-map>

  <div *ngIf="location">
    <h5>Address: {{ location.address }}</h5>
    <div>Latitude: {{ location.latitude }}</div>
    <div>Longitude: {{ location.longitude }}</div>
  </div>
</div>
