<main>
  <div class="position-relative">
    <section class="px-1 px-md-4 section section-lg section-shaped jumbotron">
      <div class="container py-lg-md">
        <div class="row expand-row-lg">
          <div class="py-4 px-4 pr-lg-2 col-12 col-lg-4">
            <div class="row">
              <div class="col-12 d-block d-lg-none">
                <app-auth *ngIf="!isAuthenticated" [style]="'home-vertical'"></app-auth>
              </div>
              <div class="col-12">
                <p class="lead text-black" i18n style="font-family: 'Josefin Sans', sans-serif; font-size: 2.2rem;">
                  Looking for an<br/> <span class="font-weight-bold">exclusive experience</span><br/> at the <span class="font-weight-bold">best price</span>?
                </p>
                <button class="btn btn-white btn-lg" (click)="goToSection('howDoesItWork')" i18n>
                  <i class="fa fa-arrow-down pr-2" aria-hidden="true"></i> Discover Restaudeal!
                </button>
              </div>
            </div>
          </div>
          <div class="py-3 px-0 px-md-3 col-12 col-lg-8" style="min-height: 32rem;">
            <div class="text-uppercase badge badge-light mx-4 px-2" i18n>
              {events?.length, plural, =1 {Current deal:} other {Current deals:}}
            </div>
            <app-event-carousel [events]="events"></app-event-carousel>
          </div>
        </div>
        <div class="row justify-content-center">
          <div class="d-none d-lg-block col-md-11 col-lg-8 col-xl-7">
            <app-auth *ngIf="!isAuthenticated" [style]="'home-horizontal'"></app-auth>
          </div>
        </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
      <!-- Quickfix for background transparency -->
      <div class="jumbotron-bg-home"></div>
    </section>
    <section class="section pb-5">
      <div class="container mb-5">
        <h2 id="howDoesItWork" class="text-black" i18n>How does it work?</h2>
        <div class="row justify-content-md-center">
          <div class="col-md-10">
            <p class="lead" i18n>
              Restaudeal proposes you exclusive deals based on a new concept:
            </p>
            <div class="display-4 text-center text-primary"><em i18n>collaborative discounts!</em></div>
            <p class="lead" i18n>
              Simply put, <b>the more you are, the more you save</b> ...and it does not matter if you sit at the same table!
            </p>
            <img src="./assets/img/high_five_deal.svg" alt="Get better deals together" class="d-block col-md-8 my-5 mx-auto" />
            <p i18n>
              <b>Every booking counts</b><br/>
              Come with your friends, make a group reservation or simply join a good deal to get access to the best prices.
              The more people join, the better the discount will be: the total number of participants to an event will decrease the final price.
            </p>
          </div>
        </div>
      </div>
      <div class="separator separator-bottom separator-skew">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-secondary-l" points="2560 -1 2560 100 -1 100"></polygon>
        </svg>
      </div>
    </section>
    <section class="section bg-secondary-l pb-0" style="height: 80rem;">
      <div class="container pb-5">
        <h2 class="text-black" i18n>A few ideas?</h2>
        <app-showroom></app-showroom>
      </div>
      <div class="separator separator-bottom separator-skew">
        <svg
          x="0"
          y="0"
          viewBox="0 -50 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          style="filter: drop-shadow(6px 0 20px hsla(0, 0%, 0%, 0.2));"
        >
          <polygon class="fill-white" points="2560 -1 2560 50 -1 50"></polygon>
        </svg>
      </div>
    </section>
    <section class="position-relative bg-white">
      <div class="container pt-4">
        <div class="row pt-5">
          <div class="offset-lg-2 col-lg-8 offset-sm-1 col-sm-10">
            <div class="shadow-lg p-5 mb-5 bg-white rounded">
              <h3 i18n>Do you manage a restaurant?</h3>
              <p i18n>
                <b>Get started by pre-registering it!</b><br/>
                We are ready to enhance your marketing strategy!
              </p>
              <div class="text-right pt-2">
                <a i18n-href="link to restaurant pre-registration form"
                   href="https://forms.gle/mXrcCDrpE8TbVtRf7"
                   target="_blank"
                   type="button"
                   class="btn btn-primary btn-lg w-100 wmin-md-40 text-white"
                   aria-label="Contact Us"
                   i18n>
                  Start Pre-Registration
                </a>
              </div>
              <hr/>
              <p i18n>
                <b>Any questions?</b><br/>
                We will be happy to get in touch with you and illustrate how Restaudeal can help your business.
              </p>
              <div class="text-right pt-2">
                <a i18n-href="link to restaurant contact form"
                   href="https://share.hsforms.com/1q26KRmtfR72ZqqAF1_LZUQ4kvq1"
                   target="_blank"
                   type="button"
                   class="btn btn-dark btn-lg w-100 wmin-md-40 text-white"
                   aria-label="Contact Us"
                   i18n>
                  Contact Us
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="row py-5">
          <div class="col text-center">
            <a href="https://www.twitter.com/restaudeal" target="_blank"><img src="./assets/img/icons/social/twitter.png" height="25" class="m-1"></a>
            <a href="https://www.instagram.com/restaudeal" target="_blank"><img src="./assets/img/icons/social/instagram.png" height="25" class="m-1"></a>
            <a href="https://www.facebook.com/restaudeal" target="_blank"><img src="./assets/img/icons/social/facebook.png" height="25" class="m-1"></a>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
