import { Component, Input, OnInit } from '@angular/core';
import { NgbCollapse } from '@ng-bootstrap/ng-bootstrap';

import { BookingFlowState } from '../booking-flow-state.model';
import { BookingFlowService } from '../booking-flow.service';
import { GtagService } from 'src/app/gtag/gtag.service';

@Component({
  selector: 'app-booking-menu',
  templateUrl: './booking-menu.component.html',
  styleUrls: ['./booking-menu.component.scss']
})
export class BookingMenuComponent implements OnInit {

  @Input() state: BookingFlowState;
  
  isTableChoicesViewCollapsed: boolean = true;

  loading = false;

  constructor(
    private bookingFlowService: BookingFlowService,
    private gtagService: GtagService,
  ) {
  }

  ngOnInit(): void {
  }
  
  isInvalidSelection() {
    return !this.state.participantChoices.isValid(this.state.selectedTable?.participant_choices);
  }

  previousStep() {
    // TODO: gTag
    this.bookingFlowService.previousStep();
  }

  nextStep() {
    // TODO: gTag

    // NOTE: state.selection is expected to be valid here
    this.bookingFlowService.nextStep();
  }

  counter(i: number) {
    return new Array(i);
  }

  toggleTableChoicesViewCollapsed() {
    this.isTableChoicesViewCollapsed = !this.isTableChoicesViewCollapsed;
  }
}
