<h2 class="border-bottom py-1"
    i18n="account general info title|A title for the section for managing generic account information used to make reservations">
    For your reservations
</h2>
<p
    i18n="account general info details|More details about the section for managing generic account information used to make reservations">
    We use this data only to make your reservation in the
    restaurants.
</p>
<form [formGroup]="accountForm" (ngSubmit)="onSave()" id="accountForm">
    <div class="form-group">
        <label for="emailInput" i18n="Email label">Email</label>
        <div class="input-group input-group-alternative">
            <input id="emailInput" type="text" class="form-control form-control-lg" placeholder="No email"
                i18n-placeholder="
            account email placeholder|Email input placeholder
            " formControlName="email" readonly />
        </div>
    </div>
    <div class="form-group">
        <label for="phoneNumber" i18n="phone label">Phone</label>
        <div class="input-group input-group-alternative d-block">
            <input
                id="phoneNumberInput"
                type="tel"
                class="form-control form-control-lg"
                style="padding-left: 52px; padding-right: 6px"
                formControlName="phone_number"
                [ngClass]="{
                    'is-invalid':
                    accountForm.controls.phone_number.invalid &&
                    accountForm.controls.phone_number.dirty,
                    'is-valid':
                    !accountForm.controls.phone_number.invalid &&
                    accountForm.controls.phone_number.dirty
                }"
                ng2TelInput
                [ng2TelInputOptions]="{initialCountry: countryCode, preferredCountries: PREFERRED_COUNTRIES}"
                (ng2TelOutput)="geti18nPhoneNumber($event)"
                (intlTelInputObject)="getNg2TelInput($event)"
                (countryChange)="onCountryChange()"
            />
        </div>
    </div>
    <div class="form-group">
        <label for="firstNameInput" i18n="First name label">First Name</label>
        <div class="input-group input-group-alternative">
            <input id="firstNameInput" type="text" class="form-control form-control-lg" [ngClass]="{
            'is-invalid':
                accountForm.controls.first_name.invalid &&
                accountForm.controls.first_name.dirty,
            'is-valid':
                !accountForm.controls.first_name.invalid &&
                accountForm.controls.first_name.dirty
            }" placeholder="First Name" i18n-placeholder="First name input placeholder" formControlName="first_name" />
        </div>
    </div>
    <div class="form-group">
        <label for="lastNameInput" i18n="Last name label">Last Name</label>
        <div class="input-group input-group-alternative">
            <input id="lastNameInput" type="text" class="form-control form-control-lg" [ngClass]="{
            'is-invalid':
                accountForm.controls.last_name.invalid &&
                accountForm.controls.last_name.dirty,
            'is-valid':
                !accountForm.controls.last_name.invalid &&
                accountForm.controls.last_name.dirty
            }" placeholder="Last Name" i18n-placeholder="Last name input placeholder" formControlName="last_name" />
        </div>
    </div>
    <div class="form-group">
        <label for="birthDateInput" i18n="Birth date label">Birthday</label>
        <div class="input-group input-group-alternative">
            <div class="input-group-prepend">
                <span class="input-group-text"><i class="ni ni-calendar-grid-58"></i></span>
            </div>
            <input id="birthDateInput" type="text" name="dp" class="form-control form-control-lg datepicker" [ngClass]="{
            'is-invalid':
                accountForm.controls.birth_date.invalid &&
                accountForm.controls.birth_date.dirty,
            'is-valid':
                !accountForm.controls.birth_date.invalid &&
                accountForm.controls.birth_date.dirty
            }" placeholder="Select date" i18n-placeholder="datepicker placeholder|Placeholder for date selection input"
                formControlName="birth_date" #d="ngbDatepicker" (click)="d.toggle()" ngbDatepicker
                [minDate]="{ year: minBirthDateYear, month: 1, day: 1 }"
                [maxDate]="{ year: maxBirthDateYear, month: 12, day: 31 }" [startDate]="{
            year: maxBirthDateYear - 10,
            month: 1,
            day: 1
            }" />
        </div>
    </div>
    <div class="form-group" *ngIf="user.is_manager">
      <label for="telegramUsernameInput" i18n="Telegram username label">Telegram username</label>
      <div>
          <input id="telegramUsernameInput"
                 type="text"
                 class="form-control form-control-lg"
                 [ngClass]="{
                    'is-invalid':
                        accountForm.controls.telegram_username.invalid &&
                        accountForm.controls.telegram_username.dirty,
                    'is-valid':
                        !accountForm.controls.telegram_username.invalid &&
                        accountForm.controls.telegram_username.dirty
                 }"
                 placeholder="Telegram username"
                 i18n-placeholder="Telegram username input placeholder"
                 formControlName="telegram_username"
                 aria-describedby="telegram-username-desc"
          />
          <div class="invalid-feedback" *ngIf="accountForm.controls.telegram_username.invalid">
            <span class="alert-inner--icon mr-2"><i class="fa fa-exclamation-circle"></i></span>
            <ng-container *ngIf="accountForm.get('telegram_username').hasError('serverError')" i18n>
              {{ accountForm.get('telegram_username').getError('serverError') }}
            </ng-container>
          </div>
          <small i18n="Telegram username input description" id="telegram-username-desc" class="form-text text-muted">
            In order to receive notifications from Restaudeal on Telegram, you must first start a chat with our bot.<br />
            To do that now, please <a href="tg://resolve?domain=restaudeal_bot">click here</a>, accept opening the Telegram app, then click "START".<br />
            If this does not work, search the <em>RestaudealBot</em> account on Telegram and start a chat with it.<br />
            <em>Please note that this operation must be done before setting your Telegram username here.</em>
          </small>
      </div>
    </div>
    <button type="submit" class="btn btn-primary float-right px-5 my-3" [disabled]="accountForm.invalid"
        i18n="Save button">
        Save
    </button>
</form>
