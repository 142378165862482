<nav
  class="navbar navbar-expand navbar position-fixed bg-white shadow w-100 p-0 pt-1 pt-lg-2"
  style="z-index: 5; margin-top: 4.5rem"
>
  <div class="w-100 container mt-lg-2">
    <ul id="deal-nav-compact" class="navbar-nav">
      <li class="nav-item px-2" [class.active]="currentSection === 'deal'">
        <a class="nav-link" routerLink="." fragment="deal" i18n>Deal</a>
      </li>
      <li
        class="nav-item px-2"
        [class.active]="currentSection === 'restaurant'"
      >
        <a class="nav-link" routerLink="." fragment="restaurant" i18n>Restaurant</a>
      </li>
      <li
        class="nav-item px-2"
        [class.active]="currentSection === 'reservation'"
      >
        <a class="nav-link" routerLink="." fragment="reservation" i18n
          >Reservation</a
        >
      </li>
    </ul>
    <app-event-alert-booking-time-details class="d-none d-sm-block ml-lg-auto" [event]="event" [ngSwitch]="timeLeftMessageType"></app-event-alert-booking-time-details>
  </div>
</nav>
<main class="mb-5" style="min-height: 100vh">
  <div class="">
    <section class="section" style="margin-top: 6rem">
      <div class="d-flex justify-content-center m-5" *ngIf="!event">
        <div class="spinner-border" role="status">
          <span class="sr-only">Loading...</span>
        </div>
      </div>
      <div class="container" *ngIf="event">
        <div class="row">
          <div id="deal-page" class="col-lg-8 mt-2">
            <h3 id="deal" i18n>Deal</h3>
            <div class="d-lg-none card shadow border-0 w-auto mb-5 m-md-5 px-md-3">
              <div class="card-body pb-0 pb-md-3">
                <div class="position-absolute" style="right: 5px; top: -35px">
                  <div class="position-relative" style="right: -20px">
                    <a
                      *ngIf="event.is_takeaway"
                      [routerLink]="['/events']"
                      [queryParams]="{ is_takeaway: true }"
                      (click)="$event.stopPropagation()"
                      ><i
                        class="fa fa-cube m-1 p-3 bg-info shadow rounded text-white"
                      ></i
                    ></a>
                    <a
                      *ngIf="event.hot"
                      title="hot deal"
                      [routerLink]="['/events']"
                      [queryParams]="{ hot: true }"
                      (click)="$event.stopPropagation()"
                      ><i
                        class="fa fa-fire m-1 p-3 bg-danger shadow rounded text-white"
                      ></i
                    ></a>
                    <span>
                      <span class="h-100 rd-icon fa food-icon m-1 bg-secondary rounded shadow">
                        <img src="{{ event.icon_url }}" alt="{{ event.icon }}" />
                      </span>
                    </span>
                  </div>
                </div>

                <h2 class="text-primary mt-3">{{ event.name }}</h2>

                <span class="text-muted text-uppercase">
                  <span *ngIf="!event.is_single_day" class="small" i18n> From </span>
                  <span class="badge badge-pill badge-secondary">{{
                    event.start_datetime | date: 'longDate'
                  }}</span>
                  <span *ngIf="!event.is_single_day">
                    <span class="small" i18n> to </span>
                    <span class="badge badge-pill badge-secondary">{{
                      event.end_datetime | date: 'longDate'
                    }}</span>
                  </span>
                </span>

                <div class="py-3 d-sm-none">
                  <app-event-alert-booking-time-list [event]="event" [ngSwitch]="timeLeftMessageType"></app-event-alert-booking-time-list>
                </div>

                <div class="row pr-4 mt-2">
                  <p class="col text-right" style="line-height: 3rem" i18n>
                    Current price per person:
                  </p>
                  <p
                    *ngIf="state.discount.totalDiscount > 0"
                    class="col-auto px-0 mx-2 text-right"
                  >
                    <del class="text-muted">({{ event.original_price }} €)</del>
                  </p>
                  <p class="display-3 col-auto pl-0 text-right">
                    {{ state.discount.eventPrice }} €
                  </p>
                </div>


                <div *ngIf="shouldShowShareEventCard()">
                  <p class="h6 mb-0" i18n>Want to pay less?</p>
                  <p class="h6 text-primary mb-3" i18n>
                    You can save up to
                    {{ nbSelectedParticipants * (event.original_price - event.best_price) }} €
                    by sharing it!
                  </p>
                  <p class="text-center">
                    <button class="mx-auto btn btn-outline-default" i18n (click)="shareEvent()">
                      Share the event!
                      <a class="my-2 pl-4">
                        <i class="fa fa-share-alt" aria-hidden="true"></i>
                      </a>
                    </button>
                  </p>
                  <div *ngIf="state.discount.remainingPeopleForNextDeal">
                    <p class="mb-0" i18n>
                      <span *ngIf="state.discount.remainingPeopleForNextDeal < 3">Just </span>
                      {{ state.discount.remainingPeopleForNextDeal }}
                      {state.discount.remainingPeopleForNextDeal, plural, =1 {person left} other {people left}}
                      to get this deal for {{ state.discount.nextAmount }} € less!
                    </p>
                    <p *ngIf="event.next_deals.length > 1" i18n>
                      Bring more people to get even more discount (even if you
                      don't share the same table)! ;)
                    </p>
                  </div>
                </div>

                <div class="row mx-2 my-1">
                  <div
                    class="alert alert-success mx-auto"
                    *ngIf="event.can_be_booked && event.next_deals.length == 0"
                  >
                    <strong>
                      <i class="fa fa-star" aria-hidden="true"></i>
                      <span i18n="event details sidebar - best discount unlocked message"> This is the best price you can get!</span>
                    </strong>
                  </div>
                </div>

                <div class="row my-2">
                  <button
                    class="col btn btn-primary"
                    (click)="goToSection('reservation')"
                    routerLink="."
                    fragment="reservation"
                    [disabled]="!event.can_be_booked"
                  >
                    <span *ngIf="event.can_be_booked; else eventCannotBeBookedButtonText">
                      <strong i18n>START RESERVATION</strong>
                      <span *ngIf="state.discount.totalDiscount > 0" i18n>
                        <br />(you will save
                        <span *ngIf="!state.discount.isBestDiscount()">at least </span>
                        {{ state.discount.totalDiscount }} €)
                      </span>
                    </span>
                  </button>
                </div>
              </div>
            </div>

            <div class="mt-3 mb-3">
              <a
                *ngFor="let tag of event.tags"
                class="text-dark"
                [routerLink]="['/events']"
                [queryParams]="{ category: tag.slug }"
              >
                #{{ tag.name.toLocaleLowerCase() }}
              </a>
            </div>

            <p class="my-5" [innerHTML]="event.description | safeHtml"></p>

            <ng-container *ngIf="event.getDeals().length > 0">
              <h5 i18n>Tiers</h5>
              <hr class="my-4" />
              <p i18n>Unlock the best prices by joining this event with your friends!</p>
              <div class="mb-5">
                <table class="table table-bordered">
                  <thead class="thead-dark">
                    <tr>
                      <th i18n>Level</th>
                      <th i18n>Minimum Participants</th>
                      <th i18n>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let deal of event.getDeals()" [class.table-primary]="deal === event.getCurrentDeal()">
                      <ng-container *ngIf="event.getDiscountForDeal(deal); let d">
                        <td>
                          {{ deal.order + 1 }}
                          <span class="pl-3" *ngIf="deal === event.getCurrentDeal() && d.isRestaudealBasePrice()">😊</span>
                          <span class="pl-3" *ngIf="deal === event.getCurrentDeal() && d.isIntermediateDiscount()">😁</span>
                          <span class="pl-3" *ngIf="deal === event.getCurrentDeal() && d.isBestDiscount()">🤩</span>
                        </td>
                        <td>
                          <ng-container *ngIf="!d.isFullPublicPrice() && !d.isExclusiveBasePrice()">{{ d.currentParticipants }}</ng-container>
                          <ng-container *ngIf="d.isFullPublicPrice()" i18n>Price without Restaudeal</ng-container>
                          <ng-container *ngIf="d.isExclusiveBasePrice()" i18n>Base price</ng-container>
                        </td>
                        <td>{{ d.eventPrice }} €</td>
                      </ng-container>
                    </tr>
                  </tbody>
                </table>
              </div>
            </ng-container>

            <div class="mx-auto w-75 alert-info mb-5 p-3 rounded shadow">
              <h5 class="text-white">
                <i class="fa fa-info-circle mr-3" aria-hidden="true"></i
                ><strong i18n>Hey, did you know?</strong>
              </h5>
              <span class="d-block" i18n
                >You can get discounts from people not eating with you!</span
              >
              <small i18n>
                Discounts are unlocked based on the overall deal popularity.<br />Every person counts, not only the ones sitting at your table!
              </small>
            </div>

            <hr class="my-4 mx-5" />

            <h3 id="restaurant" i18n>Restaurant</h3>
            <div class="my-4">
              <h4 class="text-uppercase mb-0 h5">{{ event.place.name }}</h4>
              <p class="mb-0">
                <small class="text-muted">
                  <i class="fa fa-map-marker pr-2" aria-hidden="true"></i
                  >{{ event.place.address }}
                </small>
              </p>
              <p>
                <small class="text-muted" *ngIf="event.place.phone_number">
                  <i class="fa fa-phone pr-2" aria-hidden="true"></i
                  ><a href="tel:{{ event.place.getPhoneNumberUrlFormat() }}">{{ event.place.phone_number }}</a>
                </small>
              </p>
            </div>
            <div class="mt-4">
              <app-location-map
                [latitude]="event.place.location?.coordinates[1]"
                [longitude]="event.place.location?.coordinates[0]"
              ></app-location-map>
              <p
                class="mt-4"
                *ngIf="event.place.description"
                [innerHTML]="event.place.description | safeHtml"
              ></p>
            </div>

            <hr class="my-4 mx-5" />
            <h3 id="reservation" i18n>Reservation</h3>
            <app-booking-flow [event]="event"></app-booking-flow>
          </div>
          <div class="col-lg-4 mt-lg-4">
            <div
              class="position-fixed mr-3 d-none d-lg-block"
              style="max-width: 380px; top: 25vh"
            >
              <div class="card shadow border-0">
                <div class="card-body pt-3 pb-0">
                  <div class="position-absolute" style="right: 5px; top: -35px">
                    <div class="position-relative" style="right: -20px">
                      <a
                        *ngIf="event.is_takeaway"
                        [routerLink]="['/events']"
                        [queryParams]="{ is_takeaway: true }"
                        (click)="$event.stopPropagation()"
                        ><i
                          class="fa fa-cube m-1 p-3 bg-info shadow rounded text-white"
                        ></i
                      ></a>
                      <a
                        *ngIf="event.hot"
                        title="hot deal"
                        [routerLink]="['/events']"
                        [queryParams]="{ hot: true }"
                        (click)="$event.stopPropagation()"
                        ><i
                          class="fa fa-fire m-1 p-3 bg-danger shadow rounded text-white"
                        ></i
                      ></a>
                      <span>
                        <span class="h-100 rd-icon fa food-icon m-1 bg-secondary rounded shadow">
                          <img src="{{ event.icon_url }}" alt="{{ event.icon }}" />
                        </span>
                      </span>
                    </div>
                  </div>

                  <h2 class="text-primary mt-3">{{ event.name }}</h2>
                  <span class="text-muted text-uppercase">
                    <span *ngIf="!event.is_single_day" class="small" i18n> From </span>
                    <span class="badge badge-pill badge-secondary">{{
                      event.start_datetime | date: 'longDate'
                    }}</span>
                    <span *ngIf="!event.is_single_day">
                      <span class="small" i18n> to </span>
                      <span class="badge badge-pill badge-secondary">{{
                        event.end_datetime | date: 'longDate'
                      }}</span>
                    </span>
                  </span>

                  <hr class="my-4" />

                  <div class="row pr-4 mt-2">
                    <div class="col" style="line-height: 3rem" i18n>
                      Current price:
                    </div>
                    <div
                      *ngIf="state.discount.totalDiscount > 0"
                      class="col-auto px-0 mx-2 text-right"
                    >
                      <del class="text-muted"
                        >({{ state.discount.bookingOriginalPrice }} €)</del
                      >
                    </div>
                    <div class="display-3 col-auto px-0 pr-xl-2 text-right" i18n>
                      {{ state.discount.bookingPrice }} €
                    </div>
                  </div>
                  <div class="small text-right pr-3" style="height: 2rem">
                    <span *ngIf="state.nbSelectedParticipants > 1">
                      ({{ state.nbSelectedParticipants }}x {{ state.discount.eventPrice }} €)
                    </span>
                  </div>

                  <div class="row mx-2 my-1">
                    <div
                      class="alert alert-success"
                      *ngIf="event.can_be_booked && event.next_deals.length == 0"
                    >
                      <strong>
                        <i class="fa fa-star" aria-hidden="true"></i>
                        <span i18n="event details sidebar - best discount unlocked message"> This is the best price you can get!</span>
                      </strong>
                    </div>
                  </div>

                  <div class="row my-2">
                    <button
                      class="col btn btn-primary"
                      (click)="goToSection('reservation')"
                      routerLink="."
                      fragment="reservation"
                      [disabled]="!event.can_be_booked"
                    >
                      <span *ngIf="event.can_be_booked; else eventCannotBeBookedButtonText">
                        <strong i18n>START RESERVATION</strong>
                        <span *ngIf="state.discount.totalDiscount > 0" i18n>
                          <br />(you will save
                          <span *ngIf="!state.discount.isBestDiscount()">at least </span>
                          {{ state.discount.totalDiscount }} €)
                        </span>
                      </span>
                      <ng-template #eventCannotBeBookedButtonText>
                        <strong i18n>Event not available</strong>
                      </ng-template>
                    </button>
                  </div>
                </div>
              </div>
              <div class="card shadow border-0 mt-3" *ngIf="shouldShowShareEventCard()">
                <div class="card-body py-3">
                  <h5 i18n>Want to pay less?</h5>
                  <div class="collapse-with-screen-sm-h">
                    <p class="h6 text-primary mb-3" i18n>
                      You can save up to
                      {{ nbSelectedParticipants * (event.original_price - event.best_price) }} €
                      by sharing it!
                    </p>
                    <div class="small pb-3" *ngIf="state.discount.remainingPeopleForNextDeal">
                      <div i18n>
                        <span *ngIf="state.discount.remainingPeopleForNextDeal < 3">Just </span>
                        {{ state.discount.remainingPeopleForNextDeal }} {state.discount.remainingPeopleForNextDeal, plural, =1 {person} other {people}} left to get this
                        deal for {{ state.discount.nextAmount }} € less each!
                      </div>
                      <div i18n>
                        Bring more people to get even more discount <br/>(even if you don't share the same table)! ;)
                      </div>
                    </div>
                  </div>
                  <div class="text-center">
                    <button
                      class="mx-auto btn btn-outline-default"
                      data-toggle="tooltip"
                      data-placement="bottom"
                      data-container="body" data-animation="true"
                      i18n-title="{{ event.people_nb_to_next_deal }} {event.people_nb_to_next_deal, plural, =1 {person} other {people}} left to get this deal for {{ state.nbSelectedParticipants * event.next_deals[0].amount }} € less!"
                      i18n
                      (click)="shareEvent()"
                    >
                      Share the event
                      <a class="my-2 pl-4"><i class="fa fa-share-alt" aria-hidden="true"></i></a>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
