import { Event } from "../../events/event.model";
import { Booking } from "../bookings/booking.model";

export class CheckinFlowState {
    static STEP_SELECT_EVENT = 1;
    static STEP_CHECKIN_START = 2;
    static STEP_CHECKIN_CONFIRMATION = 3;
    static STEP_CHECKIN_DENY = 4;
    static STEP_CHECKIN_FAILURE = 5;

    constructor(
        public event?: Event,
        public step?: number,
        public code?: string,
        public booking?: Booking,
        public bookingStatus?: string,
        public checkInFailureReason?: {code: string, message: string},
        public checkInDeniedReason?: string,
        public checkInDeniedNotes?: string,
    ) {
        if (!step) {
            if(event) {
                this.step = CheckinFlowState.STEP_CHECKIN_START;
            } else {
                this.step = CheckinFlowState.STEP_SELECT_EVENT;
            }
        }
    }
}
