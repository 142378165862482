import { Injectable, Inject } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Event } from '../event.model';
import { EventShareComponent } from './event-share.component';
import { DOCUMENT, Location } from '@angular/common';
import { isDesktopStrict } from '../../shared/utils/browser'
import { GtagService } from 'src/app/gtag/gtag.service';
import {unlocalizedURL} from '../../shared/utils/i18n';

@Injectable({
  providedIn: 'root',
})
export class EventShareService {
  constructor(
    @Inject(DOCUMENT) private readonly document: any,
    private readonly location: Location,
    private readonly modalService: NgbModal,
    private readonly gtagService: GtagService,
  ) {}

  showModalView(event: Event) {
    const eventUrl = unlocalizedURL(this.getEventUrl(event));

    // isDesktopStrict() is used to prevent native sharing on Desktop systems
    // (we do not expect users to have native apps installed on a PC)
    if (navigator.share && !isDesktopStrict()) {
      // use native sharing
      this.shareNative(event, eventUrl);
    } else {
      // show modal view
      this.shareModal(event, eventUrl);
    }
  }

  private getEventUrl(event) {
    // see https://stackoverflow.com/questions/51984268/how-to-get-base-url-in-angular-5
    return this.document.location.origin + this.location.prepareExternalUrl(`/events/${event.id}`);
  }

  private shareNative(event: Event, eventUrl: string) {
    // NOTE: registering share intention.
    // It is NOT possible to know if the user actually shared the event and with which app.
    this.gtagService.share({
      method: "native",
      content_type: "event",
      item_id: event.id,
    });

    navigator
      .share({
        title: `${event.name} @ ${event.place.name}`,
        text: "Let's get the best deal!",
        url: eventUrl,
      })
      .then(() => console.log('Shared! 🎉'))
      .catch((err) => {
        console.error(err);
        this.gtagService.exception("Could not share event");
      });
  }

  private shareModal(event: Event, eventUrl: string) {
      const modalRef = this.modalService.open(EventShareComponent, {
        windowClass: 'modal',
        centered: true,
      });
      modalRef.componentInstance.event = event;
      modalRef.componentInstance.eventUrl = eventUrl;
  }
}
