import { Component, TemplateRef } from '@angular/core';
import { AlertService } from './alert.service';

@Component({
  selector: 'app-alerts',
  template: `
    <ngb-alert
      *ngFor="let alert of alertService.alerts"
      type="{{ alert.type }}"
    >
      <ng-template [ngIf]="isTemplate(alert)" [ngIfElse]="text">
        <ng-template [ngTemplateOutlet]="alert.textOrTpl"></ng-template>
      </ng-template>

      <ng-template #text>{{ alert.textOrTpl }}</ng-template>
    </ngb-alert>
  `,
})
export class AlertsComponent {
  constructor(public alertService: AlertService) {}

  isTemplate(alert) {
    return alert.textOrTpl instanceof TemplateRef;
  }
}
