import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../../../events/event.model';
import { Booking } from '../../bookings/booking.model';
import { CheckinFlowService } from '../checkin-flow.service';
import { CheckinModalComponent } from '../checkin-modal/checkin-modal.component';

@Component({
  selector: 'app-checkin-failure',
  templateUrl: './checkin-failure.component.html',
  styleUrls: ['./checkin-failure.component.scss']
})
export class CheckinFailureComponent implements OnInit {

  public event: Event;
  public booking: Booking;
  public failureReason: {code: string, message: string};

  @Input() modal: CheckinModalComponent;

  constructor(public checkinFlowService: CheckinFlowService) {
    this.event = this.checkinFlowService.stateSource.value.event;
    this.booking = this.checkinFlowService.stateSource.value.booking;
    this.failureReason = this.checkinFlowService.stateSource.value.checkInFailureReason;
  }

  ngOnInit(): void {
  }

  restart(): void {
    this.checkinFlowService.forEvent(this.event);
  }

  dismiss(): void {
    this.modal.dismiss();
  }
}
