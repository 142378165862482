import {Inject, Injectable, OnDestroy, PLATFORM_ID} from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { CookieConsent } from './consent.model';
import { UserEnabledService } from './user-enabled-service.model';
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root'
})
export class CookieService implements OnDestroy {

  private static LOCAL_STORAGE_KEY_COOKIE_CONSENT = "cookieConsent";

  // Technical cookies
  static RESTAUDEAL_SETTINGS_CONSENT_KEY = "restaudeal-settings";

  static GOOGLE_RECAPTCHA_CONSENT_KEY = "google-recaptcha";
  static GOOGLE_OAUTH_CONSENT_KEY = "google-oauth";
  static FACEBOOK_OAUTH_CONSENT_KEY = "facebook-oauth";

  static getMandatoryConsents() {
    return [
      CookieService.RESTAUDEAL_SETTINGS_CONSENT_KEY,
      CookieService.GOOGLE_RECAPTCHA_CONSENT_KEY,
      CookieService.GOOGLE_OAUTH_CONSENT_KEY,
      CookieService.FACEBOOK_OAUTH_CONSENT_KEY,
    ]
  }

  private readonly CONSENT_VERSION = 0;
  private readonly CONSENT_KEYS = CookieService.getMandatoryConsents();

  userConsent = new BehaviorSubject<CookieConsent>(undefined);
  isUserConsentRequired = new BehaviorSubject<boolean>(undefined);
  private isUserConsentRequiredSubscription = undefined;

  private readonly userEnabledServiceRegistry: Map<string, UserEnabledService> = new Map();

  private isBrowser: boolean;

  checkIsUserConsentRequired(userConsent?: CookieConsent) : boolean {
    if (userConsent === undefined) {
      // CookieService not initialized yet
      return false;
    }

    if (userConsent === null) {
      // user has not provdided any consent (no user feedback)
      return true;
    }

    // check if consents need to be updated
    return userConsent.version < this.CONSENT_VERSION;
  }

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  init(): void {
    console.debug(`DEBUG[cookie/cookie.service]: init`);
    let storedUserConsent: CookieConsent = null;
    if (this.isBrowser) {
      storedUserConsent = JSON.parse(localStorage.getItem(CookieService.LOCAL_STORAGE_KEY_COOKIE_CONSENT));
    }

    // FIXME: use subscription instead?
    if(storedUserConsent !== null) {
      this.activate(storedUserConsent.consents);
    }

    this.userConsent = new BehaviorSubject<CookieConsent>(storedUserConsent);
    this.isUserConsentRequired = new BehaviorSubject<boolean>(this.checkIsUserConsentRequired(storedUserConsent));
    this.isUserConsentRequiredSubscription = this.userConsent.subscribe(userConsent => {
      this.isUserConsentRequired.next(this.checkIsUserConsentRequired(userConsent));
    });
  }

  ngOnDestroy(): void {
    this.isUserConsentRequiredSubscription.unsubscribe();
  }

  consentAll() {
    console.debug(`DEBUG[cookie/cookie.service]: User consented to ALL cookies: ${this.CONSENT_KEYS}`);
    this.updateConsent(this.CONSENT_KEYS);
  }

  consent(consentKeys: string[]) {
    console.debug(`DEBUG[cookie/cookie.service]: User consented to the following cookies: ${consentKeys}`);
    this.updateConsent(consentKeys);
  }

  private updateConsent(consentKeys: string[]) {
    const consent = {
      "version": this.CONSENT_VERSION,
      "consents": consentKeys,
    };

    if (this.isBrowser) {
      localStorage.setItem(CookieService.LOCAL_STORAGE_KEY_COOKIE_CONSENT, JSON.stringify(consent));
    }
    this.userConsent.next(consent);

    // FIXME: use subscription instead?
    this.activate(consentKeys);
  }

  register(userEnabledService: UserEnabledService) {
    const k = userEnabledService.getConsentKey();
    if(this.userEnabledServiceRegistry.has(k)) {
      console.warn(`WARNING[cookie/cookie.service]: service ${k} already registered!`);
    } else {
      this.userEnabledServiceRegistry[k] = userEnabledService;
      this.CONSENT_KEYS.push(k);
      console.debug(`DEBUG[cookie/cookie.service]: registered service ${k} (idle)`);
    }
  }

  activate(consentKeys: string[]) {
    console.debug(`DEBUG[cookie/cookie.service]: activating: ${JSON.stringify(consentKeys)}`);
    const mandatoryConsents = CookieService.getMandatoryConsents();

    for(const c of consentKeys) {
      console.debug(`DEBUG[cookie/cookie.service]: activating: ${c}`);
      if(mandatoryConsents.includes(c)) {
        // technical cookie, nothing to do (already enabled)
        console.debug(`DEBUG[cookie/cookie.service]: ${c} ignored (nothing to activate, technical cookie)`);
        continue;
      }

      this.userEnabledServiceRegistry[c].activate();
      console.debug(`DEBUG[cookie/cookie.service]: activated: ${c}`);
    }
  }
}
