import { ViewportScroller } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { first } from 'rxjs';
import { SeoImage } from 'src/app/shared/components/seo/seo.model';
import { SeoService } from 'src/app/shared/components/seo/seo.service';
import { settings } from 'src/app/shared/settings';
import { Booking } from '../../bookings/booking.model';
import { BookingService } from '../../bookings/booking.service';
import { CheckinModalComponent } from '../../checkin-flow/checkin-modal/checkin-modal.component';
import { Event } from '../event.model';
import { EventService } from '../event.service';


type Tab = "orders" | "bookings";


@Component({
  selector: 'app-management-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent implements OnInit {
  event?: Event;  // NOTE: cannot use Input() for now (how to pass it when using routerLink? EventService could act as a cache?)
  bookings?: Booking[];

  tab = 'bookings';

  constructor(
    private route: ActivatedRoute,
    private eventService: EventService,
    private bookingService: BookingService,
    private readonly viewportScroller: ViewportScroller,
    private readonly modalService: NgbModal,
    private readonly seoService: SeoService,
  ) { }

  /////////////////////////////////////////////////////////////////////////////
  // SEO METADATA
  /////////////////////////////////////////////////////////////////////////////
  getSeoTitle(): string {
    return $localize `:management event page SEO title:Restaudeal | Event ${this.event.name} ${this.event.start_datetime.toLocaleDateString()} management`;
  }

  getSeoDescription(): string {
    return $localize `:management event page SEO description:Restaudeal | Page for managing event ${this.event.name} occuring on date  ${this.event.start_datetime.toLocaleDateString()}`;
  }

  getSeoImage(): SeoImage {
    return settings.DEFAULT_SEO_IMAGE;
  }

  getSeoUrl(): string | null {
    return null;
  }

  renderSeoMetadata(): void {
    this.seoService.renderSeoMetadata(this);
  }
  /////////////////////////////////////////////////////////////////////////////

  ngOnInit(): void {
    const eventId: string = this.route.snapshot.params.id;
    if (process.env.NODE_ENV === 'development') {
      console.log(`DEBUG[management.EventDetailComponent]: eventId in URL = ${eventId}`);
    }

    if(!this.event) {
      if (process.env.NODE_ENV === 'development') {
        console.log("DEBUG[management.EventDetailComponent]: event not provided, loading it by id specified in URL");
      }
      this.eventService.loadEvent(eventId).subscribe((event) => {
        this.event = event;
        this.renderSeoMetadata();

        this.bookingService.getBookings(this.event, BookingService.ORDER_BY_SCHEDULE_TABLE).subscribe((bookings) => {
          if (process.env.NODE_ENV === 'development') {
            console.log(`DEBUG[management.EventDetailComponent]: loaded ${bookings.length} bookings`);
          }
          this.bookings = bookings;
        });
      })
    } else {
      if (process.env.NODE_ENV === 'development') {
        console.log("DEBUG[management.EventDetailComponent]: event provided, loading it from preloaded Input");
      }
      // TODO: avoid to refetch the event object again
      this.renderSeoMetadata();
    }

    this.route.fragment.pipe(first()).subscribe(fragment => {
      if(!fragment || !["bookings", "orders"].includes(fragment)) {
        this.tab = "bookings";
      } else {
        this.setTab(fragment as Tab);
      }
    });
  }

  setTab(tab: Tab) {
    this.tab = tab;
    // go to section (quickfix)
    setTimeout(() => {
      this.viewportScroller.scrollToAnchor(tab);
    }, 100);
  }

  showCheckInModal() {
    // ignore if no event yet (should not happen)
    if(!this.event) return;

    const modalRef = this.modalService.open(CheckinModalComponent, {
      windowClass: 'modal',
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.selectedEvent = this.event;
    return modalRef;
  }

}
