import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { CheckinFlowService } from '../checkin-flow.service';
import { CheckinFlowState } from '../checkin-flow-state.model';
import { Event } from '../../events/event.model';

@Component({
  selector: 'app-checkin-modal',
  templateUrl: './checkin-modal.component.html',
  styleUrls: ['./checkin-modal.component.scss']
})
export class CheckinModalComponent implements OnInit, OnDestroy {

  CheckinFlowState = CheckinFlowState;

  @Input() events: Event[];
  @Input() selectedEvent?: Event;

  /**
   * If true, the Select Event step will be skipped if only an event is provided.
   */
  @Input() selectSingleEvent: boolean = true;
  
  private stateSub: Subscription;
  public state: CheckinFlowState;

  constructor(
    public activeModal: NgbActiveModal,
    public checkinFlowService: CheckinFlowService,
  ) { }

  ngOnInit(): void {
    if(this.selectedEvent) {
      this.checkinFlowService.forEvent(this.selectedEvent);
    } else if(this.selectSingleEvent && this.events.length === 1) {
      this.checkinFlowService.forEvent(this.events[0]);
    } else {
      this.checkinFlowService.init();
    }
    
    this.stateSub = this.checkinFlowService.stateSource.subscribe((state) => {
      this.selectedEvent = state.event;
      this.state = state;
    })
  }

  ngOnDestroy(): void {
    this.stateSub.unsubscribe();
  }

  isCheckInStartBackButtonVisible(): boolean {
    return this.events.length > 1;
  }
  
  dismiss(): void {
    this.activeModal.dismiss('Cross click');
  }
}
