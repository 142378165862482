export abstract class Serializer<T1, T2> {
  abstract deserialize(item: T1): T2;

  deserializeMany(apiItems: T1[]): T2[] {
    if(!apiItems) {
      return null;
    }

    const items: T2[] = [];
    for (const apiItem of apiItems) {
      items.push(this.deserialize(apiItem));
    }
    return items;
    // return items.map(item => this.deserialize(item));
  }
}
