import { settings } from "../../settings";
import {Router} from "@angular/router";
import {Inject, PLATFORM_ID} from "@angular/core";
import {isPlatformBrowser, LocationStrategy, PlatformLocation} from "@angular/common";

export type SeoImage = {
    url: string,
    mimeType?: string,
}

export type SeoLinkUrls = {
    canonical: string,
    alternates: { url: string, lang: string }[],
}

/**
 * Helper class to handle Restaudeal's URLs for SEO purposes.
 * It assumes that the format is (<protocol>://<host>/<lang?>/<path>)
 */
export class Url {

    isBrowser;
    constructor(
        public protocol: string,
        public host: string,
        public path: string, // without leading /
        public queryParams: string, // without ?
        public fragment: string, // without #
    ) {}

    static hrefToUrl(href: string): Url {
        // const protocol = window.location.protocol;
        // const host = window.location.host;
        // const hostUrl = window.location.protocol + "//" + window.location.host;
        const protocol = '';
        const host = '';
        const hostUrl = '';

        const url = href.startsWith(hostUrl) ? href : (href.startsWith("/") ? hostUrl + href : hostUrl + "/" + href);
        const queryParamsIndex = url.lastIndexOf("?");
        const fragmentIndex = url.lastIndexOf("#");
        const hasQueryParams = queryParamsIndex !== -1;
        const hasFragment = fragmentIndex !== -1;

        const path = (
            hasQueryParams ?
                url.substring(hostUrl.length + 1, queryParamsIndex) :
                (
                    hasFragment ?
                        url.substring(hostUrl.length + 1, fragmentIndex) :
                        url.substring(hostUrl.length + 1)
                )
        );

        const queryParams = (
            hasQueryParams ? (
                hasFragment ?
                    url.substring(queryParamsIndex + 1, fragmentIndex) :
                    url.substring(queryParamsIndex + 1)
            ) : ""
        );

        const fragment = hasFragment ? url.substring(fragmentIndex + 1) : "";

        return new Url(
            protocol,
            host,
            path,
            queryParams,
            fragment,
        )
    }

    get url(): string {
        let res = this.protocol + "://" + this.host + "/" + this.path;
        if (this.queryParams) {
            res += res + "?" + this.queryParams;
        }
        if (this.fragment) {
            res += res + "#" + this.fragment;
        }
        return res;
    }

    // WARNING: handling only 2-letter language codes!
    hasLanguage(): boolean {
        // try to get language part (e.g., 'fr/')
        const possibleLangPath = this.path.substring(0, 3);

        // check if matches language part format
        if ((possibleLangPath.length === 3 && possibleLangPath.endsWith("/")) || possibleLangPath.length === 2) {
            const lang = possibleLangPath.substring(0, 2);
            return settings.SUPPORTED_LANGUAGES.includes(lang);
        } else {
            return false;
        }
    }

    resolveCanonicalUrl(): Url {
        if (this.hasLanguage()) {
            // remove language part
            return new Url(
                this.protocol,
                this.host,
                this.path.substring(3),
                this.queryParams,
                this.fragment,
            );
        } else {
            return this.duplicate();
        }
    }

    withoutQueryParams(): Url {
        return new Url(
            this.protocol,
            this.host,
            this.path,
            "",
            this.fragment,
        );
    }

    withoutFragment(): Url {
        return new Url(
            this.protocol,
            this.host,
            this.path,
            this.queryParams,
            "",
        );
    }

    withLanguage(language: string): Url {
        // WARNING: handling only 2-letter language codes!
        if(language.length !== 2) {
            throw new Error("language can only be a 2-letter code");
        }

        return new Url(
            this.protocol,
            this.host,
            language + "/" + this.path,
            this.queryParams,
            this.fragment,
        );
    }

    withoutLanguage(): Url {
        return this.resolveCanonicalUrl();
    }

    duplicate(): Url {
        return new Url(
            this.protocol,
            this.host,
            this.path,
            this.queryParams,
            this.fragment,
        );
    }
}

export function resolveSeoLinkUrls(href: string, supportedLanguages: string[], keepQueryParamsAndFragment: boolean = false): SeoLinkUrls {
    const fullUrl = Url.hrefToUrl(href);
    const url = keepQueryParamsAndFragment ? fullUrl : fullUrl.withoutQueryParams().withoutFragment();
    const canonicalUrl = url.resolveCanonicalUrl();

    const alternates = supportedLanguages.map(l => {
        return {
            "lang": l,
            "url": url.withLanguage(l).url,
        }
    });

    return {
        canonical: canonicalUrl.url,
        alternates: alternates,
    }
}
