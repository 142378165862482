import {Inject, Injectable, Input, OnChanges, OnDestroy, OnInit, PLATFORM_ID, SimpleChanges} from '@angular/core';
import { Event } from '../event.model';
import {isPlatformBrowser} from "@angular/common";

// NOTE: https://stackoverflow.com/questions/63126067/class-is-using-angular-features-but-is-not-decorated-please-add-an-explicit-ang/64964736#64964736
@Injectable()
export abstract class EventAlertBookingTimeComponent implements OnInit, OnChanges, OnDestroy {

  public static readonly REFRESH_INTERVAL__SEC = 60;

  @Input() event: Event;

  private refreshInterval?: ReturnType<typeof setInterval>;
  timeLeftMessageType?: string;
  minutesLeft?: number;
  hoursLeft?: number;
  daysLeft?: number;

  private isBrowser : boolean;

  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  ngOnChanges(): void {
    if(this.refreshInterval) {
      clearInterval(this.refreshInterval);
      this.refreshInterval = null;
    }

    if(!this.event) {
      return;
    }

    this.refreshData();
    this.initInterval();
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    clearInterval(this.refreshInterval);
  }

  private initInterval() {
    if(!this.isBrowser) {
      return;
    }

    this.refreshInterval = setInterval(
      () => {
        this.refreshData();
      },
      EventAlertBookingTimeComponent.REFRESH_INTERVAL__SEC*1000
    );
  }

  private refreshData() {
    if(!this.event) {
      this.timeLeftMessageType = undefined;
      this.minutesLeft = undefined;
      this.hoursLeft = undefined;
      this.daysLeft = undefined;
      return;
    }

    const eventTime_msec = this.event.max_booking_datetime.getTime();
    const nowTime_msec = new Date().getTime();
    this.minutesLeft = Math.floor((eventTime_msec - nowTime_msec) / (1000*60));
    this.hoursLeft = Math.floor(this.minutesLeft / 60);
    this.daysLeft = Math.floor(this.hoursLeft / 24);
    this.timeLeftMessageType = this.getTimeLeftMessageType(this.minutesLeft);
  }

  private getTimeLeftMessageType(minutesLeft: number) {
    if(minutesLeft === undefined) {
      return undefined;
    }

    // Those conditions cover !this.event.can_be_booked
    if(minutesLeft <= 0) {
      return "too_late";
    }
    if(this.event.cannot_be_booked_reason === "no_schedule_can_be_booked") {
      return "no_schedule_can_be_booked";
    }
    if(this.event.cannot_be_booked_reason === "too_early") {
      return "too_early";
    }

    if(minutesLeft <= 60*2) {
        return "urgent_few_minutes";
    }
    if(minutesLeft <= 60*12) {
        return "urgent_few_hours";
    }
    if(minutesLeft <= 60*48) {
        return "warning_few_hours";
    }

    return "normal"
  }

}
