import { BrowserModule } from '@angular/platform-browser';
import { APP_INITIALIZER, ErrorHandler, NgModule } from '@angular/core';
import { Router } from '@angular/router';
import * as Sentry from '@sentry/angular-ivy';

import { ReactiveFormsModule } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import {
  SocialLoginModule,
  SocialAuthServiceConfig,
  GoogleLoginProvider,
  FacebookLoginProvider,
} from '@abacritt/angularx-social-login';

import { Ng2TelInputModule } from './ng2-tel-input/ng2-tel-input.module';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LocationComponent } from './location/location.component';
import { AuthComponent } from './auth/auth.component';
import { NavbarComponent } from './navbar/navbar.component';
import {
  HTTP_INTERCEPTORS,
  HttpClientModule,
  provideHttpClient,
  withFetch,
  withJsonpSupport
} from '@angular/common/http';
import { APIRequestInterceptorService } from './shared/interceptors/api-request.interceptor';
import { AuthInterceptor } from './auth/auth.interceptor';
import { ErrorInterceptor } from './shared/interceptors/api-error.interceptor';
import { HomeComponent } from './home/home.component';
import { EventItemComponent } from './events/event-list/event-item/event-item.component';
import { EventItemCompactComponent } from './events/event-list/event-item/event-item-compact.component';
import { FooterComponent } from './footer/footer.component';
import { ToastsComponent } from './shared/components/toasts/toasts.component';
import { AlertsComponent } from './shared/components/alerts/alerts.component';
import { SearchEventAdvancedComponent } from './event-filters/search-event-advanced/search-event-advanced.component';
import { SearchEventCompactComponent } from './event-filters/search-event-compact/search-event-compact.component';
import { LoadingSpinnerComponent } from './shared/components/loading-spinner/loading-spinner.component';
import { AccountComponent } from './account/account.component';
import { EventListComponent } from './events/event-list/event-list.component';
import { EventListCompactComponent } from './events/event-list/event-list-compact.component';
import { EventCarouselComponent } from './events/event-carousel/event-carousel.component';
import { EventDetailComponent } from './events/event-detail/event-detail.component';
import { LocationInputComponent } from './location/location-input/location-input.component';
import { LocationMapComponent } from './location/location-map/location-map.component';
import { ShowroomComponent } from './showroom/showroom.component';
import { ExploreComponent } from './explore/explore.component';
import { SafeHtmlPipe } from './shared/pipes/safe-html.pipe';
import { FormsModule } from '@angular/forms';
import { BookingFlowComponent } from './booking-flow/booking-flow.component';
import { BookingSelectComponent } from './booking-flow/booking-select/booking-select.component';
import { BookingPaymentComponent } from './booking-flow/booking-payment/booking-payment.component';
import { BookingConfirmationComponent } from './booking-flow/booking-confirmation/booking-confirmation.component';
import { BookingLoginComponent } from './booking-flow/booking-login/booking-login.component';
import { BookingSummaryMobileComponent } from './booking-flow/booking-summary-mobile/booking-summary-mobile.component';
import { BookingUnavailableComponent } from './booking-flow/booking-unavailable/booking-unavailable.component';
import { EventShareComponent } from './events/event-share/event-share.component';
import { CookieBannerComponent } from './cookie/banner/banner.component';
import { CookieSettingsComponent } from './cookie/settings/settings.component';
import { GtagModule } from './gtag/gtag.module';

import { environment } from '../environments/environment';
import { BookingSelectScheduleComponent } from './booking-flow/booking-select/booking-select-schedule/booking-select-schedule.component';
import { BookingSelectTableComponent } from './booking-flow/booking-select/booking-select-table/booking-select-table.component';
import { TableCardComponent } from './tables/table-card/table-card.component';
import { SelectTableModalComponent } from './tables/select-table-modal/select-table-modal.component';
import { TableCardCompactComponent } from './tables/table-card-compact/table-card-compact.component';
import { EventAlertBookingTimeListComponent } from './events/event-alert-booking-time/event-alert-booking-time-list.component';
import { EventAlertBookingTimeDetailsComponent } from './events/event-alert-booking-time/event-alert-booking-time-details.component';
import { PlaceListComponent } from './management/places/place-list/place-list.component';
import { PlaceDetailComponent } from './management/places/place-detail/place-detail.component';
import { DocumentComponent } from './document/document.component';
import { ProfileComponent } from './account/profile/profile.component';
import { BookingsComponent } from './account/bookings/bookings.component';
import { BookingComponent } from './booking/booking.component';
import { BookingMenuComponent } from './booking-flow/booking-menu/booking-menu.component';
import { ParticipantSelectionComponent } from './booking-flow/booking-menu/participant-selection/participant-selection.component';
import { ParticipantsSelectionComponent } from './booking-flow/booking-menu/participants-selection/participants-selection.component';
import { ParticipantsSelectionCollapsibleSummaryComponent } from './booking-flow/booking-menu/participants-selection-collapsible-summary/participants-selection-collapsible-summary.component';
import { EventListComponent as ManagementEventListComponent } from './management/events/event-list/event-list.component';
import { EventItemComponent as ManagementEventItemComponent } from './management/events/event-list/event-item/event-item.component';
import { EventDetailComponent as ManagementEventDetailComponent } from './management/events/event-detail/event-detail.component';
import { BookingListComponent as ManagementBookingListComponent } from './management/bookings/booking-list/booking-list.component';
import { CheckinConfirmationComponent } from './management/checkin-flow/checkin-confirmation/checkin-confirmation.component';
import { CheckinStartComponent } from './management/checkin-flow/checkin-start/checkin-start.component';
import { CheckinSelectEventComponent } from './management/checkin-flow/checkin-select-event/checkin-select-event.component';
import { CheckinModalComponent } from './management/checkin-flow/checkin-modal/checkin-modal.component';
import { CheckinFailureComponent } from './management/checkin-flow/checkin-failure/checkin-failure.component';
import { OrdersListComponent } from './management/bookings/orders-list/orders-list.component';
import { BookingListTableComponent } from './management/bookings/booking-list/booking-list-table.component';
import { TableHeaderComponent } from './management/bookings/table-header/table-header.component';
import { GoogleMapsModule } from "@angular/google-maps";
import { BookingNotesModalComponent } from './management/bookings/booking-list/booking-notes-modal.component';


@NgModule({
  declarations: [
    AppComponent,
    AuthComponent,
    HomeComponent,
    LocationComponent,
    NavbarComponent,
    EventItemComponent,
    EventItemCompactComponent,
    FooterComponent,
    ToastsComponent,
    AlertsComponent,
    SearchEventCompactComponent,
    LoadingSpinnerComponent,
    AccountComponent,
    EventListComponent,
    EventListCompactComponent,
    EventCarouselComponent,
    EventDetailComponent,
    LocationInputComponent,
    LocationMapComponent,
    ShowroomComponent,
    ExploreComponent,
    SafeHtmlPipe,
    SearchEventAdvancedComponent,
    BookingFlowComponent,
    BookingSelectComponent,
    BookingPaymentComponent,
    BookingConfirmationComponent,
    BookingLoginComponent,
    BookingSummaryMobileComponent,
    BookingUnavailableComponent,
    EventShareComponent,
    CookieBannerComponent,
    CookieSettingsComponent,
    BookingSelectScheduleComponent,
    BookingSelectTableComponent,
    TableCardComponent,
    SelectTableModalComponent,
    TableCardCompactComponent,
    EventAlertBookingTimeListComponent,
    EventAlertBookingTimeDetailsComponent,
    PlaceListComponent,
    PlaceDetailComponent,
    DocumentComponent,
    ProfileComponent,
    BookingsComponent,
    BookingComponent,
    ManagementEventListComponent,
    ManagementEventItemComponent,
    ManagementEventDetailComponent,
    ManagementBookingListComponent,
    CheckinConfirmationComponent,
    CheckinStartComponent,
    CheckinSelectEventComponent,
    CheckinModalComponent,
    CheckinFailureComponent,
    OrdersListComponent,
    BookingListTableComponent,
    TableHeaderComponent,
    BookingMenuComponent,
    ParticipantSelectionComponent,
    ParticipantsSelectionComponent,
    ParticipantsSelectionCollapsibleSummaryComponent,
    BookingNotesModalComponent,
  ],
  imports: [
    HttpClientModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    SocialLoginModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    Ng2TelInputModule,
    // Google Analytics
    GtagModule.init({
      targetId: environment.GOOGLE_ANALYTICS_KEY,
    }),
    GoogleMapsModule,
  ],
  providers: [
    provideHttpClient(withJsonpSupport(), withFetch()),
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: true,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              environment.GOOGLE_SSO_ACCESS_KEY,
              {
                oneTapEnabled: false,
                scopes: [
                  'https://www.googleapis.com/auth/userinfo.email',
                  'https://www.googleapis.com/auth/userinfo.profile',
                  'openid'
                ]
              }
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider(environment.FACEBOOK_SSO_ACCESS_KEY),
          },
        ],
        onError: (err) => {
          console.error('Social auth error: ' + err);
        }
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: APIRequestInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true,
    },
        {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    DatePipe,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
