<form [formGroup]="selectionForm">
    <div class="form-group" *ngFor="let in of counter(nbSelectedParticipants); let participantIndex = index">
        <div class="card">
            <div class="card-header bg-secondary mt-0 lead">
                Participant {{participantIndex+1}}
            </div>
            <div class="card-body">
                <app-participant-selection 
                [enabled]="enabled"
                [form]="selectionForm.get(getParticipantKey(participantIndex))"
                [participantIndex]="participantIndex"
                [participantChoice]="participantChoices.getParticipantChoice(participantIndex)"
                ></app-participant-selection>
            </div>
        </div>
    </div>

    <!-- TODO: full choice validation errors (e.g., min 2 person for certain types of dish - se risotto, raclette) -->
    <!-- NOTE: already handled at input field level -->
</form>