import { Component, Inject, LOCALE_ID, OnInit } from '@angular/core';
import { User } from 'src/app/auth/user.model';
import { AuthService } from 'src/app/auth/auth.service';
import { PREFERRED_COUNTRIES } from '../../shared/utils/ng2-tel-input';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {ToastService} from '../../shared/components/toasts/toast.service';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {
  minBirthDateYear: number;
  maxBirthDateYear: number;

  accountForm: UntypedFormGroup;
  user: User;

  ngTelInput: any;  // Ng2TelInput
  i18nPhoneNumber: string;
  countryCode: string;

  PREFERRED_COUNTRIES = PREFERRED_COUNTRIES;

  constructor(
    public authService: AuthService,
    private http: HttpClient,
    private toastService: ToastService,
    fb: UntypedFormBuilder,
    @Inject(LOCALE_ID) public locale: string
  ) {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    this.minBirthDateYear = currentYear - 100;
    this.maxBirthDateYear = currentYear - 15;

    const birthDateValidator = (control: UntypedFormControl) => {
      if (!control.value) {
        return null;
      }

      if (
        control.value.year > this.minBirthDateYear &&
        control.value.year < this.maxBirthDateYear
      ) {
        return null;
      }
      return { birthDate: false };
    };

    this.accountForm = fb.group({
      first_name: new UntypedFormControl('', Validators.required),
      last_name: new UntypedFormControl('', Validators.required),
      birth_date: new UntypedFormControl(undefined, birthDateValidator),
      email: new UntypedFormControl(),
      phone_number: new UntypedFormControl(''),
      telegram_username: new UntypedFormControl(undefined),
    });
  }

  ngOnInit() {
    if (this.locale === 'fr' || this.locale === 'it') {
      this.countryCode = this.locale;
    } else {
      this.countryCode = 'us';
    }

    this.user = this.authService.user.value;
    this.accountForm.patchValue(this.user);

    this.accountForm.controls.phone_number.valueChanges.subscribe(phoneNumber => {
      // As explained in the intl-tel-input documentation, the autoFormat option
      // was removed so we have to run validation on each keypress
      this.formatAndValidatePhoneNumber(phoneNumber);
    });

    if (this.user.phone_number) {
      this.setPhoneNumber(this.user.phone_number);
    }
  }

  /* ************ *
   * Phone number *
   * ************ */

  setPhoneNumber(phone: string): void {
    this.accountForm.controls.phone_number.setValue(phone);
  }

  // To display phone numbers, we are relying on the ng2-tel-input Angular
  // library, which itself leverages the intl-tel-input library
  // Information can be found here:
  // https://github.com/gauravsoni119/ng2-tel-input/blob/master/src/ng2-tel-input.ts

  getNg2TelInput(telInput: any) {
    // Bind to the intlTelInputObject @Output of ng2-tel-input
    // to get a reference to the underlying object on init
    this.ngTelInput = telInput;
  }

  formatAndValidatePhoneNumber(phoneNumber: string) {
    // Format the phone number and adapts the displayed errors
    // Note: calling setNumber is the recommended way to format the
    // phone number in the format of the selected country
    // Check: https://github.com/jackocnr/intl-tel-input/issues/462#issuecomment-240455105
    if (this.ngTelInput) {
      // ngTelInput is undefined until getNg2TelInput gets called.
      // formatAndValidatePhoneNumber is called before in the accountForm.controls.phone_number.valueChanges subscription
      this.ngTelInput.setNumber(phoneNumber);
      this.setNgTelInputError(this.ngTelInput.isValidNumber());
    }
  }

  onCountryChange() {
    // Bind on the change of country
    // We need to rerun the validation since the expected format has changed
    this.formatAndValidatePhoneNumber(this.accountForm.controls.phone_number.value);
  }

  geti18nPhoneNumber(number: string) {
    // Bind to ngTelOutput to get the phone number in international
    // format (with prefix) on blur
    this.i18nPhoneNumber = number;
  }

  setNgTelInputError(isValid: boolean) {
    // Utility function to set or remove error related to ng2TelInput
    // validation without interferring with errors from other validators
    let errors = this.accountForm.controls.phone_number.errors;
    if (errors == null) {
      if (!isValid) {
        errors = {incorrect: true};
      }
    } else {
      if (isValid) {
        delete errors.incorrect;
        if (Object.keys(errors).length === 0) {
          errors = null;
        }
      }
    }
    this.accountForm.controls.phone_number.setErrors(errors);
  }

  onSave() {
    const user: User = this.accountForm.value;

    // NOTE: value attached to form is NOT in i18n format
    user.phone_number = this.i18nPhoneNumber;

    if (this.accountForm.dirty && this.accountForm.valid) {
      this.authService
        .updateUserProfile(user)
        .subscribe((updatedUser: User) => {
          this.accountForm.patchValue(updatedUser);
          this.accountForm.markAsPristine();
        }, (err: HttpErrorResponse) => {
          if (err.status === 400) {
            console.log(err);
            const validationErrors = err.error;
            Object.keys(validationErrors).forEach(fieldName => {
              const formControl = this.accountForm.get(fieldName);
              if (formControl) {
                formControl.setErrors({
                  serverError: validationErrors[fieldName][0]
                });
              }
            });
          } else {
            this.toastService.error('Could not update your profile.');
          }
        });
    }
  }

}
