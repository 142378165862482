<div>
  <div class="heading text-uppercase font-weight-bold mt-4 mb-1" i18n="booking confirmation title">
    Confirmation
  </div>
  <div class="custom-tab-content mx-3 mt-3 mb-5">
    <div class="tab-content p-0 rounded-lg">
      <div class="bg-success text-white p-4 mb-4 rounded-top">
        <div class="heading-title">
          <span class="alert-inner--icon"
            ><i class="ni ni-like-2 m-2"></i
          ></span>
          <span class="alert-inner--text">
            <strong i18n="booking confirmation subtitle">All good!</strong>
          </span>
        </div>
        <div class="lead font-weight-normal" i18n="booking confirmation message">
          Your registration has been confirmed.<br />Thank you {{ user.first_name }}!
        </div>
      </div>
      <div class="container px-4">
        <div class="row">
          <div class="col-12 lead pb-2">
            <div class="">{{ event.place.name }}</div>
            <small class="text-uppercase text-muted">
              <i class="ni ni-pin-3"></i>
              {{ event.place.address }}
            </small>
          </div>
          <div class="col-12 container justify-content-start">
            <div class="row">
              <div class="col-auto lead pr-1">
                <span i18n>Reservation for </span>
                <span
                  class="border border-default text-default p-2"
                  *ngIf="state.nbSelectedParticipants > 1"
                  i18n
                  >{{ state.nbSelectedParticipants }} persons</span
                >
                <span
                  class="border border-default text-default p-2"
                  *ngIf="state.nbSelectedParticipants === 1"
                  i18n
                  >{{ state.nbSelectedParticipants }} person</span
                >
              </div>
              <div class="lead pl-3 pl-md-1">
                <span i18n="date article prefix"> the </span>
                <span
                  class="border border-default text-default p-2"
                  placement="top"
                  [ngbTooltip]="state.selectedSchedule.start_datetime | date: 'longDate'"
                >{{ state.selectedSchedule.start_datetime | date: 'shortDate' }}</span>
                <span i18n="time article prefix"> at </span>
                <span class="border border-default text-default p-2">{{ state.selectedSchedule.start_datetime | date: 'shortTime' }}</span>
              </div>
            </div>
        </div>
        
        <div *ngIf="event.menu_preference_required" class="col-12 px-0 px-md-1 mt-4">
          <div class="border border-default my-2 mx-0 mx-md-2">
            <app-participants-selection-collapsible-summary [participantChoices]="state.participantChoices"></app-participants-selection-collapsible-summary>
          </div>
        </div>

        <div class="col-12">
          <div class="display-4 my-5 text-center">
            {{ state.nbSelectedParticipants }} x {{ state.discount.eventPrice }} EUR =
            {{ state.discount.bookingPrice }} EUR
            <del
              class="text-muted font-weight-light"
              *ngIf="state.discount.totalDiscount > 0"
              >({{
                state.discount.bookingOriginalPrice
              }}
              EUR)</del
            >
          </div>
        </div>
        <div class="col-12 pb-3">
          <!-- display only join code if the table was created with this booking -->
          <app-table-card-compact [showTableAs]="state.isNewTable ? 'created' : 'joined'" [table]="state.selectedTable" [nbSelectedParticipants]="state.nbSelectedParticipants"></app-table-card-compact>
        </div>
        <div class="col-12" *ngIf="state.notes">
          <div class="py-3">
            <h5 i18n="Notes title" class="heading text-uppercase font-weight-bold" i18n="Notes and special requests in reservation">Notes</h5>
            <small class="text-muted">
              {{ state.notes }}
            </small>
          </div>
        </div>
      </div>
    </div>
    <hr class="w-75" />
    <div class="text-center text-default">
      <p class="lead mb-5" *ngIf="event.current_price != event.best_price" i18n>
        Want to save up to
        <span class="text-black d-inline font-weight-bold">{{ (event.current_price - event.best_price)  }} EUR</span>
        <span *ngIf="event.original_price != event.current_price"> more</span>?
      </p>
      <p class="display-4 w-100 mx-auto position-absolute">
        <button
          style="bottom: 30px"
          class="m-auto w-50 text-white font-weight-bold btn-primary rounded-pill shadow p-2 position-relative"
          i18n="Share deal call to action"
          (click)="shareEvent()"
        >
          Share it!
        </button>
      </p>
    </div>
  </div>
</div>
