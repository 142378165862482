import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: any[] = [];
  delay: 1000;

  show(textOrTpl: string, options: any = {}) {
    this.toasts.push({ textOrTpl, ...options });
  }

  remove(toast) {
    this.toasts = this.toasts.filter((t) => t !== toast);
  }

  showUtil(message, level) {
    this.show(message, { classname: 'bg-' + level, delay: this.delay });
  }

  success(message) {
    this.showUtil(message, 'success');
  }

  info(message) {
    this.showUtil(message, 'info');
  }

  warning(message) {
    this.showUtil(message, 'warning');
  }

  error(message) {
    this.showUtil(message, 'danger');
  }
}
