<div class="table-responsive mb-5" [class.vw-100]="fullWidth">
    <!-- margin-top needed to visualize properly the dropdown (table-responsive hides the overflowing elements) -->
    <table class="table table-striped text-center">
        <thead class="bg-primary text-white">
            <th scope="col" class="fit" i18n="bookings table - check-in code">Code</th>
            <th scope="col" class="fit" i18n="bookings table - check-in status">Status</th>
            <th scope="col" class="fit" i18n="bookings table - name">Name</th>
            <th scope="col" class="fit" i18n="bookings table - participants number"># Participants</th>
            <th scope="col" class="fit" i18n="bookings table - actions">Actions</th>
        </thead>
        <tbody>
            <tr *ngFor="let booking of bookings" scope="row" class="align-middle">
                <th class="fit align-middle">{{ booking.code }}</th>
                <td class="fit align-middle">
                    <h5>
                        <span 
                          class="badge w-100"
                          [class.badge-success]="bookingId2BookingWithDisplayOptions[booking.id].status === BookingListTableComponent.STATUS_CHECKED_IN"
                          [class.badge-info]="bookingId2BookingWithDisplayOptions[booking.id].status === BookingListTableComponent.STATUS_CONFIRMED"
                          [class.badge-warning]="bookingId2BookingWithDisplayOptions[booking.id].status === BookingListTableComponent.STATUS_LATE"
                          [class.badge-danger]="bookingId2BookingWithDisplayOptions[booking.id].status === BookingListTableComponent.STATUS_CANCELLED"
                        >
                            {{ booking.status_message }}
                        </span>
                    </h5>
                </td>
                <td class="fit align-middle">{{ booking.first_name }} {{ booking.last_name }}</td>
                <td class="fit align-middle">{{ booking.people_nb }}</td>
                <td class="fit align-middle">
                    <!-- NOTE: span necessary to visualize correctly the tooltip -->
                    <span
                      ngbTooltip="View booking notes"
                      i18n-ngbTooltip="View booking notes tooltip"
                      placement="left"
                      [class.invisible]="!booking.notes && !booking.participant_choices"
                    >
                        <button 
                          class="btn btn-info" 
                          (click)="showBookingNotesModal(booking)"
                        >
                            <i class="fa fa-sticky-note" aria-hidden="true"></i>
                        </button>
                    </span>
                    <div class="btn-group" ngbDropdown placement="left-bottom" [class.invisible]="!showActions || !bookingId2BookingWithDisplayOptions[booking.id].showActionsButton">
                        <button ngbDropdownToggle class="btn btn-secondary no-dropdown-icon" type="button" data-toggle="dropdown" aria-expanded="false">
                            <i class="fa fa-ellipsis-v" aria-hidden="true"></i>
                        </button>
                        <div class="dropdown-menu shadow rounded p-0" ngbDropdownMenu>
                            <span class="dropdown-header text-dark text-center text-uppercase" i18n="Actions in booking list view (management)">Actions</span>
                            <div class="dropdown-divider"></div>
                            <span *ngIf="bookingId2BookingWithDisplayOptions[booking.id].actions.contact.enabled" class="dropdown-item p-1">
                                <button 
                                  class="btn btn-info w-100"
                                  href="#"
                                  [disabled]="bookingId2BookingWithDisplayOptions[booking.id].actions.contact.loading"
                                >
                                <span
                                  *ngIf="bookingId2BookingWithDisplayOptions[booking.id].actions.contact.loading"
                                  class="spinner-border spinner-border-sm mr-2" 
                                  role="status" 
                                  aria-hidden="true"
                                ></span>
                                <ng-container i18n="View booking contacts in booking list view (management)">View contact details</ng-container>
                            </button>
                            </span>
                            <span *ngIf="bookingId2BookingWithDisplayOptions[booking.id].actions.accept.enabled" class="dropdown-item p-1">
                                <button 
                                  class="btn btn-success w-100"
                                  (click)="checkIn(booking)"
                                  [disabled]="bookingId2BookingWithDisplayOptions[booking.id].actions.accept.loading"
                                >
                                <span
                                  *ngIf="bookingId2BookingWithDisplayOptions[booking.id].actions.accept.loading"
                                  class="spinner-border spinner-border-sm mr-2" 
                                  role="status" 
                                  aria-hidden="true"
                                ></span>
                                <ng-container i18n="Accept booking in booking list view (management)">Validate check-in</ng-container>
                            </button>
                            </span>
                            <span *ngIf="bookingId2BookingWithDisplayOptions[booking.id].actions.deny.enabled" class="dropdown-item p-1">
                                <button 
                                  class="btn btn-danger w-100"
                                  href="#"
                                  [disabled]="bookingId2BookingWithDisplayOptions[booking.id].actions.deny.loading"
                                >
                                <span
                                  *ngIf="bookingId2BookingWithDisplayOptions[booking.id].actions.deny.loading"
                                  class="spinner-border spinner-border-sm mr-2" 
                                  role="status" 
                                  aria-hidden="true"
                                ></span>
                                <ng-container i18n="Deny booking in booking list view (management)">Deny check-in</ng-container>
                            </button>
                            </span>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>