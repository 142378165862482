import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {LocationService} from '../location.service';
import {Location} from '../location.model';
import {Observable} from "rxjs";
import {GoogleMapsApiLoaderService} from "../maps-api-loader.service";

// Module documentation: https://github.com/angular/components/tree/main/src/google-maps#readme
// GoogleMap component documentation: https://github.com/angular/components/blob/main/src/google-maps/google-map/README.md
// Events documentation: https://developers.google.com/maps/documentation/javascript/reference/map#Map-Events
// Marker component documentation: https://github.com/angular/components/blob/main/src/google-maps/map-marker/README.md

@Component({
  selector: 'app-location-map',
  templateUrl: './location-map.component.html',
  styleUrls: ['./location-map.component.scss'],
})
export class LocationMapComponent implements OnInit {
  @Input() latitude: number;
  @Input() longitude: number;

  location: Location;

  apiLoaded: Observable<boolean>;
  center: google.maps.LatLngLiteral;
  zoom = 14;
  display: google.maps.LatLngLiteral;

  constructor(
      private locationService: LocationService,
      private mapsAPILoader: GoogleMapsApiLoaderService) {
    this.apiLoaded = this.mapsAPILoader.load();
  }

  ngOnInit(): void {
    this.center = {lat: this.latitude, lng: this.longitude};
    this.locationService.locationSubject.subscribe((location) => {
      if (location !== null) {
        this.center = {lat: location.latitude, lng: location.longitude};
      }
    });
  }
}
