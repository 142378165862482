import {Inject, Injectable} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

import { SeoImage, resolveSeoLinkUrls } from './seo.model';
import { settings } from '../../settings';
import { Page } from './page.model';
import {Router} from "@angular/router";
import {DOCUMENT} from "@angular/common";


/**
 * Service to handle page metadata for SEO purposes.
 * It covers information used by Search Engine and Social Media bots.
 *
 * It assumes that the Open Graph tags have the prefix 'og:' defined.
 * See <html prefix="og: https://ogp.me/ns#">.
 *
 * Static attributes are out of scope and are handled via the app.component.ts (initialization based on configuration) or
 * via the index.html (fixed values for all environments).
 *
 * Examples of out-of-scope attributes are:
 *  1. fb:app_id (Facebook App ID)
 *  2. Open Graph prefix definition, see <html prefix="og: https://ogp.me/ns#">
 *  3. og:site_name (high level name of the website)
 *  4. lang attribute
 */
@Injectable({
  providedIn: 'root'
})
export class SeoService {

  // NOTE: not managing keywords since they seem obsolete / irrelevant for SEO
  //       see https://ahrefs.com/blog/meta-keywords/

  constructor(
    private meta: Meta,
    private title: Title,
    private router: Router,
    @Inject(DOCUMENT) private document: Document
  ) { }

  // TODO: apple-mobile-web-app-capable ? see https://stackoverflow.com/questions/39612354/when-not-to-use-mobile-web-app-capable

  /**
   * Shorthand for updating all page metadata at once (see updateTitle, updateDescription, updateImage, etc.)
   * @param page page object containing the information to render
   */
  renderSeoMetadata(page: Page) {
    this.updateType('website');
    this.updateTitle(page.getSeoTitle());
    this.updateDescription(page.getSeoDescription());
    this.updateImage(page.getSeoImage());
    this.updateUrl(page.getSeoUrl());
  }

  /**
   * @see https://ogp.me/#types
   * @param type object type represented by the page
   */
  updateType(type: string) {
    if (type) {
      this.meta.updateTag({ name: 'og:type', content: type });
    }
  }

  /**
   * Updates the title of the page.
   * The title should be a concise description of the webpage in natural language.
   * Each page should have a unique title to avoid issues with SEO.
   * Ideal size is 55 to 70 characters.
   * @param title string shown as page title
   */
  updateTitle(title: string) {
    if (title) {
      // metadata for search engines
      this.title.setTitle(title);

      // metadata for social media
      this.meta.updateTag({ name: 'og:title', content: title });
    }
  }

  /**
   * Updates the description of the page.
   * The description should be a in natural language and contain as many relevant
   * keywords as possible to be effective in SEO.
   * @param description string shown as page content description
   */
  updateDescription(description: string) {
    if (description) {
      // metadata for search engines
      this.meta.updateTag({ name: 'description', content: description });

      // metadata for social media
      this.meta.updateTag({ property: 'og:description', content: description });
    }
  }

  /**
   * Updates the image associated to this page.
   * The image will be shown in social media platforms.
   * The image itself should not impact the SEO.
   * @param image image shown in social media platform when this page is shared
   */
  updateImage(image: SeoImage) {
    // metadata for social media only

    if (image?.url) {
      this.meta.updateTag({ property: 'og:image', content: image.url });
    }

    if (image.mimeType) {
      this.meta.updateTag({ property: 'og:image:type', content: image.mimeType });
    } else {
      this.meta.removeTag('property="og:image:type"');
    }
  }

  /**
   * Updates the URL associated to this page.
   * @param href URL or relative path to this page. Use null if this value should be the current URL without query params or fragment
   */
  updateUrl(href: string | null) {
    let keepQueryParamsAndFragment = undefined;
    if (!href) {
      href = this.router.url;
      // will fill in URL without params and fragment
      keepQueryParamsAndFragment = false;
    } else {
      // keep params and fragment as-is if href passed externally
      // (for cases where we want to render specific queries - e.g., events in Antibes from a linkback link)
      keepQueryParamsAndFragment = true;
    }

    // remove fragment and query parameters?
    // NO, better to handle this logic outside...
    // NOTE: support for href instead of full URLs simplifies this

    const urls = resolveSeoLinkUrls(href, settings.SUPPORTED_LANGUAGES, keepQueryParamsAndFragment);

    // inject URL values in
    //       <link rel="canonical" href="...">
    this.removeCanonicalUrlLink();
    this.addCanonicalUrlLink(urls.canonical);
    this.meta.updateTag({ property: 'og:url', content: urls.canonical });
    //       <link rel="alternate" hreflang="..." href="...">
    this.removeAlternateUrlLinks();
    urls.alternates.forEach(alt => {
      this.addAlternateUrlLink(alt.url, alt.lang);
    });
    this.addAlternateUrlLink(urls.canonical, "x-default");
  }

  addCanonicalUrlLink(url: string) {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.document.head.appendChild(link);
    link.setAttribute('href', url);
  }

  removeCanonicalUrlLink() {
    const els = this.document.querySelectorAll('link[rel=\'canonical\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }

  addAlternateUrlLink(url: string, lang: string) {
    const link: HTMLLinkElement = this.document.createElement('link');
    link.setAttribute('rel', 'alternate');
    this.document.head.appendChild(link);
    link.setAttribute('hreflang', lang);
    link.setAttribute('href', url);
  }

  removeAlternateUrlLinks() {
    const els = this.document.querySelectorAll('link[rel=\'alternate\']');
    for (let i = 0, l = els.length; i < l; i++) {
      const el = els[i];
      el.remove();
    }
  }

  // // NOTE: language is already managed by Angular? lang is already set in HTLM tag...
  // // document.documentElement.lang = language; seems not necessary here.
  // // Open Graph languange can be statically defined as well
  // // same goes for this.meta.updateTag({ property: "og:locale", content: language });
  // // og:locale:alternate might not be relevant anymore (seems it is not supported anymore)
}
