<!-- Shown only if multiple events -->
<div class="modal-header bg-secondary">
    <h5 class="modal-title" id="checkInTitle" i18n>Booking notes</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-4">
    <h5 i18n>User notes</h5>
    <ng-container *ngIf="booking.notes; else noNotes">
        <pre class="m-3">{{ booking.notes }}</pre>
    </ng-container>
    <ng-template #noNotes>
        <p class="text-center">
            <span class="badge badge-info mb-4 px-3 lead" i18n="no notes in booking">No notes</span>
        </p>
    </ng-template>

    <ng-container *ngIf="booking.participant_choices && booking.participant_choices.participantChoices.length > 0">
        <h5 i18n>Choices</h5>
        <app-participants-selection [enabled]="false" [participantChoices]="booking.participant_choices"></app-participants-selection>
    </ng-container>
</div>
