import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Event, EventSerializer } from './event.model';
import { Observable } from 'rxjs';
import { PaginatedListResponse } from '../shared/interfaces/paginated-list-response';
import { EventsQuery } from './events-query.model';

@Injectable({
  providedIn: 'root',
})
export class EventsService {
  events: Event[] = [];
  count: number;
  next: string;
  previous: string;

  constructor(
    private http: HttpClient,
    private eventSerializer: EventSerializer
  ) {}

  loadEvents(query: EventsQuery): Observable<Event[]> {
    let parameters = new HttpParams();

    if (query.lat && query.lng) {
      parameters = parameters.set('point', query.lng + ',' + query.lat);

      if (query.dist) {
        parameters = parameters.set('dist', query.dist.toString());
      }
    }

    if (query.fromDate) {
      parameters = parameters.set('date_after', query.fromDate);
    }

    if (query.toDate) {
      parameters = parameters.set('date_before', query.toDate);
    }

    // FIXME: in future we should have tags: string[], keeping like this as filter can handle single category for now
    // if (query.tags !== undefined && query.tags.length > 0 && query.tags[0] !== 'any') {
    //   parameters = parameters.set('tags', query.tags.toString());
    // }
    if (query.category) {
      parameters = parameters.set('tags', query.category);
    }

    // TODO: handle hot parameter (when backend supports it)

    if (query.is_takeaway) {
      parameters = parameters.set('is_takeaway', query.is_takeaway);
    }

    return this.http
      .get<PaginatedListResponse<Event>>('/api/v1/events', {
        params: parameters,
      })
      .pipe(
        map((response: PaginatedListResponse<Event>) => {
          this.count = response.count;
          this.next = response.next;
          this.previous = response.previous;
          this.events = [];

          for (const event of response.results) {
            this.events.push(this.eventSerializer.deserialize(event));
          }

          return this.events;
        })
      );
  }

  loadEvent(id: string): Observable<Event> {
    return this.http.get<Event>('/api/v1/events/' + id).pipe(
      map((event: Event) => {
        return this.eventSerializer.deserialize(event);
      })
    );
  }
}
