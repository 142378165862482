<main>
  <div class="position-relative">
    <section class="section section-lg section-shaped">
      <div class="container">
        <div class="card py-2 px-3">
          <app-search-event-advanced
            *ngIf="eventsQuery"
            [ngClass]="{ 'd-none': isCollapsed }"
            [eventsQuery]="eventsQuery"
            (filterChanged)="onFilterChanged($event)"
            class="d-lg-block"
          ></app-search-event-advanced>
          <div class="row d-lg-none">
            <button
              class="btn btn-white btn-sm shadow-none"
              (click)="toggleCollapse()"
            >
              <span *ngIf="isCollapsed" class="mx-2" i18n
                >Show filter
                <span class="btn-inner--icon"
                  ><i class="fa fa-caret-down"></i></span
              ></span>
              <span *ngIf="!isCollapsed" class="mx-2" i18n
                >Hide filter
                <span class="btn-inner--icon"
                  ><i class="fa fa-caret-up"></i></span
              ></span>
            </button>
          </div>
        </div>
        <div style="min-height: 500px">
          <app-event-list-compact
            [events]="events"
            [groupByDate]="true"
            class="d-block"
            *ngIf="events?.length"
          ></app-event-list-compact>
          <div class="m-5" *ngIf="!events?.length">
            <h2 i18n>Could not find any event :(</h2>
            <p i18n>
              try to <button class="btn btn-link p-0" (click)="relaxFilter()">reset constraints</button> OR select a different location.
            </p>
          </div>
          <div class="d-flex justify-content-center m-5" *ngIf="!events">
            <div class="spinner-border" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
