import { Component, OnInit, Input } from '@angular/core';
import { Event } from '../../event.model';
import { EventShareService } from '../../event-share/event-share.service';

@Component({
  selector: 'app-event',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss'],
})
export class EventItemComponent implements OnInit {
  @Input() event: Event;

  constructor(private eventShareService: EventShareService) {}

  ngOnInit(): void {}

  shareEvent() {
    this.eventShareService.showModalView(this.event);
  }
}
