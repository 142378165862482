import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { PaginatedListResponse } from '../shared/interfaces/paginated-list-response';
import { Tag } from './tag.model';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class TagsService {
  constructor(private http: HttpClient) {}

  loadTags(): Observable<Tag[]> {
    return this.http.get<PaginatedListResponse<Tag>>('/api/v1/tags/').pipe(
      map((response: PaginatedListResponse<Tag>) => {
        const tags: Tag[] = [];
        response.results.forEach((t) => {
          tags.push(
            new Tag(
              t.id,
              t.slug,
              t.name,
              t.icon ? '&#x' + t.icon.substring(2) + ';' : undefined
            )
          );
        });
        return tags;
      })
    );
  }
}
