import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../../event.model';

@Component({
  selector: 'app-management-event-item',
  templateUrl: './event-item.component.html',
  styleUrls: ['./event-item.component.scss']
})
export class EventItemComponent implements OnInit {
  @Input() event: Event;

  constructor() { }

  ngOnInit(): void {
  }

}
