import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { finalize } from 'rxjs';
import { BookingNotFoundError, BookingService, CheckInError } from '../../bookings/booking.service';
import { Event } from '../../events/event.model';
import { CheckinFlowService } from '../checkin-flow.service';
import { CheckinModalComponent } from '../checkin-modal/checkin-modal.component';

@Component({
  selector: 'app-checkin-start',
  templateUrl: './checkin-start.component.html',
  styleUrls: ['./checkin-start.component.scss']
})
export class CheckinStartComponent implements OnInit {
  public resolvingCheckIn: boolean = false;

  inputCheckInCode: string = "";
  form!: FormGroup;

  @Input() modal: CheckinModalComponent;
  // Reference event, we validate booking codes of a specific event if this is defined
  @Input() event?: Event;
  @Input() showBackButton: boolean = false;

  constructor(
    public checkinFlowService: CheckinFlowService,
    public bookingService: BookingService,
  ) { }

  ngOnInit(): void {
    this.form = new FormGroup({
      checkInCode: new FormControl(
        this.inputCheckInCode,
        [
          Validators.required,
        ]
      )
    })
  }

  back(): void {
    this.checkinFlowService.previousStep();
  }

  onSubmit(): void {
    this.checkCode();
  }

  checkCode(): void {
    this.resolvingCheckIn = true;
    this.form.disable();

    this.inputCheckInCode = this.checkInCodeControl.value;
    this.bookingService.checkinBooking(this.event.id, this.inputCheckInCode)
    .pipe(finalize(() => this.resolvingCheckIn = false))
    .subscribe({
      next: (booking) => {
        this.checkinFlowService.setBooking(this.inputCheckInCode, booking);
      },
      error: (err) => {
        this.form.enable();

        if(err instanceof BookingNotFoundError) {
          // show error in form
          const c = this.checkInCodeControl;
          c.setErrors(Object.assign(c.errors || {}, { "BookingNotFoundError": true }));
          return;
        }

        if(err instanceof CheckInError) {
          // move to check-in failure screen
          this.checkinFlowService.setFailure(err.reason, err.booking);
        }

        // Show unknown errors in form
        const c = this.checkInCodeControl;
        c.setErrors(Object.assign(c.errors || {}, { "UnknownError": true }));
      },
    });
  }

  dismiss() {
    this.modal.dismiss();
  }

  get checkInCodeControl() { return this.form.get('checkInCode'); }
}
