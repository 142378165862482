import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Event } from '../event.model';
import { ToastService } from '../../shared/components/toasts/toast.service';
import { GtagService } from 'src/app/gtag/gtag.service';

@Component({
  selector: 'app-event-share',
  templateUrl: './event-share.component.html',
  styleUrls: ['./event-share.component.scss']
})
export class EventShareComponent implements OnInit {

  @Input() event: Event;
  @Input() eventUrl: string;

  facebookUrl: string;
  twitterUrl: string;

  constructor(
    public activeModal: NgbActiveModal,
    private toastService: ToastService,
    private gtagService: GtagService,
  ) {}

  ngOnInit(): void {
    const encodedEventUrl = encodeURIComponent(this.eventUrl);
    this.facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodedEventUrl}&ref=fbshare&title=Restaudeal&display=page`;
    this.twitterUrl = `https://twitter.com/intent/tweet?url=${encodedEventUrl}`;
  }

  logShareEvent(method: string) {
    this.gtagService.share({
      method: method,
      content_type: "event",
      item_id: this.event.id,
    });
  }

  /**
   * Copies the event URL to the clipboard.
   */
  copyToClipboard() {
    // NOTE: registering share intention
    this.logShareEvent("clipboard");

    navigator.clipboard
    .writeText(this.eventUrl)
    .then(() => {
      this.toastService.success("Link copied to clipboard!");
    });
  }
}
