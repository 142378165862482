<form [formGroup]="joinTableForm" (ngSubmit)="onSubmit()">
    <div class="modal-header">
        <h5 class="modal-title" id="modal-title-default" i18n="Join table title">Joining a table?</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"
            (click)="activeModal.dismiss('Cross click')">
            <span aria-hidden="true">×</span>
        </button>
    </div>
    <div class="modal-body">
        <h6 i18n="Join table title">
            Are you joining someone?
        </h6>
        <p i18n="Join table introduction">
            Insert their table code in the input below to join another reservation.
        </p>
        <div class="form-group">
            <label for="tableCode" class="d-block heading text-uppercase font-weight-bold mt-4 mb-1"
                i18n="Join table input label">Table code</label>
            <input type="text" name="tableCode" class="form-control my-1" id="choices-tableCode"
                aria-describedby="choices-table-desc" placeholder="Insert a table code here"
                i18n-placeholder="Insert a table code here placeholder"
                [class.is-invalid]="tableCode.invalid && (tableCode.dirty || tableCode.touched)"
                formControlName="tableCode" required />
            <div *ngIf="resolvingTable">

            </div>
            <div class="invalid-feedback" *ngIf="tableCode.invalid && (tableCode.dirty || tableCode.touched)">
                <span class="alert-inner--icon mr-2"><i class="fa fa-exclamation-circle"></i></span>
                <ng-container *ngIf="tableCode.hasError('required')" i18n>Please specify a table code.
                </ng-container>
                <ng-container *ngIf="tableCode.hasError('tableNotFound')" i18n>
                    We could not find a table with the code
                    <pre class="d-inline">{{ inputTableJoiningCode }}</pre>. Maybe it's a typo?
                </ng-container>
                <ng-container *ngIf="tableCode.hasError('wrongEvent')" i18n>
                    The table
                    <pre class="d-inline">{{ inputTableJoiningCode }}</pre>
                    refers to
                    <a [routerLink]="" (click)="goToTableEvent(selectedTable)" class="text-dark"><u>this event</u></a>.
                </ng-container>
                <ng-container *ngIf="tableCode.hasError('couldNotResolveTable')" i18n>
                    Could not load table info, please <a [routerLink]="" (click)="onSubmit()" class="text-dark">retry</a>.
                </ng-container>
            </div>
        </div>
        <div class="mb-3">
            <div class="text-right">
                <button class="btn btn-link text-info btn-sm" type="button"
                    (click)="isTableCodeHelpCollapsed.toggle()"
                    [attr.aria-expanded]="!isTableCodeHelpCollapsed" aria-controls="tableCodeHelp"
                    aria-label="Toggle table code help">
                    <i class="fa fa-question-circle mr-2" aria-hidden="true"></i>
                    <ng-container i18n>Don't know the code?</ng-container>
                </button>
            </div>
            <div class="bg-info text-white p-3" id="tableCodeHelp" #isTableCodeHelpCollapsed="ngbCollapse"
                [ngbCollapse]="isTableCodeHelpCollapsed">
                <span i18n="Join table where to find code details">
                    Ask the person you want to join to check one of:
                </span>
                <ul class="mb-0">
                    <li i18n="Join table where to find code email">Email Confirmation</li>
                    <li i18n="Join table where to find code profile">Reservation in their profile</li>
                </ul>
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <button type="button" class="btn btn-link ml-auto" data-dismiss="modal"
            (click)="activeModal.close('Close click')" i18n>Close</button>
        <button type="submit" class="btn btn-primary" [disabled]="joinTableForm.invalid || resolvingTable">
            <span *ngIf="resolvingTable" class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <ng-container i18n>
                Submit
            </ng-container>
        </button>
    </div>
</form>