<ng-container *ngIf="events !== undefined; else carouselEventsLoading">
    <ng-container [ngSwitch]="events.length">
      <div *ngSwitchCase="0"
        class="wrapper mx-4 my-5 px-4 py-5 card bg-white shadow"
        style="opacity: 0.8;">
        <p class="display-4 text-dark pb-3" i18n="No active events">
          Our events are coming back soon.
        </p>
        <p class="text-dark" i18n="No active events, register to newsletter">
          Until then, you can <a class="btn btn-primary btn-sm" href="https://share.hsforms.com/1KiMPXoR5T9aQKl9GakXmdQ4kvq1">join our newsletter</a> and stay updated on our best offers.
        </p>
      </div>
      <div *ngSwitchCase="1"
        class="wrapper px-4 pb-4">
        <app-event-compact
          class="d-flex align-self-stretch cursor-pointer my-4"
          [event]="events[0]"
        ></app-event-compact>
      </div>
      <ngb-carousel *ngSwitchDefault
        id="jumbotron-home-carousel"
        class="carousel-fade"
        #carousel
        [interval]="carouselInterval"
        [pauseOnHover]="true"
        [animation]="false"
        (slide)="onSlide($event)"
      >
        <ng-template ngbSlide *ngFor="let event of events; index as i">
          <div class="wrapper px-4 pb-4">
            <!-- style="min-height: 18rem;" Necessary? -->
            <app-event-compact
              class="d-flex align-self-stretch cursor-pointer my-4"
              [event]="event"
            ></app-event-compact>
          </div>
        </ng-template>
      </ngb-carousel>
    </ng-container>
  </ng-container>
  <ng-template #carouselEventsLoading>
    <div class="d-flex justify-content-center m-5">
      <div class="spinner-border spinner-large m-5" role="status">
        <span class="sr-only">Loading...</span>
      </div>
    </div>
  </ng-template>
