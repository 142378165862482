import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {Schedule} from '../events/schedule.model';
import {Payment, PaymentSerializer} from './payment.model';
import {Table} from '../tables/table.model';
import {Booking, BookingSerializer, ParticipantChoices} from "../booking/booking.model";
import {PaginatedListResponse} from "../shared/interfaces/paginated-list-response";

interface BookingIntentResponse {
  client_secret: string;
}

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  constructor(
    private http: HttpClient,
    private paymentSerializer: PaymentSerializer,
    private bookingSerializer: BookingSerializer) {}

  createPayment(
    schedule: Schedule,
    peopleNb: number,
    notes: string,
    participantChoices: ParticipantChoices,
    table: Table | null = null,
  ): Observable<Payment> {
    const participants = [];
    for (let participantIndex = 0; participantIndex < participantChoices.participantChoices.length; participantIndex++) {
      const participantChoice = participantChoices.getParticipantChoice(participantIndex);
      const participant = {"order": participantIndex+1, "choice_ids": []};
      for (const choice of participantChoice.choices) {
        if (choice.id !== null) {
          participant.choice_ids.push(choice.id);
        }
      }
      participants.push(participant);
    }

    return this.http.post<Payment>('/api/v1/payments', {
      schedule_id: schedule.id,
      people_nb: peopleNb,
      notes: notes,
      table_id: table?.id,
      participants: participants,
    }).pipe(
      map((payment: Payment) => {
        return this.paymentSerializer.deserialize(payment);
      })
    );
  }

  patchPayment(paymentId: string, payload) {
    return this.http.patch<Payment>('/api/v1/payments/' + paymentId, payload)
      .pipe(
        map((updatedPayment: Payment) => {
          return this.paymentSerializer.deserialize(updatedPayment);
        })
      );
  }

  listBookings(): Observable<Booking[]> {
    return this.http
      .get<PaginatedListResponse<Booking>>('/api/v1/bookings')
      .pipe(
        map((response: PaginatedListResponse<Booking>) => {
          const bookings = [];

          for (const booking of response.results) {
            bookings.push(this.bookingSerializer.deserialize(booking));
          }

          return bookings;
        })
      );
  }
}
