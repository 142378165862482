import {Serializer} from "../shared/serializer";
import {Injectable} from "@angular/core";

export interface ApiResponseChoice {
    id: string
    order: number
    name: string
    description?: string
    min_people_nb: number,
    extra_cost: number
}

export class Choice {
  // NOTE: internal value, does not exist on Backend
  static readonly NO_PREFERENCE = new Choice(
    null, 0, $localize `No preference`
  );

  constructor(
    public id: string,
    public order: number,
    public name: string,
    public description: string | null = null,
    public minPeopleNb: number = 1,
    public extraCost: number = 0
  ) {

  }
}

@Injectable({
  providedIn: 'root',
})
export class ChoiceSerializer extends Serializer<ApiResponseChoice, Choice> {
  deserialize(apiChoice: ApiResponseChoice): Choice {
    const minPeopleNb = !apiChoice.min_people_nb? 1 : apiChoice.min_people_nb;

    return new Choice(
      apiChoice.id,
      apiChoice.order,
      apiChoice.name,
      apiChoice.description,
      minPeopleNb,
      apiChoice.extra_cost,
    );
  }

}
