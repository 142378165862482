import { Component, OnInit } from '@angular/core';
import {PlaceService} from "../place.service";
import {Place} from "../place.model";
import {Router} from "@angular/router";
import { SeoService } from 'src/app/shared/components/seo/seo.service';
import { Page } from 'src/app/shared/components/seo/page.model';
import { SeoImage } from 'src/app/shared/components/seo/seo.model';
import { settings } from 'src/app/shared/settings';

@Component({
  selector: 'app-place-list',
  templateUrl: './place-list.component.html',
  styleUrls: ['./place-list.component.scss']
})
export class PlaceListComponent implements OnInit, Page {
  places: Place[];

  constructor(
    private router: Router,
    private placeService: PlaceService,
    private seoService: SeoService,
  ) {}
  
  /////////////////////////////////////////////////////////////////////////////
  // SEO METADATA
  /////////////////////////////////////////////////////////////////////////////
  getSeoTitle(): string {
    return $localize `:management place selection page SEO title:Restaudeal | Select place to manage`;
  }

  getSeoDescription(): string {
    return $localize `:management place selection page SEO description:Restaudeal | Selection page for restaurant managers`;
  }

  getSeoImage(): SeoImage {
    return settings.DEFAULT_SEO_IMAGE;
  }

  getSeoUrl(): string | null {
    return null;
  }

  renderSeoMetadata(): void {
    this.seoService.renderSeoMetadata(this);
  }
  /////////////////////////////////////////////////////////////////////////////

  ngOnInit(): void {
    this.placeService.getPlaces().subscribe((places: Place[]) => {
        this.places = places;
        if (this.places.length > 0) {
          this.router.navigateByUrl('management/places/' + this.places[0].id);
        }
    });
    this.renderSeoMetadata();
  }

}
