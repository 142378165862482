
<form [formGroup]="form" (ngSubmit)="onSubmit()">
    <div class="modal-header bg-secondary">
        <h5 class="modal-title" id="checkInTitle" i18n>Check-in</h5>
        <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body container px-4">
        <div class="row">
            <div class="col">
                <div class="form-group">
                    <label for="checkInCodeInput" class="lead" i18n>Booking code</label>
                    <input 
                        type="text" 
                        class="form-control form-control-lg" 
                        id="checkInCodeInput" 
                        placeholder="Booking code" 
                        i18n-placeholder="booking code input placeholder" 
                        aria-describedby="bookingCode" 
                        [class.is-invalid]="checkInCodeControl.invalid && (checkInCodeControl.dirty || checkInCodeControl.touched)"
                        formControlName="checkInCode"
                        required
                    />
                    <div class="invalid-feedback mb-2" *ngIf="checkInCodeControl.invalid && (checkInCodeControl.dirty || checkInCodeControl.touched)">
                        <span class="alert-inner--icon mr-2"><i class="fa fa-exclamation-circle"></i></span>
                        <ng-container *ngIf="checkInCodeControl.hasError('required')" i18n>Please specify a booking code.</ng-container>
                        <ng-container *ngIf="checkInCodeControl.hasError('BookingNotFoundError')" i18n>We could not find a booking given the code <pre class="d-inline">{{ inputCheckInCode }}</pre>. Maybe it's a typo?</ng-container>
                        <ng-container *ngIf="checkInCodeControl.hasError('unknownError')" i18n>Unknown error, please <a [routerLink]="" (click)="onSubmit()" class="text-dark">retry</a>.</ng-container>
                    </div>
                    <small id="bookingCode" class="form-text text-muted" i18n>The booking code can be found in the user's profile or in the confirmation email.</small>
                </div>
                <!-- <hr/> -->
                <!-- OR -->
                <!-- Scan QR code -->
            </div>
        </div>
    </div>
    <div class="modal-footer">
        <div class="container-fluid">
            <div class="row">
                <div class="offset-lg-6 col-12 col-lg-3 my-2 my-lg-0">
                    <button type="button" class="btn btn-link text-primary w-100" [class.d-none]="!showBackButton" data-dismiss="modal" (click)="back()">
                        <i class="fa fa-arrow-left pr-2" aria-hidden="true"></i>
                        <span i18n>Back</span>
                    </button>
                </div>
                <div class="col-12 col-lg-3 my-2 my-lg-0">
                    <button type="submit" class="btn btn-primary px-3 w-100" [disabled]="form.invalid || resolvingCheckIn">
                        <span *ngIf="resolvingCheckIn" class="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true"></span>
                        <ng-container i18n>Submit</ng-container>
                    </button>
                </div>
            </div>
        </div>
    </div>
</form>
