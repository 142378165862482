import {Choice, ApiResponseChoice, ChoiceSerializer} from "./choice.model";
import {Serializer} from "../shared/serializer";
import {Injectable} from "@angular/core";

export interface ApiResponseSection {
    id: string
    order: number
    name: string
    description?: string
    choices?: (ApiResponseChoice[] | null)
    min_choices: number
    max_choices: number
}

export class Section {
  constructor(
    public id: string,
    public order: number,
    public name: string,
    public description?: string,  // TODO use default empty string
    public choices: Choice[] = null,  //TODO Use empty list instead of null
    public min_choices: number = 0,
    public max_choices: number = null,
  ) {
    if(this.isChoiceRequired && !this.hasChoices) {
      throw new Error("Choices must be defined if a choice is required.");
    }

    if(!this.hasChoices && !description) {
      throw new Error("A section must have at least a choice or a description (no choices)");
    }
  }

  get hasChoices() : boolean {
    return (this.choices !== null) && this.choices.length > 0;
  }

  get isChoiceRequired(): boolean {
    return this.min_choices > 0;
  }

  getChoiceById(id: string) {
    if(this.choices === null) {
      throw new Error("Cannot resolve choice by id if the menu section doesn't have choices");
    }

    for (const choice of this.choices) {
      if(choice.id === id) return choice;
    }

    throw new Error(`Could not find choice with id=${id} in menu section`)
  }
}

@Injectable({
  providedIn: 'root',
})
export class SectionSerializer extends Serializer<ApiResponseSection, Section> {
  constructor(
    private choiceSerializer: ChoiceSerializer,
  ) {
    super();
  }

  deserialize(apiSection: ApiResponseSection): Section {
    const choices = this.choiceSerializer.deserializeMany(apiSection.choices);

    return new Section(
      apiSection.id,
      apiSection.order,
      apiSection.name,
      apiSection.description,
      choices,
      apiSection.min_choices,
      apiSection.max_choices,
    );
  }
}
