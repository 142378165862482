<div *ngIf="isLoading" style="text-align: center">
  <app-loading-spinner></app-loading-spinner>
</div>
<div class="btn-wrapper container" *ngIf="!isLoading"><div class="row">
  <div class="p-0" [ngClass]="cssClassesGoogle">
    <button
      (click)="getGoogleAccessToken()"
      class="btn btn-white btn-icon btn-google w-100 text-left m-0"
      [ngClass]="cssClassesBtnGoogle"
      style="height:40px;padding:8px"
    >
      <img src="./assets/img/btn_google_light_normal.svg" style="margin-right:24px;" class="h-100" />
      <span class="btn-inner--text m-0" style="font-family: 'Roboto', sans-serif" i18n> Sign in with Google </span>
    </button>
  </div>
  <div class="p-0" [ngClass]="cssClassesFacebook">
    <button
      (click)="signInWithFB()"
      class="btn btn-white btn-icon btn-facebook w-100 text-left m-0"
      style="height: 40px;padding:8px"
    >
      <img src="./assets/img/f_logo_RGB-White_1024.svg" style="margin-right:24px;" class="h-100" />
      <span class="btn-inner--text m-0" i18n> Sign in with Facebook </span>
    </button>
  </div>
</div></div>
