import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../../../events/event.model';
import { Choice } from '../../../events/choice.model';
import { Section } from '../../../events/section.model';
import { Menu } from '../../../events/menu.model';
import { Booking, Table, indexBookingTableJoiningCodesByDate, indexBookingsByTableJoiningCode, indexTablesByTableJoiningCode } from '../booking.model';
import { DateMap } from '../../../shared/utils/map';
import { FormControl, FormGroup } from '@angular/forms';
import { ParticipantChoice } from '../../../booking/booking.model';


type ChoicesEntry = {
  choice: Choice,
  quantity: number,
  hasNotes: boolean
};

type ChoicesBySectionEntry = {
  section: Section,
  choices: ChoicesEntry[],
  noPreferences: number,
};


// NOTE: supports only a menu
class TableChoicesSummary {
  /**
   * Ensures that all the choices provided are associated to the same menu.
   * @param participantChoices choices to validate
   * @param menu undefined if reference menu is not set, otherwise reference menu
   * @returns reference menu for the choices provided (if valid). Undefined if no choices and undefined reference menu in input.
   */
  private validateChoices(participantChoices: ParticipantChoice[], menu: Menu | null | undefined = undefined): Menu | null | undefined {
    for (const participantChoice of participantChoices) {
      if(menu === undefined) {
        menu = participantChoice.menu;
      } else {
        // validate menu
        if(menu !== participantChoice.menu) {
          throw "ERROR: TableChoicesSummary does not support multiple menus";
        }
      }
    }
    return menu;
  }

  private validateBookings(bookings: Booking[]): Menu | null | undefined {
    var menu: Menu | null | undefined = undefined;
    for (const booking of bookings) {
      if(booking.participant_choices === null) {
        if(menu) {
          throw "ERROR: TableChoicesSummary does not support menus with optional choices";
        } else {
          // set menu to unset (assumption: no choices = no menu)
          menu = null;
          continue;
        }
      }
      menu = this.validateChoices(booking.participant_choices.participantChoices);
    }
    return menu;
  }

  menu: Menu | null | undefined;
  choicesBySection: ChoicesBySectionEntry[] = [];

  constructor(bookings: Booking[]) {
    this.menu = this.validateBookings(bookings);

    if(this.menu && this.menu.sections !== null) {
      for (let sectionIndex = 0; sectionIndex < this.menu.sections.length; sectionIndex++) {
        const section = this.menu.sections[sectionIndex];

        const choicesBySectionId: Map<string, ChoicesEntry> = new Map();
        const _sectionEntry: ChoicesBySectionEntry = {
          section: section,
          choices: [],
          noPreferences: 0,
        };

        for (const booking of bookings) {
          if(!booking.participant_choices) continue;

          for (const choices of booking.participant_choices.participantChoices) {
            // process section choice associated to a single participant
            const sectionChoice = choices.getChoiceFor(sectionIndex);
            if(sectionChoice === null) {
              _sectionEntry.noPreferences += 1;
            } else {
              if(choicesBySectionId.has(sectionChoice.id)) {
                (choicesBySectionId.get(sectionChoice.id) as ChoicesEntry).quantity += 1;
              } else {
                const hasNotes = !!booking.notes;
                choicesBySectionId.set(sectionChoice.id, {choice: sectionChoice, quantity: 1, hasNotes: hasNotes});
              }
            }
          }
        }

        if(_sectionEntry.noPreferences > 0) {
          // TODO: handle notes flag
          _sectionEntry.choices = [{choice: Choice.NO_PREFERENCE, quantity: _sectionEntry.noPreferences, hasNotes: false}];
        }
        _sectionEntry.choices = _sectionEntry.choices.concat(Array.from(choicesBySectionId.values()));
        _sectionEntry.choices.sort((a, b) => { return a.choice.order - b.choice.order; });

        this.choicesBySection.push(_sectionEntry as ChoicesBySectionEntry);
      }
    }
  }
};


@Component({
  selector: 'app-management-orders-list',
  templateUrl: './orders-list.component.html',
  styleUrls: ['./orders-list.component.scss']
})
export class OrdersListComponent implements OnInit {

  @Input() event: Event;
  @Input() bookings: Booking[];

  form!: FormGroup;
  selectedSchedule: number | null = null;

  dates!: Date[];
  tablesDateList!: {date: Date, tables: Set<string>}[];

  readonly bookingsByTable: Map<string, Booking[]> = new Map(); // index: Table Joining Code
  readonly tablesByJoiningCode: Map<string, Table> = new Map();

  readonly tableChoicesSummaryByTable: Map<string, TableChoicesSummary> = new Map(); // index: Table Joining Code
  choicesSummary?: TableChoicesSummary;

  constructor() { }

  buildIndexes() {
    const tablesByDate: DateMap<Set<string>> = new DateMap();
    const indexFns: [Map<any, any>, (map: Map<any, any>, booking: Booking) => void][] = [
      [this.tablesByJoiningCode, indexTablesByTableJoiningCode],
      [tablesByDate, indexBookingTableJoiningCodesByDate],
      [this.bookingsByTable, indexBookingsByTableJoiningCode],
    ];

    for(const booking of this.bookings) {
      for(let [map, fn] of indexFns) {
        fn(map, booking);
      }
    }

    this.tablesDateList = Array.from(tablesByDate.entries()).map(t => { return {date: t[0], tables: t[1]} });
    this.dates = Array.from(tablesByDate.keys()).sort();
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      schedule: new FormControl(this.selectedSchedule)
    });

    this.buildIndexes();

    // build table summaries
    for (const [tableJoiningCode, bookings] of this.bookingsByTable.entries()) {
      this.tableChoicesSummaryByTable.set(tableJoiningCode, new TableChoicesSummary(bookings));
    }

    // build global summary
    this.choicesSummary = new TableChoicesSummary(this.bookings);
  }

  onFilterChange($event): void {
    this.selectedSchedule = this.form.controls.schedule.value;
  }

  bookingsHaveNotes(bookings: Booking[]) : boolean {
    for (const booking of bookings) {
      if(booking.notes) return true;
    }
    return false;
  }
}
