import { NgModule, ModuleWithProviders } from '@angular/core';
import { GtagService } from './gtag.service';
import { GtagDirective } from './gtag.directive';
import { GtagConfig, GtagConfigToken } from './gtag.model';

// export interface Gtag {
//     (cmd: 'set', params: CustomParams): void;
//     (cmd: 'config', targetId: string, config?: ConfigParams): void;
//     (cmd: 'event', action: string, params?: EventParams): void;
// }

// export interface CustomParams {
//     [key: string]: any;
// }

// //@see: https://developers.google.com/gtagjs/reference/parameter#control_parameters
// export interface ConfigParams extends CustomParams {
//     groups?: string | string[];
//     send_to?: string | string[];
//     event_callback?: () => void;
//     event_timeout?: number;

//     //@see: https://developers.google.com/analytics/devguides/collection/gtagjs#disable_pageview_measurement
//     send_page_view?: boolean;

//     //@see: https://developers.google.com/analytics/devguides/collection/gtagjs/enhanced-link-attribution
//     link_attribution?: boolean;

//     //@see: https://developers.google.com/analytics/devguides/collection/gtagjs/ip-anonymization
//     anonymize_ip?: boolean;

//     //@see: https://developers.google.com/analytics/devguides/collection/gtagjs/display-features
//     allow_ad_personalization_signals?: boolean;
// }

// export interface EventParams extends CustomParams {
//     non_interaction?: true;
//     event_category?: string;
//     event_label?: string;
//     value?: number;
// }

// export interface GtagConfig {
//     targetId: string; // gtag('config', 'targetId', configInfo )
//     configInfo?: ConfigParams;
//     setParams?: CustomParams; // gtag('set', setParams)
//     moreIds?: string[];
//     timeout?: number;
// }

// export const GtagConfigToken = new InjectionToken<GtagConfig>('wizdm.gtag.config');






// class GtagProxyFactory {
//     constructor(private cookieService: CookieService) {
//     }

//     build(): Gtag {
//         const _this = this;
//         return function(cmd, ...args) {
//             if() {

//             }
//         };


//     }
// }


// /** Reproduces the standard code snippet we would paste in index.html
//  * @see: https://developers.google.com/analytics/devguides/collection/gtagjs */
// export function gtagFactory(config: GtagConfig, cookieService: CookieService): Gtag {
//     if ('gtag' in window) { return window['gtag']; }

//     // return proxy object (forwarding calls only if GTAG is enabled)
//     return new GtagProxyFactory(cookieService).build();
// }

@NgModule({
    declarations: [GtagDirective],
    exports: [GtagDirective]
})
export class GtagModule {
    static init(config: GtagConfig): ModuleWithProviders<GtagModule> {
        console.debug(`DEBUG[gtag/gtag.module]: initializing with config=${JSON.stringify(config)}`)
        return {
            ngModule: GtagModule,
            providers: [
                { provide: GtagConfigToken, useValue: config },
                GtagService,
                // {
                //     provide: GTAG,
                //     useFactory: gtagFactory,
                //     deps: [[new Optional(), new Inject(GtagConfigToken)], CookieService]
                // }
            ]
        };
    }
}
