import {
  Component,
  Input,
  OnInit,
  OnDestroy,
  OnChanges,
  Output,
  EventEmitter,
} from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { EventsQuery } from '../../events/events-query.model';
import { Subscription } from 'rxjs';
import { dateToNgbDate, ngbDateToString } from '../../shared/utils/dates';
import { Location } from 'src/app/location/location.model';
import { Tag } from '../../events/tag.model';
import { TagsService } from '../../events/tags.service';
import { DEFAULT_SEARCH_DISTANCE } from '../event-filters.constants';
import {LocationService} from "../../location/location.service";

@Component({
  selector: 'app-search-event-advanced',
  templateUrl: './search-event-advanced.component.html',
  styleUrls: ['./search-event-advanced.component.scss'],
})
export class SearchEventAdvancedComponent implements OnInit, OnDestroy, OnChanges {
  @Input() eventsQuery: EventsQuery;
  @Output() filterChanged = new EventEmitter<EventsQuery>();
  filterForm: UntypedFormGroup;
  tags: Tag[];

  private formChangesSubscription: Subscription;

  constructor(
    private calendar: NgbCalendar,
    private fb: UntypedFormBuilder,
    private tagsService: TagsService,
    private locationService: LocationService,
  ) {}

  onLocationChanged(location: Location) {
    // Emit EventsQuery change when the location changes
    this.filterChanged.emit(
      new EventsQuery(
        location?.latitude,
        location?.longitude,
        this.eventsQuery.dist | DEFAULT_SEARCH_DISTANCE,
        this.eventsQuery.fromDate,
        this.eventsQuery.toDate,
        this.eventsQuery.category,
        this.eventsQuery.hot
      )
    );
  }

  private getEventsQueryAsFormControlValue() {
    return {
      fromDate: this.eventsQuery.fromDate
          ? dateToNgbDate(new Date(this.eventsQuery.fromDate))
          : this.calendar.getToday(),
      toDate: this.eventsQuery.toDate !== undefined
          ? dateToNgbDate(new Date(this.eventsQuery.toDate))
          : null,
      category: this.eventsQuery.category
          ? this.eventsQuery.category
          : 'any',
      hot: !!this.eventsQuery.hot,
    }
  }

  ngOnInit() {
    this.tagsService.loadTags().subscribe((tags: Tag[]) => {
      this.tags = tags;
    });

    this.locationService.locationSubject.subscribe((location) => {
      this.onLocationChanged(location);
    });

    const filterFormValues = this.getEventsQueryAsFormControlValue();
    this.filterForm = this.fb.group({
      fromDate: new UntypedFormControl(filterFormValues.fromDate),
      toDate: new UntypedFormControl(filterFormValues.toDate),
      category: new UntypedFormControl(filterFormValues.category),
      hot: new UntypedFormControl(filterFormValues.hot),
    });

    // Emit EventsQuery change when form values change
    this.formChangesSubscription = this.filterForm.valueChanges.subscribe(
      (formValue) => {
        this.filterChanged.emit(
          new EventsQuery(
            this.eventsQuery.lat,
            this.eventsQuery.lng,
            this.eventsQuery.dist,
            formValue.fromDate
              ? ngbDateToString(formValue.fromDate)
              : undefined,
            formValue.toDate ? ngbDateToString(formValue.toDate) : undefined,
            formValue.category,
            formValue.hot
          )
        );
      }
    );
  }

  ngOnChanges() {
    // REMINDER: ngOnChanges is called before ngOnInit
    if(this.filterForm) {
      const newFilterFormValue = this.getEventsQueryAsFormControlValue();
      if(JSON.stringify(newFilterFormValue) !== JSON.stringify(this.filterForm.value)) {
        this.filterForm.patchValue(newFilterFormValue);
      }
    }
  }

  ngOnDestroy() {
    this.formChangesSubscription.unsubscribe();
  }
}
