import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../auth/auth.service';
import { Page } from '../shared/components/seo/page.model';
import { SeoImage } from '../shared/components/seo/seo.model';
import { SeoService } from '../shared/components/seo/seo.service';
import { settings } from '../shared/settings';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss'],
})
export class AccountComponent implements OnInit, Page {
  static ACCOUNT_PAGES = ["profile", "bookings"];

  SUPPORT_EMAIL = settings.SUPPORT_EMAIL;

  currentPage = null;

  constructor(
    public authService: AuthService,
    private route: ActivatedRoute,
    private seoService: SeoService,
  ) {
  }
  
  /////////////////////////////////////////////////////////////////////////////
  // SEO METADATA
  /////////////////////////////////////////////////////////////////////////////
  getSeoTitle(): string {
    return $localize `:account page SEO title:Restaudeal | My Account`;
  }

  getSeoDescription(): string {
    return $localize `:account page SEO description:Restaudeal | Personal account page`;
  }

  getSeoImage(): SeoImage {
    return settings.DEFAULT_SEO_IMAGE;
  }

  getSeoUrl(): string | null {
    return null;
  }

  renderSeoMetadata(): void {
    this.seoService.renderSeoMetadata(this);
  }
  /////////////////////////////////////////////////////////////////////////////

  ngOnInit() {
    this.route.fragment.subscribe((fragment) => {
      if (fragment) {
        // go to section (quickfix for first rendering)
        setTimeout(() => {
          this.setPage(fragment);
        }, 100);
      } else {
        this.setPage("profile");
      }
    });
    this.renderSeoMetadata();
  }

  setPage(page: string) {
    if(!AccountComponent.ACCOUNT_PAGES.includes(page)) {
      page = "profile";
    }

    this.currentPage = page;
  }
}
