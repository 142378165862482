import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class AlertService {
  alerts: any[] = [];
  delay: 1000;

  show(textOrTpl: string, options: any = {}) {
    this.alerts.push({ textOrTpl, ...options });
  }

  remove(alert) {
    this.alerts = this.alerts.filter((t) => t !== alert);
  }

  showUtil(message, level) {
    this.show(message, { type: level, delay: this.delay });
  }

  success(message) {
    this.showUtil(message, 'success');
  }

  info(message) {
    this.showUtil(message, 'info');
  }

  warning(message) {
    this.showUtil(message, 'warning');
  }

  error(message) {
    this.showUtil(message, 'danger');
  }
}
