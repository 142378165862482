import {
  HttpEvent,
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AuthService } from '../../auth/auth.service';
import { ToastService } from '../components/toasts/toast.service';

@Injectable({
  providedIn: 'root',
})
export class ErrorInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private toastService: ToastService
  ) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      catchError((err: HttpErrorResponse) => {
        if (err.error instanceof ProgressEvent) {
          this.toastService.error('A generic network error occured');
        } else if (err.status === 401) {
          this.authService.clearAuth();
        } else if (err.status === 403) {
          this.toastService.error('Sorry, you cannot perform this action');
        }
        return throwError(err);
      })
    );
  }
}
