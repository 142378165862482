<div class="modal-header bg-secondary">
    <h5 class="modal-title" id="checkInTitle" i18n>Select event</h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-0">
    <div class="card dropdown-item cursor-pointer" *ngFor="let event of events">
        <div class="card-body" (click)="selectEvent(event)">
            <div class="row h-100">
                <div class="col">
                    <h5>{{ event.name }}</h5>
                    <p>{{ event.short_description }}</p>
                    <p class="text-primary">
                        <span *ngFor="let tag of event.tags" class="mr-2">#{{ tag.slug }}</span>
                    </p>
                </div>
                <div class="col-auto my-auto">
                    <i class="ni ni-bold-right"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="text-center lead m-5" *ngIf="events.length === 0" i18n>No events available now</div>
</div>