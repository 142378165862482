<div *ngIf="state !== undefined" class="justify-content-md-center">
  <app-booking-summary-mobile
    [event]="event"
    [state]="state"
  ></app-booking-summary-mobile>
  <ng-container *ngIf="event.can_be_booked; else cannot_be_booked">
    <app-booking-select
      [event]="event"
      [state]="state"
      *ngIf="state.step == 1"
    ></app-booking-select>
    <app-booking-menu
      [state]="state"
      *ngIf="state.step == 2"
    ></app-booking-menu>
    <app-booking-login
      *ngIf="state.step == 3"
    ></app-booking-login>
    <app-booking-payment
      [event]="event"
      [state]="state"
      *ngIf="state.step == 4"
    ></app-booking-payment>
    <div
      *ngIf="state.step == 5"
    >
      <app-booking-confirmation
        [event]="event"
        [state]="state"
      ></app-booking-confirmation>
      <!--
      <hr />
      <div i18n>
        Do you want to make another reservation?
        <button
          class="btn btn-link text-primary btn-lg"
          type="button"
          (click)="restart()"
        >
          <i class="fa fa-repeat" aria-hidden="true"></i> Restart
        </button>
      </div>
      -->
    </div>
  </ng-container>
  <ng-template #cannot_be_booked>
    <app-booking-unavailable
      [event]="event"
    ></app-booking-unavailable>
  </ng-template>
</div>
