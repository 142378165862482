export function getOrCreateArray<K, V>(map: Map<K, V[]>, key: K): V[] {
    if (map.has(key)) {
        return map.get(key) as V[];
    } else {
        const res = [];
        map.set(key, res);
        return res;
    }
}


export function getOrCreateSet<K, V>(map: Map<K, Set<V>>, key: K): Set<V> {
    if (map.has(key)) {
        return map.get(key);
    } else {
        const res = new Set<V>();
        map.set(key, res);
        return res;
    }
}


/**
 * Adds the value to the corresponding Set indexed in the map by key.
 * If key does not exist a new entry will be added with a new Set containing value only.
 * @param map map to update
 * @param key reference key value
 * @param value value to add to the Set indexed by key
 */
export function addToSetInMap<K, V>(map: Map<K, Set<V>>, key: K, value: V): void {
    if(map.has(key)) {
        map.get(key).add(value);
    } else {
        map.set(key, new Set([value]));
    }
}


/**
 * Adds the value to the corresponding array indexed in the map by key.
 * If key does not exist a new entry will be added with a new array containing value only.
 * @param map map to update
 * @param key reference key value
 * @param value value to add to the array indexed by key
 */
export function addToArrayInMap<K, V>(map: Map<K, V[]>, key: K, value: V): void {
    if(map.has(key)) {
        map.get(key).push(value);
    } else {
        const arr = [value];
        map.set(key, arr);
    }
}

export class DateMap<V> extends Map<Date, V> {

    // NOTE: the key is a number associated to a Date 
    //       Cannot use Date as key since date equality is not supported.
    //
    //       Example:
    //         const map: Map<Date, number> = new Map();
    //         const d1 = new Date();
    //         const d2 = new Date(d1);
    //         map.set(d1, 1);
    //         map.has(d1) --> true
    //         map.has(d2) --> false !!!
    private readonly state = new Map<number, V>();

    constructor();
    constructor(entries?: Iterable<[Date, V]> | [Date, V][]) {
        const acc = [];
        if(entries) {
            for(let e of entries) {
                acc.push([e[0].valueOf(), e[1]]);
            }
        }
        super(acc);
    }
    
    set(key: Date, value: V) {
        this.state.set(key.valueOf(), value);
        return this;
    }

    has(key: Date) {
        return this.state.has(key.valueOf());
    }

    get(key: Date) {
        return this.state.get(key.valueOf());
    }

    clear(): void {
        this.state.clear();
    }

    delete(key: Date) {
        return this.state.delete(key.valueOf());
    }

    forEach(callbackfn: (value: V, key: Date, map: Map<Date, V>) => void, thisArg?: any): void {
        this.state.forEach((v, k, _) => callbackfn(v, new Date(k), this), thisArg);
    }

    entries(): IterableIterator<[Date, V]> {
        return Array.from(this.state.entries()).map((t => [new Date(t[0]), t[1]] as [Date, V])).values();
    }

    keys(): IterableIterator<Date> {
        return Array.from(this.state.keys()).map((v) => new Date(v)).values();
    }

    values(): IterableIterator<V> {
        return this.state.values();
    }

    get size(): number {
        return this.state.size;
    };
}
