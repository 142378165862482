import { Component, OnInit } from '@angular/core';
import { Payment } from 'src/app/booking-flow/payment.model';
import { Booking } from 'src/app/booking/booking.model';
import { Event } from 'src/app/events/event.model';
import { Place } from 'src/app/events/place.model';
import { Point } from 'src/app/events/point.model';
import { Schedule } from 'src/app/events/schedule.model';
import { Tag } from 'src/app/events/tag.model';
import {BookingService} from "../../booking-flow/booking.service";

@Component({
  selector: 'app-bookings',
  templateUrl: './bookings.component.html',
  styleUrls: ['./bookings.component.scss']
})
export class BookingsComponent implements OnInit {

  openBookings: Booking[] = [];
  closedBookings: Booking[] = [];

  constructor(private bookingService: BookingService) { }

  ngOnInit(): void {
    this.bookingService.listBookings().subscribe((bookings: Booking[]) => {
      bookings.forEach(booking => {
        if (booking.isPast()) {
          this.closedBookings.push(booking);
        } else {
          this.openBookings.push(booking);
        }
      });
    });
  }

}
