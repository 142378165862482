import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { EventAlertBookingTimeComponent } from './event-alert-booking-time';

@Component({
  selector: 'app-event-alert-booking-time-details',
  templateUrl: './event-alert-booking-time-details.component.html',
})
export class EventAlertBookingTimeDetailsComponent extends EventAlertBookingTimeComponent implements OnInit, OnDestroy {
   @Input() event;
}
