import { Component, OnInit, Input } from '@angular/core';
import { Event } from '../../events/event.model';

@Component({
  selector: 'app-booking-unavailable',
  templateUrl: './booking-unavailable.component.html',
  styleUrls: ['./booking-unavailable.component.scss']
})
export class BookingUnavailableComponent implements OnInit {

  @Input() event: Event;
  
  constructor() { }

  ngOnInit(): void {
  }

}
