import { Injectable } from '@angular/core';
import { Serializer } from '../../shared/serializer';

export class Place {
  constructor(
    public id: string,
    public type: string,
    public name: string,
    public created_datetime: Date,
    public address: string,
    public phone_number: string,
    public stripe_account_completed: boolean
  ) {}
}

@Injectable({
  providedIn: 'root',
})
export class PlaceSerializer extends Serializer<Place, Place> {
  constructor() {
    super();
  }

  deserialize(place: Place): Place {
    return new Place(
      place.id,
      place.type,
      place.name,
      new Date(place.created_datetime),
      place.address,
      place.phone_number,
      place.stripe_account_completed
    );
  }
}
