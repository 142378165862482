import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { finalize, Subscription, TimeoutError } from 'rxjs';
import { Page } from '../shared/components/seo/page.model';
import { SeoImage } from '../shared/components/seo/seo.model';
import { SeoService } from '../shared/components/seo/seo.service';
import { settings } from '../shared/settings';
import { Document } from './document.model';
import { DocumentService } from './document.service';

@Component({
  selector: 'app-document',
  templateUrl: './document.component.html',
  styleUrls: ['./document.component.scss'],
})
export class DocumentComponent implements OnInit, OnDestroy, Page {

  static ERROR_TIMEOUT = "timeout";
  static ERROR_NOT_FOUND = "not_found";
  static ERROR_FORBIDDEN = "forbidden";
  static ERROR_UNKNOWN = "unknown";

  // see https://stackoverflow.com/a/60602454
  DocumentComponent = DocumentComponent;

  documentSubscription?: Subscription = undefined;
  document?: Document = undefined;

  documentType?: string = undefined;
  errorType?: string = undefined;

  constructor(
    private router: Router,
    private documentService: DocumentService,
    private seoService: SeoService,
  ) { }

    /////////////////////////////////////////////////////////////////////////////
  // SEO METADATA
  /////////////////////////////////////////////////////////////////////////////
  getSeoTitle(): string {
    if(!this.document) {
      // QUICKFIX: important since not all documents are published yet.
      //           to avoid a negative SEO ranking it is better to keep unique page titles.
      return `Restaudeal | ${this.documentType}`;
    }
    return `Restaudeal | ${this.document.type_display}`;
  }

  getSeoDescription(): string {
    if(!this.document) {
      // QUICKFIX: important since not all documents are published yet.
      //           to avoid a negative SEO ranking it is better to keep unique page descriptions.
      return $localize `:document page SEO description for document not available|A description of the page with an invalid or not yet published legal documents:Document ${this.documentType} coming soon or invalid.`;
    }

    // NOTE: for now supporting a single description. A description per type would be more suitable to improve our SEO ranking.
    return $localize `:document page SEO description|A description of the page with legal documents:You can find in this page the ${this.document.type_display}.`;
  }

  getSeoImage(): SeoImage {
    return settings.DEFAULT_SEO_IMAGE;
  }

  getSeoUrl(): string | null {
    return null;  // Defer to SeoService
  }
  
  renderSeoMetadata(): void {
    this.seoService.renderSeoMetadata(this);
  }
  /////////////////////////////////////////////////////////////////////////////

  ngOnInit(): void {
    // get document type from router URL
    const url = this.router.url;
    this.documentType = url.slice(url.lastIndexOf("/")+1);

    this.documentSubscription = this.documentService.fetchDocument(this.documentType)
    .pipe(
      finalize(() => {
        this.documentSubscription.unsubscribe();
        this.renderSeoMetadata();
      })
    )
    .subscribe({
      next: d => {
        this.document = d;
      },
      error: error => {
        if (error instanceof TimeoutError) {
          this.errorType = DocumentComponent.ERROR_TIMEOUT;
          return;
        }
        if(error instanceof HttpErrorResponse) {
          switch (error.status) {
            case 403:
              this.errorType = DocumentComponent.ERROR_FORBIDDEN;
              return;
            case 404:
              this.errorType = DocumentComponent.ERROR_NOT_FOUND;
              return;
            default:
              break;
          }
        }

        this.errorType = DocumentComponent.ERROR_UNKNOWN;
      }
    })
  }

  ngOnDestroy(): void {
    this.documentSubscription.unsubscribe();
  }

}
