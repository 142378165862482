<div id="cookieSettings" aria-labelledby="cookieSettingsTitle" aria-hidden="true">
    <form
    [formGroup]="form"
    (ngSubmit)="onSave()"
    id="cookieSettingsForm"
    >
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="cookieSettingsTitle" i18n>Manage your cookies</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body container px-4">
                <div class="row">
                    <p i18n>
                        We collect the minimum amount of information to understand who our audience is and how they interact with our services.
                        To do so we have limited our data collection to just a few partners. 
                    </p>
                    <p i18n>
                        If you want to limit further the data we collect, you can manage your preferences here or in your browser settings.
                    </p>
                    <p i18n>
                        To know more about how we manage your data please refer to our <a [routerLink]="['/privacy-policy']" data-dismiss="modal" (click)="dismiss()">Privacy Policy</a>.
                    </p>
                </div>
                <div class="row mt-4">
                    <div class="w-100">
                        <div>
                            <h6 i18n>Technical</h6>
                        </div>
                    </div>
                    <p i18n>
                        Cookies necessary for the correct behavior of the website (see preferences, security, authentication).
                    </p>
                    <table class="table small">
                        <thead>
                            <tr><th i18n>Vendor</th><th i18n>Usage</th><th i18n>Expiration</th><th i18n>Enabled</th></tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><a href="https://www.restaudeal.com/privacy">Restaudeal</a></td>
                                <td i18n>Store which cookies you have given consent to.</td>
                                <td i18n>1 year</td>
                                <td>
                                    <div class="custom-control custom-switch text-right">
                                        <input type="checkbox" class="custom-control-input" id="restaudealConsent" checked="true" disabled="true" />
                                        <label class="custom-control-label text-hide" for="restaudealConsent" i18n>Restaudeal Toggle</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <a href="https://policies.google.com/privacy" i18n>Google (Privacy)</a>
                                </td>
                                <td i18n>
                                    We use Google reCAPTCHA to limit spam and hostile actions.
                                    For more info please check <a href="https://policies.google.com/terms">Google's Terms of Service</a>
                                </td>
                                <td i18n>Session</td>
                                <td>
                                    <div class="custom-control custom-switch text-right">
                                        <input type="checkbox" class="custom-control-input" id="googleReCAPTCHAConsent" checked="true" disabled="true" />
                                        <label class="custom-control-label text-hide" for="googleReCAPTCHAConsent" i18n>Google reCAPTCHA Toggle</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><a href="https://policies.google.com/privacy">Google</a></td>
                                <td i18n>We use Google OAuth2 service to grant access to our website. Cookies are necessary to maintain authenticated user's sessions.</td>
                                <td i18n>Persistent</td>
                                <td>
                                    <div class="custom-control custom-switch text-right">
                                        <input type="checkbox" class="custom-control-input" id="googleOAuthConsent" checked="true" disabled="true" />
                                        <label class="custom-control-label text-hide" for="googleOAuthConsent" i18n>Google OAuth2 Toggle</label>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td><a href="https://www.facebook.com/full_data_use_policy">Facebook</a></td>
                                <td i18n>We use Facebook Connect to grant access to our website. Cookies are necessary to maintain authenticated user's sessions.</td>
                                <td i18n>Persistent</td>
                                <td>
                                    <div class="custom-control custom-switch text-right">
                                        <input type="checkbox" class="custom-control-input" id="fbConnectConsent" checked="true" disabled="true" />
                                        <label class="custom-control-label text-hide" for="fbConnectConsent" i18n>Facebook Connect Toggle</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div class="row mt-4">
                    <div class="clearfix w-100">
                        <h6 class="float-left" i18n>Statistics</h6>
                        <!--
                        <div class="float-right">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="cookieStatsSwitch" value="statsConsent" checked="" formControlName="gtag" />
                                <label class="custom-control-label text-hide" for="cookieStatsSwitch" i18n>Statistics Toggle</label>
                            </div>
                        </div>
                        -->
                    </div>
                    <table class="table small">
                        <thead>
                            <tr><th i18n>Vendor</th><th i18n>Usage</th><th i18n>Expiration</th><th i18n>Enabled</th></tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td><a href="https://policies.google.com/privacy">Google</a></td>
                                <td i18n>Google Analytics is used to collect information about who visits our website.</td>
                                <td i18n>Session</td>
                                <td>
                                    <div class="custom-control custom-switch text-right">
                                        <input type="checkbox" class="custom-control-input" id="gtagConsent" value="statsConsent" checked="" formControlName="google-analytics" />
                                        <label class="custom-control-label text-hide" for="gtagConsent" i18n>Statistics Toggle</label>
                                    </div>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-secondary w-25" data-dismiss="modal" (click)="dismiss()" i18n>Close</button>
                <button type="submit" class="btn btn-primary w-25" data-dismiss="modal" i18n>Save</button>
            </div>
        </div>
    </form>
</div>