import { Injectable } from '@angular/core';
import { Serializer } from '../shared/serializer';

export class Schedule {
  constructor(
    public id: string,

    public start_datetime: Date,
    public end_datetime: Date,
    public max_people_nb: number,
    public participants_nb: number,
    public available_places: number,

    public can_be_booked: boolean,
    public cannot_be_booked_reason: string | null,
  ) {}

  public get availablePlaces() {
    return this.available_places;
  }

  public isPast(): boolean {
      return this.end_datetime < new Date();
  }
}

@Injectable({
  providedIn: 'root',
})
export class ScheduleSerializer extends Serializer<Schedule, Schedule> {
  deserialize(schedule: Schedule): Schedule {
    return new Schedule(
      schedule.id,
      new Date(schedule.start_datetime),
      new Date(schedule.end_datetime),
      schedule.max_people_nb,
      schedule.participants_nb,
      schedule.available_places,
      schedule.can_be_booked,
      schedule.cannot_be_booked_reason,
    );
  }
}
