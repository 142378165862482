import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../event.model';
import { DateMap, addToArrayInMap } from '../../shared/utils/map';

@Component({
  selector: 'app-event-list-compact',
  templateUrl: './event-list-compact.component.html',
  styleUrls: ['./event-list-compact.component.scss'],
})
export class EventListCompactComponent implements OnInit {
  @Input() events: Event[];
  @Input() groupByDate = false;

  /**
   * Computed only if groupByDate is true.
   */
  eventDateList!: {date: Date, events: Event[]}[];

  indexEventsByDate() {
    let eventsByDate: DateMap<Event[]> = new DateMap();

    // Events running for multiple days will appear multiple times (one per each day)
    for (const event of this.events) {
      for (const date of event.getEventDates()) {
        addToArrayInMap(eventsByDate, date, event);
      }
    }

    this.eventDateList = Array.from(eventsByDate.entries()).map(t => { return {date: t[0], events: t[1]} });
    this.eventDateList.sort((a, b) => a.date.getTime() - b.date.getTime());
  }

  ngOnInit(): void {
    if(this.groupByDate) {
      this.indexEventsByDate();
    }
  }
}
