<!-- WARNING: only single day events are supported in this view -->

<form [formGroup]="form" (change)="onFilterChange($event)">
  <div class="form-group">
      <label for="scheduleSelect" i18n="schedule filter | schedule filter title">Filter by Schedule</label>
      <select class="form-control" id="scheduleSelect" formControlName="schedule">
          <option [ngValue]="null" i18n="schedule filter | all schedules option">All schedules</option>
          <option *ngFor="let date of dates" [ngValue]="date.valueOf()">{{ date | date: 'shortTime' }}</option>
      </select>
  </div>
</form>

<div *ngIf="bookings.length > 0; else noBookings">
  <div class="px-0 px-md-5" style="margin-top: 5rem;" *ngFor="let dateTablesEntry of tablesDateList">
    <div [class.d-none]="selectedSchedule != null && selectedSchedule !== dateTablesEntry.date.valueOf()">
      <span class="small text-muted" style="white-space: nowrap;" i18n="bookings table - schedule">Schedule <em>{{ dateTablesEntry.date | date: 'shortTime' }}</em></span>
      <hr class="mt-0 mb-5" />
      <diV *ngIf="dateTablesEntry.tables.size === 0" i18n>There are no bookings at {{ dateTablesEntry.date | date: 'shortTime' }}.</diV>
      <div class="mb-5" *ngFor="let tableJoiningCode of dateTablesEntry.tables">
        <app-management-table-header [table]="tablesByJoiningCode.get(tableJoiningCode)"></app-management-table-header>
        <app-management-booking-list-table [bookings]="bookingsByTable.get(tableJoiningCode)" [fullWidth]="false"></app-management-booking-list-table>
      </div>
    </div>
  </div>
</div>
<ng-template #noBookings>
  <div i18n>There are no bookings for this event.</div>
</ng-template>