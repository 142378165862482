import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
} from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Injectable } from "@angular/core";
import { LOCALE_ID, Inject } from '@angular/core';

@Injectable()
export class APIRequestInterceptorService implements HttpInterceptor {
  constructor(
    @Inject(LOCALE_ID) private locale: string
  ) { }

  intercept(req: HttpRequest<any>, next: HttpHandler) {
    if (!req.url.startsWith('/')) {
      return next.handle(req);
    }

    // Prepend domain and add a '/' at the end if not present
    let newUrl = environment.baseURL + req.url;
    if (!newUrl.endsWith('/')) {
      newUrl = newUrl + '/';
    }
    req = req.clone({
      url: newUrl,
    });

    // Add application/json content type if not present
    if (!req.headers.has('Content-Type')) {
      req = req.clone({
        headers: req.headers.set('Content-Type', 'application/json'),
      });
    }

    // Add Accept: application/json header
    req = req.clone({
      headers: req.headers.set('Accept', 'application/json'),
    });

    // Set Accept-Language header
    req = req.clone({
      headers: req.headers.set('Accept-Language', this.locale),
    });

    return next.handle(req);
  }
}
