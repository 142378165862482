import { Injectable, NgZone } from '@angular/core';
import { Location } from './location.model';
import { BehaviorSubject, Observable, Observer } from 'rxjs';
import { MapGeocoder } from "@angular/google-maps";
import {isPlatformBrowser} from "@angular/common";

@Injectable({
  providedIn: 'root',
})
export class LocationService {
  private location: Location;
  public locationSubject = new BehaviorSubject<Location>(null);

  constructor(
    private ngZone: NgZone,
    private geocoder: MapGeocoder) {}


  public getLocation(): Location {
    return this.location;
  }

  public setLocation(location: Location) {
    this.location = location;
    this.locationSubject.next(location);
  }

  public setCurrentAddress() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setAddress(
          position.coords.latitude,
          position.coords.longitude
        );
      });
    }
  }

  public setAddress(latitude: number, longitude: number) {
    this.geocoder.geocode(
      {
        location: { lat: latitude, lng: longitude },
        // componentRestrictions: {country: 'FR'},
      }).subscribe(({status, results}) => {
        this.ngZone.run(() => {
          if (status === 'OK') {
            this.location = this.getLocalityLocation(results);
            this.locationSubject.next(this.location);
          } else {
            console.error('Geocoder failed due to: ' + status);
          }
        });
      }
    );
  }

  private getLocalityLocation(results: Array<google.maps.GeocoderResult>) {
    for (const result of results) {
      if (result.types.includes('locality')) {
        return new Location(
          result.geometry.location.lat(),
          result.geometry.location.lng(),
          result.formatted_address
        );
      }
    }

    // If the locality was not found in the root types,
    // look inside the address_components of each result
    // for a locality and a country
    let localityResult: google.maps.GeocoderResult;
    let locality: string;
    let country: string;
    for (const result of results) {
      for (const component of result.address_components) {
        if (component.types.includes('locality')) {
          localityResult = result;
          locality = component.long_name;
        }
        if (component.types.includes('country')) {
          country = component.long_name;
        }
      }
    }

    let name: string;

    if (locality && country) {
      name = locality + ', ' + country;
    } else if (locality) {
      name = locality;
    }

    if (name) {
      return new Location(
        localityResult.geometry.location.lat(),
        localityResult.geometry.location.lng(),
        name
      );
    }
    return undefined;
  }
}
