import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AuthGuard } from './auth/auth.guard';
import { AccountComponent } from './account/account.component';
import { EventDetailComponent } from './events/event-detail/event-detail.component';
import { ExploreComponent } from './explore/explore.component';
import { PlaceListComponent as ManagementPlaceListComponent } from './management/places/place-list/place-list.component';
import { PlaceDetailComponent as ManagementPlaceDetailComponent } from './management/places/place-detail/place-detail.component';
import { EventDetailComponent as ManagementEventDetailComponent } from './management/events/event-detail/event-detail.component';
import { DocumentComponent } from './document/document.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'account',
    component: AccountComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'events',
    component: ExploreComponent,
  },
  {
    path: 'events/:id',
    component: EventDetailComponent,
  },
  // Routes for the restaurant's dashboard
  {
    path: 'management/places',
    component: ManagementPlaceListComponent,
  },
  {
    path: 'management/places/:id',
    component: ManagementPlaceDetailComponent,
  },
  {
    path: 'management/events/:id',
    component: ManagementEventDetailComponent,
  },
  // Routes for legal documents
  {
    path: 'privacy-policy',
    component: DocumentComponent,
  },
  {
    path: 'general-terms-of-use',
    component: DocumentComponent,
  },
  {
    path: 'general-terms-of-subscription',
    component: DocumentComponent,
  },
  // Redirect to the home page by default
  { path: '**', redirectTo: '' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollOffset: [0, 150],
    initialNavigation: 'enabledBlocking'
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
