import { Component, Input } from '@angular/core';
import { ParticipantChoices } from 'src/app/booking/booking.model';

@Component({
  selector: 'app-participants-selection-collapsible-summary',
  templateUrl: './participants-selection-collapsible-summary.component.html',
  styleUrls: ['./participants-selection-collapsible-summary.component.scss']
})
export class ParticipantsSelectionCollapsibleSummaryComponent {
  @Input() isParticipantChoicesCollapsed: boolean = true;
  @Input() participantChoices: ParticipantChoices;
}
