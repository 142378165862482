import {ApiResponseSection, SectionSerializer} from "./section.model";
import {Section} from "./section.model";
import {Injectable} from "@angular/core";
import {Serializer} from "../shared/serializer";

export interface ApiResponseMenu {
  id: string
  created_datetime: Date
  name: string
  description?: string
  sections?: (ApiResponseSection[] | null)
}

export class Menu {
  public sections: (Section[] | null);

  constructor(
    public id: string,
    public createdDatetime: Date,
    public name: string,
    public description?: string,
    sections?: Section[],
  ) {
    if(sections === undefined) {
      this.sections = null;
    } else {
      this.sections = sections;
    }

    if(!this.sections && !description) {
      throw new Error("A menu must have at least a section or a description (no choices)");
    }
  }

  get isWithChoices(): boolean {
    if(this.sections === null) return false;

    for (const section of this.sections) {
      if(section.hasChoices) return true;
    }
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class MenuSerializer extends Serializer<ApiResponseMenu, Menu> {

  constructor(
    private sectionSerializer: SectionSerializer,
  ) {
    super();
  }

  deserialize(apiMenu: ApiResponseMenu): Menu {
    const sections = this.sectionSerializer.deserializeMany(apiMenu.sections);

    return new Menu(
      apiMenu.id,
      apiMenu.created_datetime,
      apiMenu.name,
      apiMenu.description,
      sections,
    );
  }

}
