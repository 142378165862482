import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { EventsService } from '../events.service';
import { Event } from '../event.model';
import { BookingFlowService } from '../../booking-flow/booking-flow.service';
import { DatePipe, ViewportScroller } from '@angular/common';
import { filter, first } from 'rxjs/operators';
import { BookingFlowState } from 'src/app/booking-flow/booking-flow-state.model';
import { EventShareService } from '../event-share/event-share.service';
import { GtagService } from 'src/app/gtag/gtag.service';
import { Subscription } from 'rxjs';
import { Page } from '../../shared/components/seo/page.model';
import { SeoImage } from 'src/app/shared/components/seo/seo.model';
import { SeoService } from 'src/app/shared/components/seo/seo.service';
import { TableService } from '../../tables/table.service';
import { ToastService } from '../../shared/components/toasts/toast.service';

@Component({
  selector: 'app-event-detail',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss'],
})
export class EventDetailComponent implements OnInit, OnDestroy, Page {
  currentSection = undefined;

  event: Event;
  state: BookingFlowState;
  nbSelectedParticipants = 1;

  timeLeftMessageType: string = "urgent_few_hours";
  minutesLeft: number = 90;
  timeLeft: Date;

  private routeChangedSubscription: Subscription;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private eventsService: EventsService,
    private tableService: TableService,
    private bookingFlowService: BookingFlowService,
    private viewportScroller: ViewportScroller,
    private eventShareService: EventShareService,
    private toastService: ToastService,
    private gtagService: GtagService,
    private seoService: SeoService,
    private datePipe: DatePipe,
  ) {}


  /////////////////////////////////////////////////////////////////////////////
  // SEO METADATA
  /////////////////////////////////////////////////////////////////////////////
  getSeoTitle(): string {
    return $localize `Restaudeal | ${this.event.name} @ ${this.event.place.name}`;
  }

  getSeoDescription(): string {
    // Adding date information to differentiate repetitions of the same event (make unique descriptions).
    const startDate = this.datePipe.transform(this.event.start_datetime, 'fullDate');
    let eventDateDescription; 
    if(this.event.is_single_day) {
      eventDateDescription = $localize `This event occurs on ${startDate}.`;
    } else {
      const endDate = this.datePipe.transform(this.event.end_datetime, 'fullDate');
      eventDateDescription = $localize `This event occurs from ${startDate} to ${endDate}.`;
    }

    const eventMainDescription = $localize `Get the best deal at this event at ${this.event.place.name} (${this.event.place.address})! Menu starts at ${this.event.best_price}€. ${this.event.short_description}.`;
    
    return eventMainDescription + ' ' + eventDateDescription;
  }

  getSeoImage(): SeoImage {
    return {
      url: this.event.image_url,
    };
  }

  getSeoUrl(): string | null {
    return null; // deferred to SeoService
  }

  renderSeoMetadata(): void {
    this.seoService.renderSeoMetadata(this);
  }
  /////////////////////////////////////////////////////////////////////////////

  ngOnInit(): void {
    this.timeLeft = new Date(1900, 0, 1);
    this.timeLeft.setMinutes(this.minutesLeft);

    // handle Component creation.
    this.loadEvent();

    // Handle URL changes targeting this component.
    // Need to subscribe to router to avoid duplicated events
    // see https://stackoverflow.com/a/45765143
    this.routeChangedSubscription = this.router.events.
      pipe(filter(event => event instanceof NavigationEnd)).
      subscribe(_ => {
        // NOTE: resets BookingFlowState
        // FIXME: 
        //  ideally we should keep the previous number of participants selected 
        //  (it is likely that I still want to book for the same number of people 
        //  if I am browsing events or I've clicked on the wrong one when joining a table)
        // FIXME: we should keep the table associated to the event if it was defined and we are following this event... use fragment to pass the tableJoiningCode? (table will be resolved twice... bad!)
        const previousState = this.bookingFlowService.stateSource.value;
                
        this.loadEvent().add(() => {
          if(previousState !== null) {
            this.bookingFlowService.setNbSelectedParticipants(previousState.nbSelectedParticipants);
            if(previousState.selectedTable?.event_id === this.event.id) {
              this.bookingFlowService.setSelectedTable(previousState.selectedTable);
            }
          }
        });

      });
  }

  ngOnDestroy(): void {
    this.routeChangedSubscription.unsubscribe();
  }

  /**
   * Resolve table from URL.
   */
  private loadTable() {
    const tableCode = this.route.snapshot.queryParamMap.get("joining_code");
    if(tableCode === null) return;

    return this.tableService.loadTable(tableCode, this.event).subscribe({
      next: (table) => {
        if(table.event_id !== this.event.id) {
          this.toastService.error(($localize `Could not link table to booking. It belongs to another event.`));          
          return;
        }

        this.bookingFlowService.setSelectedTable(table);
        if(this.state.isTableAboveDenyThreshold()) {
          this.toastService.error($localize `Table found but new reservations will make it too big and the restaurant cannot handle it.`);
        } else if(this.state.isTableAboveWarningThreshold()) {
          this.toastService.warning($localize `Table linked to reservation successfully. Due to its size, please contact the restaurant to guarantee single-table seating for your party.`);
        } else {
          this.toastService.success(($localize `Table linked to reservation successfully.`));
        }
      },
      error: (error) => {
        this.toastService.error(($localize `Could not resolve table from URL.`) + " " + error.message);
      }
    });
  }

  /**
   * Load an event using the current route as input params.
   */
  private loadEvent() {
    const id = this.route.snapshot.params.id;
    return this.eventsService.loadEvent(id).subscribe((event) => {
      this.event = event;
      this.renderSeoMetadata();

      this.loadTable();

      const item = {
        item_id: event.id,
        item_name: event.name,
        item_brand: event.place.id,
        item_category: event.tags.length > 0 ? event.tags[0].slug : null,
      }

      const eventStartDate = event.start_datetime ? event.start_datetime.toISOString().substring(0, 10) : "";
      const eventEndDate = event.end_datetime ? event.end_datetime.toISOString().substring(0, 10) : "";

      if(eventStartDate == eventEndDate) {
        item["check_in_date"] = eventStartDate;
      }

      this.gtagService.viewItem({
        items: [item],
      });

      this.bookingFlowService
        .forEvent(event)
        .stateSource.subscribe((state) => {
          this.state = state;
        });

      this.route.fragment.pipe(first()).subscribe((fragment) => {
        if (fragment) {
          // go to section (quickfix for first rendering)
          setTimeout(() => {
            this.goToSection(fragment);
          }, 100);
        }
      });
    });
  }

  goToSection(sectionId: string) {
    if(sectionId == "reservation") {
      this.gtagService.startReservation({
        items: [BookingFlowService.buildItem(this.state)],
      });
    }
    this.currentSection = sectionId;
    this.viewportScroller.scrollToAnchor(this.currentSection);
  }

  shareEvent() {
    this.eventShareService.showModalView(this.event);
  }
  
  shouldShowShareEventCard(): boolean {
    return this.event.can_be_booked && (this.event.next_deals.length > 0);
  }
}
