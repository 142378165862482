import { Component, Inject, Input, LOCALE_ID, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Schedule } from 'src/app/events/schedule.model';
import { GtagService } from 'src/app/gtag/gtag.service';
import { Event } from '../../events/event.model';
import { BookingFlowState } from '../booking-flow-state.model';
import { BookingFlowService } from '../booking-flow.service';
import { Table } from '../../tables/table.model';
import { ScheduleState, ScheduleStateMap } from './booking-select-schedule/booking-select-schedule.component';

@Component({
  selector: 'app-booking-select',
  templateUrl: './booking-select.component.html',
  styleUrls: ['./booking-select.component.scss'],
})
export class BookingSelectComponent implements OnInit, OnChanges {
  @Input() event: Event;
  @Input() state: BookingFlowState;

  scheduleStateMap: ScheduleStateMap = {};

  selectForm: UntypedFormGroup;


  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private bookingFlowService: BookingFlowService,
    @Inject(LOCALE_ID) private locale: string,
    private gtagService: GtagService,
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    // initialize form
    if(!this.selectForm) {
      this.selectForm = this.fb.group({
        // initialize values based on booking flow state
        // - notes
        notesControl: new FormControl<string | null>(
          this.state.notes
        ),
      });
    }

    // initialize state with first available schedule if none already selected
    if(!this.state.selectedSchedule) {
      this.bookingFlowService.setSelectedSchedule(this.getFirstAvailableSchedule());
    }

    // initialize values based on booking flow state
    // - schedules (state)
    this.initScheduleStateMap();
  }

  ngOnInit(): void {
  }

  private initScheduleStateMap() {
    for (const s of this.event.schedules) {
      this.scheduleStateMap[s.id] = this.getScheduleStateGivenUserInput(s);
    }
  }

  isTableSelected() : boolean {
    return this.state.selectedTable !== null;
  }
  
  getFirstAvailableSchedule() {
    for (const s of this.event.schedules) {
        if (this.isScheduleBookableGivenUserInput(s)) {
          return s;
        }
    }
    return null;
  }

  selectTable(selectedTable: Table | null) {
    // NOTE: keep table schedule selected if table deselected
    if(selectedTable === null && this.state.selectedSchedule === null) {
      this.selectSchedule(this.getFirstAvailableSchedule());
    }
    this.bookingFlowService.setSelectedTable(selectedTable);
    this.updateSchedulesState();
  }

  getTotalTableParticipants(): number {
    const currentTableParticipants = this.state.selectedTable ? this.state.selectedTable.people_nb : 0;
    const totalTableParticipants = this.state.nbSelectedParticipants + currentTableParticipants;
    return totalTableParticipants;
  }

  isTableAboveWarningThreshold() {
    return this.getTotalTableParticipants() >= this.event.booking_capacity_warning;
  }

  isTableAboveDenyThreshold() {
    return this.getTotalTableParticipants() >= this.event.booking_capacity_deny;
  }

  updateSchedulesState() {
    for (const s of this.event.schedules) {
      this.scheduleStateMap[s.id] = this.getScheduleStateGivenUserInput(s);
    }
  }

  selectSchedule(selectedSchedule: Schedule) {
    this.bookingFlowService.setSelectedSchedule(selectedSchedule);
    this.updateSchedulesState();
  }

  setNbSelectedParticipants(event: any) {
    this.bookingFlowService.setNbSelectedParticipants(parseInt(event.target.value));
    this.updateSchedulesState();
  }

  isScheduleBookableGivenUserInput(schedule: Schedule) : boolean {
    return schedule.can_be_booked && schedule.availablePlaces >= this.state.nbSelectedParticipants
  }

  isSelectedScheduleBookableGivenUserInput() : boolean {
    return this.isScheduleBookableGivenUserInput(this.state.selectedSchedule);
  }

  isAnyScheduleBookableGivenUserInput() : boolean {
    for (const s of this.event.schedules) {
      if(this.isScheduleBookableGivenUserInput(s)) return true;
    }
    return false;
  }

  isSelectedScheduleTooEarly() {
    return this.state.selectedSchedule.cannot_be_booked_reason === 'too_early';
  }

  isSelectedScheduleTooLate() {
    return this.state.selectedSchedule.cannot_be_booked_reason === 'too_late';
  }

  isSelectedScheduleFullGivenUserInput() {
    return (
      this.state.selectedSchedule.can_be_booked &&
      !this.isSelectedScheduleBookableGivenUserInput() &&
      this.isAnyScheduleBookableGivenUserInput()
    );
  }

  isGroupWithEnoughParticipants() : boolean {
    return (
      (this.state.selectedTable !== null) || 
      (this.state.nbSelectedParticipants >= this.event.min_people_nb_per_table)
    );
  }

  isTableTooBigGivenUserInput() : boolean {
    const threshold = this.event.booking_capacity_deny;
    var tablemates = this.state.nbSelectedParticipants;

    if(this.state.selectedTable) {
      tablemates += this.state.selectedTable.people_nb;
    }

    if(tablemates >= threshold) {
      return false;
    }

    return true;
  }

  isInvalidSelection() {
    return (
      !this.state.selectedSchedule || 
      !this.isSelectedScheduleBookableGivenUserInput() || 
      !this.isGroupWithEnoughParticipants() || 
      !this.isTableTooBigGivenUserInput() || 
      this.selectForm.invalid
    )
  }

  getScheduleStateGivenUserInput(schedule: Schedule) {
    return ScheduleState.getScheduleState(
      schedule === this.state.selectedSchedule, 
      this.isScheduleBookableGivenUserInput(schedule)
    );
  }

  goToTableEvent(table: Table) {
    // NOTE: DISABLED code below since table choices require the target event to be properly parsed.
    // // QUICKFIX to pass table to event-details component
    // this.bookingFlowService.stateSource.value.selectedTable = table;
    this.bookingFlowService.stateSource.value.selectedTable = null;

    this.router.navigate(['/events', table.event_id], {'fragment': "reservation"});
  }

  nextStep() {
    const item = BookingFlowService.buildItem(this.state);

    this.gtagService.addItemToCart({
      currency: "EUR",
      value: this.state.discount.bookingPrice,
      items: [item],
    });
    this.bookingFlowService.setNotes(this.selectForm.controls.notesControl.value);
    this.bookingFlowService.nextStep();
  }

  counter(i: number) {
    return new Array(i);
  }
}
