<div class="row px-2 d-lg-none">
  <div class="card shadow col-sm-9 col-md-7 mx-auto my-5">
    <div class="card-body row">
      <div class="col-12">
        <span class="text-uppercase text-muted" i18n>Your Selection</span>
        <h2 class="card-title text-primary">{{ event.name }}</h2>
        <hr class="my-3" />
      </div>
      <div class="col-12">
        <div class="row">
          <div class="col" style="line-height: 3rem" i18n>Total price:</div>
          <div
            *ngIf="state.discount.totalDiscount > 0"
            class="col-auto px-0 mx-2 text-right"
          >
            <del class="text-muted">
              ({{ state.discount.bookingOriginalPrice }} €)
            </del>
          </div>
          <div class="display-3 col-auto pl-0 pr-xl-2 text-right">
            {{ state.discount.bookingPrice }} €
          </div>
          <div class="col-12 small text-right pr-3" style="height: 2rem">
            <span *ngIf="state.nbSelectedParticipants > 1">
              ({{ state.nbSelectedParticipants }}x {{ state.discount.eventPrice }} €)
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
