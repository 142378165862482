<main class="bg-secondary-alt" style="background-color: #efefef;">
    <div>
        <section class="section section-lg section-shaped mt-5">
            <div class="container p-0"  style="min-height: 1000px">
                <div class="d-flex justify-content-center m-5 p-5" *ngIf="!(document || errorType)">
                    <div class="spinner-border m-5" role="status">
                        <span class="sr-only" i18n="loading">Loading...</span>
                    </div>
                </div>
                <div *ngIf="errorType" class="my-5">
                    <div class="card text-white bg-danger my-5 mx-auto" style="max-width: 35rem;">
                        <ng-container [ngSwitch]="errorType">
                            <div *ngSwitchCase="DocumentComponent.ERROR_TIMEOUT" class="card-body">
                                <h5 class="card-title"><span i18n>ERROR: </span><span i18n>Request timed out</span></h5>
                                <p class="card-text" i18n>We could not fetch the document. Please retry.</p>
                            </div>
                            <div *ngSwitchCase="DocumentComponent.ERROR_NOT_FOUND" class="card-body">
                                <h5 class="card-title"><span i18n>ERROR: </span><span i18n>Document not found</span></h5>
                                <p class="card-text" i18n>The requested document does not exist. Maybe there is a typo in the URL?</p>
                            </div>
                            <div *ngSwitchCase="DocumentComponent.ERROR_FORBIDDEN" class="card-body">
                                <h5 class="card-title"><span i18n>ERROR: </span><span i18n>You cannot access this document</span></h5>
                                <p class="card-text" i18n>You don't have the required permissions to view this document.</p>
                            </div>
                            <div *ngSwitchCase="DocumentComponent.ERROR_UNKNOWN" class="card-body">
                                <h5 class="card-title"><span i18n>ERROR: </span><span i18n>Unknown error</span></h5>
                                <p class="card-text" i18n>This is unexpected. Please retry later.</p>
                            </div>
                        </ng-container>
                    </div>                    
                </div>
                <div *ngIf="document" class="my-lg-5">
                    <div class="text-center p-3">
                        <h1 class="display-1">{{ document.type_display }}</h1>
                        <p class="lead text-muted">version {{ document.version }} - {{ document.published_datetime | date }}</p>
                    </div>
                    <div class="p-3 p-lg-5 card shadow border-0 text-justify">
                        <div [innerHTML]="document.text"></div>
                    </div>
                </div>
                <div class="text-center opacity-4 my-5">
                    <img src="./assets/img/logo-hr-minimal-black.svg" alt="Restaudeal logo" />
                    <p>Restaudeal SAS</p>
                </div>
            </div>
            <div class="separator separator-bottom separator-skew">
                <svg x="0" y="0" viewBox="0 -50 2560 100" preserveAspectRatio="none" version="1.1"
                    xmlns="http://www.w3.org/2000/svg" style="filter: drop-shadow(6px 0 20px hsla(0, 0%, 0%, 0.2));">
                    <polygon class="fill-white" points="2560 -1 2560 50 -1 50"></polygon>
                </svg>
            </div>
        </section>
    </div>
</main>