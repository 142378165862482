import { Component } from '@angular/core';
import { BookingFlowService } from '../booking-flow.service';

@Component({
  selector: 'app-booking-login',
  templateUrl: './booking-login.component.html',
  styleUrls: ['./booking-login.component.scss'],
})
export class BookingLoginComponent {
  constructor(private bookingFlowService: BookingFlowService) {}

  previousStep() {
    this.bookingFlowService.previousStep();
  }
}
