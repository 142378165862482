import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import { Observable, throwError, BehaviorSubject } from 'rxjs';
import { tap, map, catchError } from 'rxjs/operators';
import { User } from './user.model';
import { ToastService } from '../shared/components/toasts/toast.service';
import { Router } from '@angular/router';
import { SocialAuthService } from '@abacritt/angularx-social-login';
import { NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { GtagService } from '../gtag/gtag.service';
import {isPlatformBrowser} from "@angular/common";

export interface LoginResponseData {
  key: string;
}

export interface UserProfileResponseData {
  id: string;
  email: string;
  first_name: string;
  last_name: string;
  subscribed_to_newsletter: boolean;
  is_manager: boolean;
  birth_date?: string;
  phone_number?: string;
  telegram_username?: string;
}

interface UserAuthenticatedSession {
  token: string;
  user: UserProfileResponseData;
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user = new BehaviorSubject<User>(null);
  token: string = null;
  isBrowser: boolean;

  constructor(
    private http: HttpClient,
    private toastService: ToastService,
    private router: Router,
    private socialAuthService: SocialAuthService,
    private ngbDateParserFormatter: NgbDateParserFormatter,
    private gtag: GtagService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.isBrowser = isPlatformBrowser(this.platformId);
  }

  logIn(socialToken: string, provider: string) {
    return this.socialLogIn(socialToken, provider).pipe(
      tap((loginData) => {
        this.token = loginData.key;
        this.getUserProfile().subscribe(
          (userProfileData) => {
            const user = this.buildUserFromResponseData(userProfileData);
            const session: UserAuthenticatedSession = {
              token: this.token,
              user: userProfileData,
            };
            this.user.next(user);
            if (this.isBrowser) {
              localStorage.setItem('userData', JSON.stringify(session));
            }

            // TODO: distinguish between new users and login
            this.gtag.signUp(provider);
          },
          (errorMessage) => {
            this.toastService.error(errorMessage);
          }
        );
      })
    );
  }

  private socialLogIn(socialToken: string, provider: string) {
    return this.http
      .post<LoginResponseData>('/api/v1/auth/login/' + provider.toLowerCase(), {
        access_token: socialToken,
      })
      .pipe(catchError(this.handleLoginError));
  }

  getUserProfile(): Observable<UserProfileResponseData> {
    return this.http
      .get<UserProfileResponseData>('/api/v1/auth/me')
      .pipe(catchError(this.handleGetUserProfileError));
  }

  private buildUserFromResponseData(resData: UserProfileResponseData): User {
    return new User(
      resData.id,
      resData.email,
      resData.first_name,
      resData.last_name,
      resData.subscribed_to_newsletter,
      resData.is_manager,
      this.ngbDateParserFormatter.parse(resData.birth_date),
      resData.phone_number,
      resData.telegram_username,
    );
  }

  updateUserProfile(payload): Observable<User> {

    if (payload.birth_date !== undefined && payload.birth_date !== null) {
      payload.birth_date = this.ngbDateParserFormatter.format(payload.birth_date);
    }

    return this.http
      .patch<UserProfileResponseData>('/api/v1/auth/me', payload)
      .pipe(
        map((resData: UserProfileResponseData) => {
          const updatedUser = this.buildUserFromResponseData(resData);

          this.user.next(updatedUser);

          // update local storage
          const session = { token: this.token, user: updatedUser };
          if (this.isBrowser) {
            localStorage.setItem('userData', JSON.stringify(session));
          }

          this.toastService.success(
            'Your profile has been successfully updated.'
          );
          return updatedUser;
        }),
      );
  }

  autoLogin() {
    if(!this.isBrowser) {
      return;
    }

    const session: UserAuthenticatedSession = JSON.parse(
      localStorage.getItem('userData')
    );

    if (!session) {
      return;
    }

    if (session.token) {
      this.token = session.token;
      this.user.next(this.buildUserFromResponseData(session.user));
    }
  }

  logOut() {
    this.http.post('/api/v1/auth/logout', {}).subscribe(() => {
      this.clearAuth();
    });
  }

  clearAuth() {
    if(!this.isBrowser) {
      return;
    }

    this.socialAuthService.signOut().finally(() => {
      this.token = null;
      this.user.next(null);
      localStorage.removeItem('userData');
      this.router.navigate(['/']);
    });
  }

  isLoggedIn() {
    return !!this.user.value && this.user.value.email !== null;
  }

  private handleLoginError(errorRes: HttpErrorResponse) {
    return throwError('An unexpected error occured');
  }

  private handleGetUserProfileError(errorRes: HttpErrorResponse) {
    return throwError(
      'An unexpected error occured while trying to get your profile'
    );
  }
}
