<div class="mt-3 mb-0">
    <div *ngIf="selectedTable; else tableNotSelected">
        <app-table-card [table]="selectedTable" [showCloseButton]="true" (closeEventEmitter)="removeTable()"></app-table-card>
    </div>    
    <ng-template #tableNotSelected>
        <div class="text-center">
            <button type="button" class="btn btn-link"
                (click)="openSelectTableModal()" 
                i18n="Join table button">
                Joining a table? Click here!
            </button>
        </div>
    </ng-template>
</div>