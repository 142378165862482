// Reference: https://gsma.force.com/mwcoem/servlet/servlet.FileDownload?file=00P6900002qUNl1EAG

/**
 * Returns true if a mobile browser is being used, false otherwise.
 *
 * IMPORTANT:
 * Device detection is usually a bad practice.
 * It is usually NOT needed and feature detection or media queries are usually the best solution.
 * This function uses User Agent strings to detect the device OS and infer if it is a mobile device.
 * It must be used only when implementing a specific behavior that cannot be done otherwise
 * (e.g., avoid native sharing on desktop browsers supporting it to have a more intuitive UX).
 * This function returns true only for UA strings that are explicitly mentioning mobile devices,
 * if not it will return false (strict check).
 */
export function isMobileStrict(userAgent?: string): boolean {
    if(userAgent === undefined) {
        userAgent = navigator.userAgent;
    }

    return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
}

function isUncommonDevice(userAgent?: string): boolean {
    return /Kindle|Xbox|Nintendo|PlayStation|GoogleTV|CrKey/i.test(userAgent);
}

/**
 * Returns true if a desktop browser is being used, false otherwise.
 *
 * IMPORTANT:
 * Device detection is usually a bad practice.
 * It is usually NOT needed and feature detection or media queries are usually the best solution.
 * This function uses User Agent strings to detect the device OS and infer if it is a mobile device.
 * It must be used only when implementing a specific behavior that cannot be done otherwise.
 * This function returns true only for UA strings that are explicitly mentioning desktop devices,
 * if not it will return false (strict check).
 */
export function isDesktopStrict(userAgent?: string): boolean {
    if(userAgent === undefined) {
        userAgent = navigator.userAgent;
    }

    // discard mobile signatures
    if(isMobileStrict(userAgent)) {
        return false;
    }

    // discard special cases (Consoles, eReaders, TVs)
    if(isUncommonDevice(userAgent)) {
        return false;
    }

    // NOTE: considering Chrome OS as a desktop here
    return /Linux|Windows|Mac|CrOS/i.test(userAgent);
}

/**
 * Returns true if a mobile browser is being used, false otherwise.
 *
 * IMPORTANT:
 * Device detection is usually a bad practice.
 * It is usually NOT needed and feature detection or media queries are usually the best solution.
 * This function uses User Agent strings to detect the device OS and infer if it is a mobile device.
 * If an unknown user agent is used, it will return true (permissive check).
 */
export function isMobile(userAgent?: string): boolean {
    if(userAgent === undefined) {
        userAgent = navigator.userAgent;
    }

    return !isDesktopStrict(userAgent);
}

/**
 * Returns true if a desktop browser is being used, false otherwise.
 *
 * IMPORTANT:
 * Device detection is usually a bad practice.
 * It is usually NOT needed and feature detection or media queries are usually the best solution.
 * This function uses User Agent strings to detect the device OS and infer if it is a mobile device.
 * If an unknown user agent is used, it will return true (permissive check).
 */
export function isDesktop(userAgent?: string): boolean {
    if(userAgent === undefined) {
        userAgent = navigator.userAgent;
    }

    return !isMobileStrict(userAgent) && !isUncommonDevice(userAgent);
}
