<div *ngIf="apiLoaded | async">
  <google-map
    height="10rem"
    width="100%"
    [center]="center"
    [zoom]="zoom"
  >
    <map-marker [position]="center" [options]="{draggable: false}" />
  </google-map>
</div>
