import { CookieService } from './cookie.service';

export abstract class UserEnabledService {
    constructor(private cookieService: CookieService) {
        console.debug(`DEBUG[cookie/user-enabled-service]: registering ${this.getConsentKey()} to CookieService`)
        this.cookieService.register(this);
    }

    isActive: boolean = false;
    abstract getConsentKey(): string;
    abstract activate(): void;
}
