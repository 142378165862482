import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { AuthService } from '../auth/auth.service';
import { Subscription } from 'rxjs';
import { Page } from '../shared/components/seo/page.model';
import { SeoImage } from '../shared/components/seo/seo.model';
import { settings } from '../shared/settings';
import { SeoService } from '../shared/components/seo/seo.service';
import { NgbCarousel, NgbSlideEvent, NgbSlideEventSource } from '@ng-bootstrap/ng-bootstrap';
import { EventsQuery } from '../events/events-query.model';
import { Event } from '../events/event.model';
import { EventsService } from '../events/events.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy, Page {
  isAuthenticated = false;
  private userSub: Subscription;

  // used by carousel view
  events: Event[] = undefined;

  constructor(
    private authService: AuthService,
    private seoService: SeoService,
    private eventsService: EventsService,
    private viewportScroller: ViewportScroller,
  ) {}

  /////////////////////////////////////////////////////////////////////////////
  // SEO METADATA
  /////////////////////////////////////////////////////////////////////////////
  getSeoTitle(): string {
    return $localize `:homepage SEO title|A catchy title with a length of at most 70 characters:Restaudeal | Discover New Restaurants and Get Exclusive Offers!`;
  }

  getSeoDescription(): string {
    return $localize `:homepage SEO description|A catchy description that will show up in search engine results:Join our platform and unlock exclusive discounts and offers at restaurants. Our collaborative offers are available to all, regardless of whether they are part of the same group. Start saving today!`;
  }

  getSeoImage(): SeoImage {
    return settings.DEFAULT_SEO_IMAGE;
  }

  getSeoUrl(): string | null {
    return null;  // Defer to SeoService
  }

  renderSeoMetadata(): void {
    this.seoService.renderSeoMetadata(this);
  }
  /////////////////////////////////////////////////////////////////////////////

  ngOnInit() {
    this.renderSeoMetadata();
    this.userSub = this.authService.user.subscribe((user) => {
      this.isAuthenticated = this.authService.isLoggedIn();
    });

    // Fetch events for event carousel
    this.eventsService
      .loadEvents(new EventsQuery())
      .subscribe((events: Event[]) => {
        this.events = events;
      });
  }

  ngOnDestroy() {
    this.userSub.unsubscribe();
  }
  
  goToSection(sectionId: string) {
    this.viewportScroller.scrollToAnchor(sectionId);
  }
}
