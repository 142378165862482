import { Component, Input, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastService } from 'src/app/shared/components/toasts/toast.service';
import { Booking } from '../booking.model';
import { BookingService } from '../booking.service';
import { BookingNotesModalComponent } from './booking-notes-modal.component';


type UiBookingStatus = (
  typeof BookingListTableComponent.STATUS_CONFIRMED |
  typeof BookingListTableComponent.STATUS_CANCELLED |
  typeof BookingListTableComponent.STATUS_CHECKED_IN |
  typeof BookingListTableComponent.STATUS_LATE
)
type UiActionStatus = {
  enabled: boolean,
  loading: boolean,
}
type UiActionsStatus = {
  accept: UiActionStatus, 
  deny: UiActionStatus, 
  contact: UiActionStatus
}
type BookingWithDisplayOptions = {
  "booking": Booking,
  "showActionsButton": boolean,
  "actions": UiActionsStatus,
  "status": UiBookingStatus,
}


@Component({
  selector: 'app-management-booking-list-table',
  templateUrl: './booking-list-table.component.html',
  styleUrls: ['./booking-list-table.component.scss']
})
export class BookingListTableComponent implements OnInit {

  // API statuses (unchanged)
  static readonly STATUS_CONFIRMED = "confirmed";
  static readonly STATUS_CANCELLED = "cancelled";
  static readonly STATUS_CHECKED_IN = "checked_in";
  static readonly API_STATUSES = [
    BookingListTableComponent.STATUS_CONFIRMED, 
    BookingListTableComponent.STATUS_CANCELLED, 
    BookingListTableComponent.STATUS_CHECKED_IN
  ];
  static fromApiStatusCode(status_code: string): UiBookingStatus {
    if(!BookingListTableComponent.API_STATUSES.includes(status_code)) {
      throw new Error(`Unknown booking status code (not part of API values), was '${status_code}'`);
    }

    return status_code as UiBookingStatus;
  }

  // Extra statuses
  static readonly STATUS_LATE = "confirmed_late";

  // When the actions will be implemented, this will be enabled if the event has not ended yet.
  // Otherwise we will hide the action buttons since the bookings cannot be modified anymore.
  @Input() showActions = true;

  @Input() fullWidth = false;

  @Input() bookings: Booking[];

  public readonly bookingId2BookingWithDisplayOptions: Map<string, BookingWithDisplayOptions> = new Map();

  public readonly BookingListTableComponent = BookingListTableComponent;

  constructor(
    private readonly bookingService: BookingService,
    private readonly modalService: NgbModal,
    private readonly toastService: ToastService,
  ) { }

  private resolveBookingStatus(booking: Booking): UiBookingStatus {
    if(booking.status_code === BookingListTableComponent.STATUS_CONFIRMED && booking.isLate) {
      return BookingListTableComponent.STATUS_LATE;
    } else {
      return BookingListTableComponent.fromApiStatusCode(booking.status_code);
    }
  }

  asDate(timestamp: number) {
    return new Date(timestamp);
  }

  private updateBookingStatus(booking: Booking): void {
    this.bookingId2BookingWithDisplayOptions[booking.id].status = this.resolveBookingStatus(booking);
  }

  private resolveBookingActions(booking: Booking): UiActionsStatus {
    // TODO: disable actions if event is over
    //       Should we automatically mark as Checked-in all the confirmed bookings after the event?
    // NOTE: this can be handled by the showActions input.

    function buildAction(enabled: boolean): UiActionStatus {
      return {
        "enabled": enabled,
        "loading": false,
      }
    }

    if(booking.status_code === BookingListTableComponent.STATUS_CONFIRMED) {
      return {
        "accept": buildAction(true),
        "deny": buildAction(false),  // FIXME: action not yet supported
        "contact": buildAction(false),  // FIXME: action not yet supported
      }
    }

    return {
      "accept": buildAction(false),
      "deny": buildAction(false),
      "contact": buildAction(false),
    }
  }

  private resolveDisplayActionsButton(o: BookingWithDisplayOptions): boolean {
    return Object.entries(o.actions).reduce<boolean>((p, c) => p || c[1].enabled, false);
  }

  private updateBookingActions(booking: Booking): void {
    const displayOptions = this.bookingId2BookingWithDisplayOptions[booking.id];
    displayOptions.actions = this.resolveBookingActions(booking);
    displayOptions.showActionsButton = this.resolveDisplayActionsButton(displayOptions);
  }

  private updateDisplayOptions(booking: Booking): void {
    this.updateBookingStatus(booking);
    this.updateBookingActions(booking);
  }

  ngOnInit(): void {
    for(const booking of this.bookings) {
      const value = {
        "booking": booking,
        "showActionsButton": false,
        "actions": this.resolveBookingActions(booking),
        "status": this.resolveBookingStatus(booking),
      };
      value.showActionsButton = this.resolveDisplayActionsButton(value);
      this.bookingId2BookingWithDisplayOptions[booking.id] = value;
    }
  }

  showBookingNotesModal(booking: Booking) {
    const modalRef = this.modalService.open(BookingNotesModalComponent, {
      windowClass: 'modal',
      size: 'lg',
      centered: true,
    });
    modalRef.componentInstance.booking = booking;
    return modalRef;
  }

  checkIn(booking: Booking) {
    // mark button as loading
    this.bookingId2BookingWithDisplayOptions[booking.id].actions.accept.loading = true;

    this.bookingService.checkinBooking(booking.event_id, booking.code)
    .subscribe({
      next: (b) => {
        // update booking
        booking.status_code = b.status_code;
        booking.status_message = b.status_message;

        // refresh UI status
        this.updateDisplayOptions(booking);

        // notify successful check-in
        this.toastService.success($localize `Guest checked-in successfully`);
      }, 
      error: (err) => {
        // enable button (loading off)
        this.bookingId2BookingWithDisplayOptions[booking.id].actions.accept.loading = false;

        // notify failed check-in
        this.toastService.error($localize `Error: could not check-in guest`);

        // TODO: handle specific errors
        // if(err instanceof CheckInError) {
        // }
      },
    });
  }

}
