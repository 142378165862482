import { Component, OnDestroy, OnInit, Input } from '@angular/core';

import {
  SocialAuthService,
  SocialUser,
  FacebookLoginProvider,
  GoogleLoginProvider,
} from '@abacritt/angularx-social-login';

import { AuthService } from './auth.service';
import { ToastService } from '../shared/components/toasts/toast.service';
import { environment } from '../../environments/environment';
import { Subscription } from 'rxjs';
import { GtagService } from '../gtag/gtag.service';
import { settings } from "../shared/settings";

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit, OnDestroy {
  static MOCK_SOCIAL_USER: SocialUser = {
    provider: 'mock',
    id: '12345',
    firstName: 'John',
    lastName: 'Smith',
    email: 'js@mail.test',
    name: '',
    photoUrl: undefined,
    authToken: '11111',
    idToken: undefined,
    authorizationCode: undefined,
    response: undefined,
  };

  isLoading = false;

  cssClassesGoogle: string[];
  cssClassesBtnGoogle: string[];
  cssClassesFacebook: string[];

  socialAuthSub: Subscription;

  @Input() style: string;

  constructor(
    private socialAuthService: SocialAuthService,
    private authService: AuthService,
    private toastService: ToastService,
    private gtag: GtagService,
  ) {}

  ngOnInit() {
    if (environment.withMocks) {
      return;
    }
    // Passing an arrow function is required here, otherwise the
    // authService may be undefined
    // https://stackoverflow.com/a/57588867/12770477
    this.socialAuthSub = this.socialAuthService.authState.subscribe((socialUser: SocialUser) => {
      this.onAuthStateChange(socialUser);
    });

    switch (this.style) {
      case "home-vertical":
        const cssClassesVertical = ["col-12", "col-md-6", "col-lg-12"];
        this.cssClassesGoogle = cssClassesVertical.concat(["pr-md-1", "pr-lg-0", "py-1"]);
        this.cssClassesFacebook = cssClassesVertical.concat(["pr-md-1", "pr-lg-0", "py-1"]);
        break;
      case "home-horizontal":
        const cssClassesHorizontal = ["col-12", "col-md-6"];
        this.cssClassesGoogle = cssClassesHorizontal.concat(["pr-md-1", "py-1"]);
        this.cssClassesFacebook = cssClassesHorizontal.concat(["pr-md-1", "py-1"]);
        break;
      default:
        const cssClassesDefault = ["col-md-6", "col-lg-8", "col-xl-6"];
        this.cssClassesGoogle = cssClassesDefault.concat(["pr-md-2", "pr-lg-0", "pr-xl-1"]);
        this.cssClassesFacebook = cssClassesDefault.concat(["pl-md-2", "pl-lg-1", "pl-xl-1"]);
        this.cssClassesBtnGoogle = ["mb-3", "mb-md-0", "mb-lg-3", "mb-xl-0"];
        break;
    }
  }

  ngOnDestroy() {
    if (environment.withMocks) {
      return;
    }
    this.socialAuthSub.unsubscribe();
  }

  getGoogleAccessToken() {
    this.socialAuthService.getAccessToken(GoogleLoginProvider.PROVIDER_ID).then(
      token => {
        this.authenticateWithBackend(token, GoogleLoginProvider.PROVIDER_ID);
      }
    );
  }

  onAuthStateChange(socialUser: SocialUser) {
    if (!this.authService.isLoggedIn() && socialUser != null) {
      // A user is trying to log in through SSO
      this.gtag.signInAttemptEvent(socialUser.provider);

      if (socialUser.provider === GoogleLoginProvider.PROVIDER_ID) {
        if (!socialUser.authToken) {
          this.getGoogleAccessToken();
        }
      }
      else {
        this.authenticateWithBackend(socialUser.authToken, socialUser.provider);
      }
    }
  }

  authenticateWithBackend(authToken, provider) {
    this.isLoading = true;
    this.authService
      .logIn(authToken, provider)
      .subscribe(
        () => {
          this.isLoading = false;
        },
        (errorMessage) => {
          this.toastService.error(errorMessage);
          this.isLoading = false;
        }
      );
  }

  signInWithFB(): void {
    // Inject mock User if using mocked data
    if (environment.withMocks) {
      this.onAuthStateChange(AuthComponent.MOCK_SOCIAL_USER);
      return;
    }

    // This will trigger the auth state change
    this.socialAuthService.signIn(FacebookLoginProvider.PROVIDER_ID).catch(err => {
      if (err instanceof TypeError) {
        this.toastService.error(
          $localize `An extension such as Ghostery may be preventing you from authenticating with Facebook.
                     Please deactivate it or add an exception, and reload the page to be able to sign in.
                     If the problem persists, you can contact our support team at ${settings.SUPPORT_EMAIL}.`);
      }
    });
  }
}
