import { Component, Input, OnInit } from '@angular/core';
import { Event } from '../../events/event.model';
import { CheckinFlowService } from '../checkin-flow.service';
import { CheckinModalComponent } from '../checkin-modal/checkin-modal.component';

@Component({
  selector: 'app-checkin-select-event',
  templateUrl: './checkin-select-event.component.html',
  styleUrls: ['./checkin-select-event.component.scss']
})
export class CheckinSelectEventComponent implements OnInit {

  @Input() modal: CheckinModalComponent;
  @Input() events: Event[];

  constructor(
    public checkinFlowService: CheckinFlowService,
  ) { }

  ngOnInit(): void {
  }

  selectEvent(event: Event): void {
    this.checkinFlowService.setEvent(event);
  }

  dismiss(): void {
    this.modal.dismiss();
  }
}
