<div
  [routerLink]="['/events', event.id]"
  class="card shadow shadow-lg--hover mt-0 mt-lg-5 flex-fill"
>
  <div class="position-absolute" style="right: 5px; top: -15px">
    <a
      *ngIf="event.is_takeaway"
      [routerLink]="['/events']"
      [queryParams]="{ is_takeaway: true }"
      (click)="$event.stopPropagation()"
      ><i class="fa fa-cube m-1 p-3 bg-info shadow rounded text-white"></i
    ></a>
    <a
      *ngIf="event.hot"
      title="hot deal"
      [routerLink]="['/events']"
      [queryParams]="{ hot: true }"
      (click)="$event.stopPropagation()"
      ><i class="fa fa-fire m-1 p-3 bg-danger shadow rounded text-white"></i
    ></a>
  </div>
  <img
    class="card-img-top pb-4"
    style="min-height: 200px"
    src="{{ event.image_url }}"
    alt=""
  />
  <div class="card-body pt-0">
    <div class="position-relative float-right" style="top: -50px; right: -10px">
      <div
        class="rd-icon icon icon-shape bg-secondary rounded-circle text-white shadow"
      >
        <img src="{{ event.icon_url }}" alt="{{ event.icon }}" />
      </div>
    </div>
    <div class="pb-4">
      <div class="pl-4 pl-md-0 pl-lg-4">
        <div class="mb-3">
          <a
            *ngFor="let tag of event.tags"
            class="text-dark"
            [routerLink]="['/events']"
            [queryParams]="{ category: tag.slug }"
            (click)="$event.stopPropagation()"
          >
            #{{ tag.name.toLocaleLowerCase() }}
          </a>
        </div>
        <h4 class="card-title text-primary mt-2 mb-0">
          {{ event.place.name }}
        </h4>
        <small class="card-subtitle text-muted">
          <i class="fa fa-map-marker pr-2" aria-hidden="true"></i
          >{{ event.place.address }}
        </small>
        <div class="mt-4 d-none d-lg-block">
          <h5 class="mb-0">{{ event.name }}</h5>
          <p
            class="text-muted mb-0"
            [innerHTML]="event.short_description | safeHtml"
          ></p>
        </div>
        <div class="row pr-4 mt-2">
          <p class="col mx-2 text-right px-0">
            <del
              *ngIf="event.nextBookingPrice() !== event.original_price"
              class="text-muted"
              >({{ event.original_price }} €)</del
            >
          </p>
          <p class="display-3 col-auto text-right px-0">
            <!--
              Show price you would get if you booked the event only if it is bookable,
              otherwise show current price.
              This matters only for events that are full or closed.
              If it is too early the base price will be shown in any case.
            -->
            {{ event.nextBookingPrice() }} €
          </p>
        </div>
        <p class="text-center pr-4">
          <span
            *ngIf="
              event.can_be_booked &&
              event.next_deals !== undefined &&
              event.next_deals.length > 0 &&
              event.people_nb_to_next_deal > 2
            "
            class="lead text-primary border border-primary rounded-lg py-2 px-4 m-2"
            ngbTooltip="Want to save more? Share this deal!"
            placement="bottom"
            i18n="compact discount reminder"
          >
            -{{ event.people_nb_to_next_deal }}
            <i class="fa fa-user" aria-hidden="true"></i> to
            {{ event.current_price - event.next_deals[0].amount }} €
          </span>
          <span
            *ngIf="
              event.can_be_booked &&
              event.next_deals !== undefined &&
              event.next_deals.length > 0 &&
              event.people_nb_to_next_deal <= 2
            "
            class="lead text-white bold bg-danger border border-dark rounded-lg shadow py-2 px-4 m-2"
            ngbTooltip="Next deal almost unlocked, share it to save {{
              event.next_deals[0].amount
            }} €!"
            placement="bottom"
            i18n="compact discount reminder"
          >
            -{{ event.people_nb_to_next_deal }}
            <i class="fa fa-user" aria-hidden="true"></i> to
            {{ event.current_price - event.next_deals[0].amount }} €
          </span>
        </p>
      </div>
    </div>
  </div>
  <div class="p-4 w-100 position-absolute clearfix" style="bottom: 0px" (click)="$event.stopPropagation()">
    <div class="float-left">
      <a [routerLink]="['/events', event.id]" class="text-warning" i18n
        >Learn more</a
      >
    </div>
    <a class="float-right text-primary" (click)="shareEvent()"
      ><i class="fa fa-share-alt" aria-hidden="true"></i
    ></a>
  </div>
</div>
