<!-- WARNING: only single day events are supported in this view -->

<form [formGroup]="form" (change)="onFilterChange($event)">
    <div class="form-group">
        <label for="scheduleSelect" i18n="schedule filter | schedule filter title">Filter by Schedule</label>
        <select class="form-control" id="scheduleSelect" formControlName="schedule">
            <option [ngValue]="null" i18n="schedule filter | all schedules option">All schedules</option>
            <option *ngFor="let date of dates" [ngValue]="date.valueOf()">{{ date | date: 'shortTime' }}</option>
        </select>
    </div>
</form>

<div *ngIf="bookings.length > 0; else noBookings">
    <div class="px-0 px-md-5" style="margin-top: 5rem;" *ngFor="let dateTablesEntry of tablesDateList">
        <div [class.d-none]="selectedSchedule != null && selectedSchedule !== dateTablesEntry.date.valueOf()">
            <span class="small text-muted" style="white-space: nowrap;" i18n="bookings table - schedule">Schedule <em>{{ dateTablesEntry.date | date: 'shortTime' }}</em></span>
            <hr class="mt-0 mb-5" />
            <diV *ngIf="dateTablesEntry.tables.size === 0" i18n>There are no bookings at {{ dateTablesEntry.date | date: 'shortTime' }}.</diV>
            <div class="mb-5" *ngFor="let tableJoiningCode of dateTablesEntry.tables">
                <app-management-table-header [table]="tablesByJoiningCode.get(tableJoiningCode)"></app-management-table-header>
                <div *ngIf="tableChoicesSummaryByTable.get(tableJoiningCode).menu">
                    <h6 class="small ml-3" i18n>Table choices summary</h6>
                    <div class="table-responsive mb-3">
                        <table class="table table-striped text-center">
                            <thead class="bg-primary text-white">
                                <th scope="col" class="fit" i18n="bookings table summary - menu section title">Section</th>
                                <th scope="col" class="fit" i18n="bookings table - menu choice name">Choice</th>
                                <th scope="col" class="fit" i18n="bookings table - menu choice count">Quantity</th>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let sectionChoices of tableChoicesSummaryByTable.get(tableJoiningCode).choicesBySection">
                                    <tr *ngFor="let choice of sectionChoices.choices" scope="row" class="align-middle">
                                        <th class="fit align-middle pl-4" style="white-space: nowrap;">{{ sectionChoices.section.name }}</th>
                                        <td class="fit align-middle" style="white-space: nowrap;">
                                            {{ choice.choice.name }}
                                            <span *ngIf="choice.hasNotes" class="text-danger">*</span>
                                        </td>
                                        <td class="fit align-middle pr-4">{{ choice.quantity }}</td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                        <p class="small">
                            <span class="text-danger">*</span>
                            <span i18n="bookings table summary - highlight choice might have notes">This choice might have some booking notes</span>
                        </p>
                    </div>
                </div>
                <div *ngIf="event.is_takeaway || bookingsHaveNotes(bookingsByTable.get(tableJoiningCode)); else tableNoNotes">
                    <h6 class="small ml-3" i18n>Remarks</h6>
                    <div class="table-responsive">
                        <table class="table table-striped text-center">
                            <thead class="bg-primary text-white">
                                <th scope="col" class="fit" i18n="bookings table - check-in code">Code</th>
                                <th scope="col" class="fit" i18n="bookings table - name">Name</th>
                                <th scope="col" class="fit w-50" i18n="bookings table - notes">Notes</th>
                            </thead>
                            <tbody>
                                <ng-container *ngFor="let booking of bookingsByTable.get(tableJoiningCode)" scope="row" class="align-middle">
                                    <tr *ngIf="event.is_takeaway || booking.notes">
                                        <th class="fit align-middle pl-4" style="white-space: nowrap;">{{ booking.code }}</th>
                                        <td class="fit align-middle" style="white-space: nowrap;">{{ booking.first_name }} {{ booking.last_name }}</td>
                                        <td class="fit align-middle pr-4">
                                            <pre class="my-0 mx-auto text-left d-inline-block" *ngIf="booking.notes; else noNotes">{{ booking.notes?.trim() }}</pre>
                                            <ng-template #noNotes><span class="badge" [class.badge-info]="!event.menu_preference_required" [class.badge-danger]="event.menu_preference_required" i18n="no notes in booking">No notes</span></ng-template>
                                        </td>
                                    </tr>
                                </ng-container>
                            </tbody>
                        </table>
                    </div>
                </div>
                <ng-template #tableNoNotes>
                    <div class="m-3"><p class="badge-success py-2 px-3" i18n="no notes in table">No notes</p></div>
                </ng-template>
            </div>
        </div>
    </div>

    <ng-container *ngIf="choicesSummary.menu">
        <hr class="my-5" />

        <div>
            <h4 i18n>Orders summary</h4>
            <p i18n>This table summarizes all the orders.</p>
            <div class="table-responsive mb-3 px-0 px-md-5 mt-4">
                <table class="table table-striped text-center">
                    <thead class="bg-primary text-white">
                        <th scope="col" class="fit" i18n="bookings table summary - menu section title">Section</th>
                        <th scope="col" class="fit" i18n="bookings table - menu choice name">Choice</th>
                        <th scope="col" class="fit" i18n="bookings table - menu choice count">Quantity</th>
                    </thead>
                    <tbody>
                        <!-- TODO: show entries for each participantChoices summary (section by section) -->
                        <ng-container *ngFor="let sectionChoices of choicesSummary.choicesBySection">
                            <tr *ngFor="let choice of sectionChoices.choices" scope="row" class="align-middle">
                                <th class="fit align-middle pl-4" style="white-space: nowrap;">{{ sectionChoices.section.name }}</th>
                                <td class="fit align-middle" style="white-space: nowrap;">
                                    {{ choice.choice.name }}
                                    <span *ngIf="choice.hasNotes" class="text-danger">*</span>
                                </td>
                                <td class="fit align-middle pr-4">{{ choice.quantity }}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
                <p class="small">
                    <span class="text-danger">*</span>
                    <span i18n="bookings table summary - highlight choice might have notes">This choice might have some booking notes</span>
                </p>
            </div>
        </div>
    </ng-container>
</div>
<ng-template #noBookings>
    <div i18n>There are no bookings for this event.</div>
</ng-template>