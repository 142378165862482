<!-- Designed to be enclosed in a col-md-4 div (contained in a container and row div) -->
<div class="card shadow border-0">
  <div class="card-body py-3">
    <h2 class="border-bottom py-1" i18n>Find your deal!</h2>
    <form [formGroup]="searchForm" (ngSubmit)="onSearch()">
      <div class="form-group">
        <label for="locationInput" i18n>Where?</label>
        <div class="input-group input-group-alternative">
          <app-location-input
            id="locationInput"
            class="w-100"
          ></app-location-input>
        </div>
      </div>
      <div class="form-group">
        <label for="dateInput" i18n>When?</label>
        <div class="input-group input-group-alternative">
          <div class="input-group-prepend">
            <span class="input-group-text"
              ><i class="ni ni-calendar-grid-58"></i
            ></span>
          </div>
          <input
            id="dateInput"
            type="text"
            name="dp"
            class="form-control form-control-lg datepicker"
            placeholder="Select date"
            i18n-placeholder="
              datepicker placeholder|Placeholder for date selection
              input
            "
            formControlName="when"
            #d="ngbDatepicker"
            (click)="d.toggle()"
            ngbDatepicker
          />
        </div>
      </div>
      <div class="form-group">
        <label for="tagsInput" i18n>What?</label>
        <div class="input-group input-group-alternative">
          <!-- FIXME CSS quickfix: set padding + height; select not handled by input.form-control -->
          <select
            class="form-control form-control-lg custom-select"
            id="tagsInput"
            style="padding: 0.875rem 1rem; height: calc(3.625rem + 2px)"
            formControlName="what"
          >
            <option value="any"><span class="food-icon" selected>⁉️</span> Any</option>
            <option *ngFor="let tag of tags" [value]="tag.slug">
              <span class="food-icon" [innerHTML]="tag.icon"></span> {{ tag.name }}
            </option>
          </select>
        </div>
      </div>
      <button type="submit" class="btn btn-primary float-right" i18n>Submit</button>
    </form>
  </div>
</div>
