/// <reference types="@types/stripe-v3" />

import {Component, ElementRef, Inject, Input, LOCALE_ID, OnInit, ViewChild} from '@angular/core';
import {AuthService} from 'src/app/auth/auth.service';
import {User} from 'src/app/auth/user.model';
import {Event} from '../../events/event.model';
import {BookingService} from 'src/app/booking-flow/booking.service';
import {BookingFlowState} from '../booking-flow-state.model';
import {BookingFlowService} from '../booking-flow.service';
import {Payment} from '../payment.model';
import {FormControl, UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';
import {environment} from '../../../environments/environment';
import {ToastService} from 'src/app/shared/components/toasts/toast.service';
import {GtagService} from 'src/app/gtag/gtag.service';
import {PREFERRED_COUNTRIES} from 'src/app/shared/utils/ng2-tel-input';

@Component({
  selector: 'app-booking-payment',
  templateUrl: './booking-payment.component.html',
  styleUrls: ['./booking-payment.component.scss'],
})
export class BookingPaymentComponent implements OnInit {
  @Input() event: Event;
  @Input() state: BookingFlowState;

  user: User;

  timer = '4:10';
  payment: Payment;

  error = false;
  loading = false;
  cardFormIsValid = false;

  isParticipantChoicesCollapsed = true;

  paymentForm: UntypedFormGroup;

  ngTelInput: any;
  countryCode: string;
  PREFERRED_COUNTRIES = PREFERRED_COUNTRIES;

  // Stripe payment
  @ViewChild('cardElement') cardElement: ElementRef;
  stripe: stripe.Stripe;
  card: stripe.elements.Element;
  cardErrors;

  constructor(
    private fb: UntypedFormBuilder,
    private toastService: ToastService,
    private authService: AuthService,
    private bookingService: BookingService,
    private bookingFlowService: BookingFlowService,
    @Inject(LOCALE_ID) public locale: string,
    private gtagService: GtagService,
  ) {}

  ngOnInit(): void {
    this.user = this.authService.user.value;

    if(this.locale == "fr" || this.locale == "it") {
      this.countryCode = this.locale;
    } else {
      this.countryCode = "us";
    }

    this.paymentForm = this.fb.group({
      name: new FormControl<string | null>({
        value: this.user.full_name().toUpperCase(),
        disabled: true,
      }),
      email: new FormControl<string | null>({
        value: this.user.email,
        disabled: true,
      }),
      phoneNumber: new FormControl<string | null>('', {validators: [Validators.required]}),
      wantsToSubscribeToNewsletter: new FormControl<boolean | null>(true),
    });

    this.initPayment();

    if(this.user.phone_number) {
      this.setPhoneNumber(this.user.phone_number);
    }

    // NOTE: must be done after setPhoneNumber call (see above)
    // the call to setPhoneNumber above will trigger this callback
    // and ngTelInput will still be undefined
    this.paymentForm.controls.phoneNumber.valueChanges.subscribe(phoneNumber => {
      // As explained in the intl-tel-input documentation, the autoFormat option
      // was removed so we have to run validation on each keypress
      this.formatAndValidatePhoneNumber(phoneNumber);
    });
  }

  /* ************ *
   * Phone number *
   * ************ */

  // To display phone numbers, we are relying on the ng2-tel-input Angular
  // library, which itself leverages the intl-tel-input library
  // Information can be found here:
  // https://github.com/gauravsoni119/ng2-tel-input/blob/master/src/ng2-tel-input.ts

  setPhoneNumber(phone: string) {
    this.paymentForm.controls.phoneNumber.setValue(phone);
  }

  getNg2TelInput(telInput: any) {
    // Bind to the intlTelInputObject @Output of ng2-tel-input
    // to get a reference to the underlying object on init
    this.ngTelInput = telInput;
  }

  formatAndValidatePhoneNumber(phoneNumber: string) {
    // Format the phone number and adapts the displayed errors
    // Note: calling setNumber is the recommended way to format the
    // phone number in the format of the selected country
    // Check: https://github.com/jackocnr/intl-tel-input/issues/462#issuecomment-240455105
    this.ngTelInput.setNumber(phoneNumber);
    this.setNgTelInputError(this.ngTelInput.isValidNumber());
  }

  onCountryChange() {
    // Bind on the change of country
    // We need to rerun the validation since the expected format has changed
    this.formatAndValidatePhoneNumber(this.paymentForm.controls.phoneNumber.value);
  }

  setNgTelInputError(isValid: boolean) {
    // Utility function to set or remove error related to ng2TelInput
    // validation without interferring with errors from other validators
    let errors = this.paymentForm.controls.phoneNumber.errors;
    if(errors == null) {
      if(!isValid) {
        errors = {"incorrect": true};
      }
    } else {
      if(isValid) {
        delete errors["incorrect"];
        if(Object.keys(errors).length === 0) {
          errors = null;
        }
      }
    }

    this.paymentForm.controls.phoneNumber.setErrors(errors);
  }

  /* ******* *
   * Payment *
   * ******* */

  initPayment() {
    this.bookingService
      .createPayment(
        this.state.selectedSchedule,
        this.state.nbSelectedParticipants,
        this.state.notes,
        this.state.participantChoices,
      )
      .subscribe((payment: Payment) => {
        this.payment = payment;
        this.initStripeElements();
        this.error = false;
      }, (error) => {
        this.error = true;
        this.gtagService.exception("Could not init payment");
      });
  }

  initStripeElements() {
    this.stripe = Stripe(environment.STRIPE_API_KEY);
    const elements = this.stripe.elements();

    const appearance = {
      style: {
        base: {
          ':disabled': {
            'color': '#343A40',
          }
        }
      }
    };
    this.card = elements.create('card', appearance);
    this.card.mount(this.cardElement.nativeElement);

    this.card.addEventListener('change', event => {
      if (event.complete) {
        this.cardFormIsValid = true;
      } else {
        this.cardFormIsValid = false;
      }

      if (event.error) {
        this.cardErrors = event.error.message;
      } else {
        this.cardErrors = null;
      }
    });
  }

  disableForm() {
    this.paymentForm.disable();
    this.card.update({ disabled: true });
  }

  enableForm() {
    this.paymentForm.enable();
    this.card.update({ disabled: false });
  }

  async processPayment() {
    this.loading = true;
    this.disableForm();

    this.bookingService
      .patchPayment(this.payment.id, {
        status: 'pending',
        phone_number: this.ngTelInput.getNumber(),
        table_id: this.state.selectedTable?.id,
      })
      .subscribe((payment: Payment) => {
        this.payment = payment;
        this.subscribeToNewsletter();
        this.processStripeElements();
        this.error = false;
      }, (error) => {
        this.error = true;
        this.loading = false;
        this.enableForm();
        this.gtagService.exception('Could not process payment (set payment status as pending)');
      });
  }

  async subscribeToNewsletter() {
    // If the user is not subscribed to our newsletter and wants to subscribe
    // register his subscription
    if (!this.user.subscribed_to_newsletter) {
      const wantsToSubscribeToNewsletter = this.paymentForm.get('wantsToSubscribeToNewsletter').value;
      if (wantsToSubscribeToNewsletter) {
        this.authService
          .updateUserProfile({subscribed_to_newsletter: wantsToSubscribeToNewsletter})
          .subscribe((user: User) => {
            this.user = user;
          });
      }
    }
  }

  async processStripeElements() {

    // Convert payment information collected by elements into a Source object
    // that can be safely passed to our server to use in an API call
    // https://stripe.com/docs/js/tokens_sources/create_source
    const sourceRes: stripe.SourceResponse = await this.stripe.createSource(
      this.card
    );

    if (sourceRes.error) {
      // Inform the customer that there was an error
      // (for instance the user did not fill properly the card number field)
      const cardErrors = sourceRes.error.message;
      return;
    }

    // Confirm the PaymentIntent by providing card details
    // https://stripe.com/docs/js/payment_intents/confirm_card_payment
    /*
    There's a risk of the customer closing the window before callback
    execution. Set up a webhook or plugin to listen for the
    payment_intent.succeeded event that handles any business critical
    post-payment actions.
    */
    const paymentIntentRes: stripe.PaymentIntentResponse = await this.stripe.confirmCardPayment(
      this.payment.client_secret,
      {
        payment_method: {
          card: this.card,
          billing_details: {
            name: this.user.full_name(),
          },
        },
      }
    );

    if (paymentIntentRes.error) {
      // Inform the customer that there was an error
      this.cardErrors = paymentIntentRes.error.message;
      this.loading = false;
      this.enableForm();
      return;
    }

    // The payment has been processed!
    const paymentIntent = paymentIntentRes.paymentIntent;
    if (
      paymentIntent.status === 'succeeded' ||
      paymentIntent.status === 'requires_capture'
    ) {
      // Show a success message to the customer
      this.toastService.success($localize `Payment has been processed successfully!`);

      // Call the backend to update Payment state
      this.bookingService
        .patchPayment(this.payment.id, {
          status: 'authorized',
          table_id: this.state.selectedTable?.id,
        })
        .subscribe((payment: Payment) => {
          // NOTE: using the payment id as transaction id.
          this.state.transactionId = payment.id;
          this.state.isNewTable = !this.state.selectedTable;
          if(this.state.isNewTable) {
            // table created by backend, it is the current table associated to the reservation
            this.state.selectedTable = payment.booking.table;
          }
          this.nextStep();
        }, (error) => {
          // The problem is on our side and we already accepted the payment
          // Go to the next step
          this.gtagService.exception("Could not authorize payment (set payment status as authorized)");
          this.nextStep();
        }, () => {
          this.loading = false;
          this.enableForm();
        });
    }
  }

  // Booking flow routing
  nextStep() {
    this.bookingFlowService.nextStep();
  }

  previousStep() {
    this.bookingFlowService.previousStep();
  }
}
