import { Params } from '@angular/router';

/**
 * Query parameters for GET /events/
 *
 * See https://studio-ws.apicur.io/sharing/988d5d5b-8f5c-408d-816d-67d87d20d42c#tag/Deals/paths/~1events~1/get
 */
export class EventsQuery {
  constructor(
    public lat?: number,
    public lng?: number,
    public dist?: number,
    public fromDate?: string,
    public toDate?: string,
    public category?: string,
    public hot?: boolean,
    public is_takeaway?: boolean,
  ) {}

  /**
   * Parses the URL query parameters into an EventsQuery object.
   * @param params URL query parameters
   * @returns parsed EventsQuery object
   */
  public static fromQueryParams(params: Params): EventsQuery {
    return new EventsQuery(
      +params.lat,
      +params.lng,
      params.dist !== undefined ? +params.dist : undefined,
      params.fromDate,
      params.toDate,
      params.category,
      params.hot,
      params.is_takeaway,
    );
  }

  public toQueryParams() {
    const queryParams: Params = {};

    if (this.fromDate) {
      queryParams.fromDate = this.fromDate;
    }

    if (this.toDate) {
      queryParams.toDate = this.toDate;
    }

    if (this.lat && this.lng) {
      queryParams.lat = this.lat.toFixed(6);
      queryParams.lng = this.lng.toFixed(6);
      queryParams.dist = this.dist;
    }

    if (this.category && this.category !== 'any') {
      queryParams.category = this.category;
    }

    if (this.hot) {
      queryParams.hot = true;
    }

    return queryParams;
  }
}
