import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { CookieService } from '../cookie.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieSettingsComponent } from '../settings/settings.component';

@Component({
  selector: 'app-cookie-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss']
})
export class CookieBannerComponent implements OnInit, OnDestroy {

  private userConsentSubscription: Subscription = undefined;
  isUserConsentRequired: boolean = false;

  constructor(
    private cookieService: CookieService,
    private modalService: NgbModal,
  ) { }

  ngOnInit(): void {
    this.userConsentSubscription = this.cookieService.isUserConsentRequired.subscribe(
      isUserConsentRequired => {
        this.isUserConsentRequired = isUserConsentRequired;
        console.debug(`DEBUG[cookie/banner]: isUserConsentRequired=${isUserConsentRequired}`);
      }
    );
  }

  ngOnDestroy(): void {
    this.userConsentSubscription.unsubscribe();
  }

  consentAll(): void {
    this.cookieService.consentAll();
  }

  showSettingsModalView() {
    const modalRef = this.modalService.open(CookieSettingsComponent, {
      windowClass: 'modal',
      size: 'lg',
      centered: true,
    });

    // Show current consent in settings
    modalRef.componentInstance.userConsent = this.cookieService.userConsent.value;

    modalRef.result.then(result => {
      console.debug(`DEBUG[cookie/banner]: showSettingsModalView(), saved with result=${JSON.stringify(result)}`);
      this.cookieService.consent(result);
    }).catch(reason => {
      console.debug(`DEBUG[cookie/banner]: showSettingsModalView(), dismissed with reason=${reason}`);
    })
  }
}
