<div xmlns="http://www.w3.org/1999/html">
    <div class="heading text-uppercase font-weight-bold mt-4 mb-1" i18n="Menu Choice booking flow step title">Menu Choices</div>
    <p i18n="Menu Choice booking flow step subtitle">Select per each participants your preferences.</p>
    <app-participants-selection [enabled]="true" [participantChoices]="state.participantChoices" [tableParticipantChoices]="state.selectedTable?.participant_choices"></app-participants-selection>

    <ng-container *ngIf="state.selectedTable">
      <div class="heading text-uppercase font-weight-bold mt-4 mb-1" i18n="Menu Choice booking flow group choices title">Table Choices</div>
      <p i18n="Menu Choice booking flow group choices subtitle">You can see the choices of the people in your group by clicking the button below.</p>

      <div class="text-center">
        <button class="btn btn-sm btn-info px-5 py-3 mb-4 mx-auto" type="button"
          (click)="toggleTableChoicesViewCollapsed()"
          [attr.aria-expanded]="!isTableChoicesViewCollapsed" aria-controls="tableChoicesView"
          aria-label="Toggle table participant choices view">
            <ng-container i18n>See choices of people in your group</ng-container>
            <span class="btn-inner--icon ml-2" *ngIf="isTableChoicesViewCollapsed"><i class="fa fa-caret-down"></i></span>
            <span class="btn-inner--icon ml-2" *ngIf="!isTableChoicesViewCollapsed"><i class="fa fa-caret-up"></i></span>
        </button>
      </div>

      <div id="tableChoicesView" *ngIf="!isTableChoicesViewCollapsed">
        <app-participants-selection [enabled]="false" [participantChoices]="state.selectedTable?.participant_choices"></app-participants-selection>
      </div>
    </ng-container>

    <hr />
    <div class="container">
      <div class="clearfix">
        <div class="col-6 col-md-4 float-left">
            <button
                class="btn btn-link text-primary btn-lg w-100"
                type="button"
                (click)="previousStep()"
                i18n="back"
                [disabled]="loading"
            >
                <i class="fa fa-arrow-left pr-2" aria-hidden="true"></i>Back
            </button>
        </div>
        <div class="col-6 col-md-4 float-right">
          <button
            class="btn btn-primary btn-lg w-100"
            type="button"
            [disabled]="isInvalidSelection()"
            (click)="nextStep()"
            i18n="next"
          >
            Next<i class="fa fa-arrow-right pl-2" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
</div>