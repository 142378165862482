import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Event } from '../events/event.model';
import { BookingFlowService } from './booking-flow.service';
import { BookingFlowState } from './booking-flow-state.model';

@Component({
  selector: 'app-booking-flow',
  templateUrl: './booking-flow.component.html',
  styleUrls: ['./booking-flow.component.scss'],
})
export class BookingFlowComponent implements OnInit, OnDestroy {
  @Input() event: Event;

  private stateSub: Subscription;
  public state: BookingFlowState;

  constructor(private bookingFlowService: BookingFlowService) {}

  ngOnInit(): void {
    this.stateSub = this.bookingFlowService
      .forEvent(this.event)
      .stateSource.subscribe((state) => {
        this.state = state;
      });
  }

  ngOnDestroy(): void {
    this.stateSub.unsubscribe();
  }

  // Booking flow routing
  nextStep() {
    this.bookingFlowService.nextStep();
  }

  previousStep() {
    this.bookingFlowService.previousStep();
  }

  restart() {
    this.bookingFlowService.restart();
  }
}
