<div class="container py-4">
  <form [formGroup]="filterForm">
    <div class="row">
      <div class="col-lg-4">
        <div class="form-group">
          <label for="locationInput" i18n>Where?</label>
          <div class="input-group input-group-alternative">
            <app-location-input
              id="locationInput"
              [lat]="eventsQuery.lat"
              [lng]="eventsQuery.lng"
              class="w-100"
            ></app-location-input>
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-3 col-6">
        <div class="form-group">
          <label for="fromDateInput" i18n>From when?</label>
          <div class="input-group input-group-alternative">
            <div class="input-group-prepend">
              <span class="input-group-text py-0"
                ><i class="ni ni-calendar-grid-58"></i
              ></span>
            </div>
            <input
              class="form-control form-control-sm datepicker"
              id="fromDateInput"
              placeholder="Select date"
              i18n-placeholder="
                datepicker placeholder|Placeholder for date selection
                input
              "
              name="dp1"
              formControlName="fromDate"
              ngbDatepicker
              #d1="ngbDatepicker"
              (click)="d1.toggle()"
              type="text"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-3 col-6">
        <div class="form-group">
          <label for="toDateInput" i18n>To when?</label>
          <div class="input-group input-group-alternative">
            <div class="input-group-prepend">
              <span class="input-group-text py-0"
                ><i class="ni ni-calendar-grid-58"></i
              ></span>
            </div>
            <input
              class="form-control form-control-sm datepicker"
              id="toDateInput"
              placeholder="Select date"
              i18n-placeholder="
                datepicker placeholder|Placeholder for date selection
                input
              "
              name="dp2"
              formControlName="toDate"
              ngbDatepicker
              #d2="ngbDatepicker"
              (click)="d2.toggle()"
              type="text"
            />
          </div>
        </div>
      </div>
      <div class="col-lg-2 col-md-3">
        <div class="form-group">
          <label for="tagsInput" i18n>What?</label>
          <div class="input-group input-group-alternative">
            <select
              class="form-control form-control-sm custom-select"
              id="tagsInput"
              formControlName="category"
            >
              <option value="any" selected>
                <span class="food-icon">⁉️</span> Any
              </option>
              <option *ngFor="let tag of tags" [value]="tag.slug">
                <span class="food-icon" [innerHTML]="tag.icon"></span> {{ tag.name }}
              </option>
            </select>
          </div>
          <small class="text-muted">Define your preferences</small>
        </div>
      </div>
      <div class="col-lg-2 col-md-3">
        <div class="form-group h-100 d-flex">
          <div class="custom-control custom-checkbox my-auto pt-lg-3">
            <input
              type="checkbox"
              class="custom-control-input"
              id="hot"
              formControlName="hot"
            />
            <label class="custom-control-label" for="hot">Hot deals only</label>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
