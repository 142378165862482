<main>
  <div class="position-relative">
    <section class="section section-lg section-shaped jumbotron">
      <div class="container py-4">
        <div class="px-0 row">
          <div class="col-md-9 offset-md-3">
            <h1 i18n="account greeting|Greeting a user in the account page">
              Welcome {{ authService.user.value.first_name }}!
            </h1>
          </div>
        </div>
      </div>
      <!-- SVG separator -->
      <div class="separator separator-bottom separator-skew">
        <svg
          x="0"
          y="0"
          viewBox="0 0 2560 100"
          preserveAspectRatio="none"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <polygon class="fill-white" points="2560 0 2560 100 0 100"></polygon>
        </svg>
      </div>
      <!-- Quickfix for background transparency -->
      <div class="jumbotron-bg"></div>
    </section>
    <section class="section">
      <div
        class="container"
        style="position: relative; top: -200px; z-index: 2"
      >
        <div class="row">
          <div class="p-0 pt-md-5 col-md-3 border-0 mb-3 mx-md-0 mx-4" id="sidebar-account">
            <div class="list-group shadow">
              <a
                routerLink="." fragment="profile"
                class="list-group-item list-group-item-action"
                [class.active]="currentPage === 'profile'"
                i18n
              >Profile</a>
              <a
                routerLink="." fragment="bookings"
                class="list-group-item list-group-item-action"
                [class.active]="currentPage === 'bookings'"
                i18n
              >Reservations</a>
            </div>
          </div>
          <div class="card shadow border-0 col-md-9" style="z-index: 10">
            <div class="card-body py-3" [ngSwitch]="currentPage">
              <app-profile *ngSwitchCase="'profile'"></app-profile>
              <app-bookings *ngSwitchCase="'bookings'"></app-bookings>
            </div>
            <div class="m-5">
              <hr class="mx-auto" style="max-width: 100px" />
            </div>
            <div class="mb-5"><small i18n>
              If you wish to permanently delete your account, please send an email to <a [href]="'mailto:' + SUPPORT_EMAIL">{{ SUPPORT_EMAIL }}</a>
            </small></div>
          </div>
        </div>
      </div>
    </section>
  </div>
</main>
