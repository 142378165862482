<div *ngFor="let entry of schedulesByDate">
    <label class="d-block mt-3 mb-0">{{ entry.scheduleGroupTitle }}</label>
    <div class="row mx-0">
        <div *ngFor="let s of entry.scheduleGroup" class="col-4 col-md-3 px-1">
            <button class="btn w-100 my-1 {{ getCssClasses(s.schedule) }}"
                [disabled]="disabled || !scheduleStatesMap[s.schedule.id].available"
                (click)="selectSchedule(s.schedule)">
                {{ s.schedule.start_datetime | date: 'shortTime' }}
            </button>
        </div>
    </div>
</div>