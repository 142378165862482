import { Injectable, Inject, NgZone } from '@angular/core';
import { Gtag, EventParams, CustomParams, GtagConfigToken, GtagConfig, CheckoutAction, ItemAction, ViewItemListAction, ShareItemAction } from './gtag.model';
import { UserEnabledService } from '../cookie/user-enabled-service.model';
import { CookieService } from '../cookie/cookie.service';
import { GOOGLE_ANALYTICS_CONSENT_KEY } from './gtag.constants';

@Injectable({
  providedIn: 'root',
})
export class GtagService extends UserEnabledService {
  private gtag: Gtag = undefined; // not yet initialized
  
  constructor(
    cookieService: CookieService,
    @Inject(GtagConfigToken) private config: GtagConfig, 
    private zone: NgZone,
  ) {
    super(cookieService);
    console.debug("DEBUG[gtag/gtag.service]: registered Google Analytics service (inactive - waiting for consent)");
  }

  getConsentKey(): string {
    return GOOGLE_ANALYTICS_CONSENT_KEY;
  }

  private injectGtagScript() {
    const script = document.createElement('script');

    script.src = 'https://www.googletagmanager.com/gtag/js?id=' + this.config.targetId;
    script.type = 'text/javascript';
    script.async = true;

    document.head.appendChild(script);

    (window as any).dataLayer = (window as any).dataLayer || [];
    function gtag(...args) { (window as any).dataLayer.push(arguments); }

    gtag('js', new Date());
    gtag('config', this.config.targetId, { send_page_view: false, ...this.config.configInfo });
    ('setParams' in this.config) && gtag('set', this.config.setParams);
    ('moreIds' in this.config) && this.config.moreIds.forEach(id => gtag('config', id));

    (window as any).gtag = gtag;
    return gtag;
  }

  activate(): void {
    if (!('gtag' in window)){
      // gtag already active
      this.gtag = this.injectGtagScript();
      this.disable(false);
    }

    console.debug("DEBUG[gtag/gtag.service]: Google Analytics activated");
    this.isActive = true;
  }

  /** Disables the analytics for the given measurement id. Defaults to the targetId from the global confguration when unspecified.
   * @see: https://developers.google.com/analytics/devguides/collection/gtagjs/user-opt-out */
  public disable(value: boolean = true, id: string = this.config.targetId) {
    window[`ga-disable-${id}`] = value;
  }
  
  /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/setting-values */
  public set(params: CustomParams): void {
    if(!this.isActive) {
      throw new Error("ERROR[gtag/gtag.service]: Cannot set gtag properties, GtagService is NOT active.");
    }
    return this.gtag('set', params);
  }

  /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/events */
  public event(action: string, params?: EventParams): Promise<void> {
    if(!this.isActive) {
      console.debug("DEBUG[gtag/gtag.service]: event ignored, GtagService is NOT active.");
      return new Promise((resolve, reject) => {resolve()});
    }

    // Wraps the event call into a Promise
    return this.zone.runOutsideAngular(() => new Promise((resolve, reject) => {
      try { 
        // Triggers a 1s time-out timer 
        const tmr = setTimeout( () => reject( new Error('gtag call timed-out')), this.config.timeout || 10000 );
        // Performs the event call resolving with the event callback
        this.gtag('event', action, { ...params, event_callback: () => { clearTimeout(tmr); resolve(); }}); } 
      // Rejects the promise on errors
      catch(e) { reject(e); }
    }));
  }

  /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/pages */
  public pageView(page_title?: string, page_path?: string, page_location?: string) { 
    return this.event('page_view', { page_title, page_location, page_path }); 
  }

  /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/exceptions */
  public exception(description?: string, fatal?: boolean) { 
    return this.event('exception', { description, fatal }); 
  }

  /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/user-timings */
  public timingComplete(name: string, value: number, event_category?: string, event_label?: string) { 
    return this.event('timing_complete', { name, value, event_category, event_label }); 
  }

  /** @see: https://developers.google.com/analytics/devguides/collection/gtagjs/screens */
  public screenView(app_name: string, screen_name: string, app_id?: string, app_version?: string, app_installer_id?: string) { 
    return this.event('screen_view', { app_name, screen_name, app_id, app_version, app_installer_id }); 
  }

  // --------------------------------------------------------------------------
  // # Recommended Events - https://support.google.com/analytics/answer/9267735
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // ## Generic events (all properties)
  // --------------------------------------------------------------------------

  // --------------------------------------------------------------------------
  // ### Authentication
  // --------------------------------------------------------------------------
  /**
   * Send this event to signify that a user has logged in.
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#login
   * @param method The method used to login (example: "Google")
   */
  public login(method?: string) {
    return this.event('login', { method });
  }

  /**
   * This event indicates that a user has signed up for an account.
   * Use this event to understand the different behaviors of logged in and logged out users.
   * @see https://developers.google.com/analytics/devguides/collection/ga4/reference/events#sign_up
   * @param method The method used for sign up (example: "Google")
   */
  public signUp(method?: string) {
    return this.event('sign_up', { method });
  }

  // --------------------------------------------------------------------------
  // ### Checkout
  // --------------------------------------------------------------------------
  public beginCheckout(action?: CheckoutAction) {
    return this.event('begin_checkout', action);
  }

  public addPaymentInfo(action?: CheckoutAction) {
    return this.event('add_payment_info', action);
  }

  public purchase(action?: CheckoutAction) {
    return this.event('purchase', action);
  }
  
  // TODO: unlock_achievement

  // --------------------------------------------------------------------------
  // ### E-Commerce (catalogue)
  // --------------------------------------------------------------------------
  public viewItem(action: ItemAction) {
    return this.event('view_item', action);
  }

  public viewItemList(action: ViewItemListAction) {
    return this.event('view_item_list', action);
  }

  public share(action: ShareItemAction) {
    return this.event('share', action);
  }

  public addItemToCart(action: ItemAction) {
    return this.event('add_to_cart', action);
  }


  // TODO: other tracking methods
  // ------------------------------------------------------------------------
  
  // ------------------------------------------------------------------------
  // ## Custom Events
  // ------------------------------------------------------------------------
  /**
   * This event indicates that a user has initiated the sign in for an account.
   * @param method The method used for sign in (example: "Google")
   */
  public signInAttemptEvent(method?: string) {
    return this.event('sign_in_attempt', {
      method: method,
    });
  }

  /**
   * Register a click on the start reservation button (shortcut)
   * @param action target item
   */
  public startReservation(action: ItemAction) {
    return this.event('start_reservation', action);
  }
  // ------------------------------------------------------------------------
}
