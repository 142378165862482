import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Booking } from '../booking.model';

@Component({
  selector: 'app-booking-notes-modal',
  templateUrl: './booking-notes-modal.component.html',
})
export class BookingNotesModalComponent implements OnInit, OnDestroy {

  @Input() booking: Booking;

  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }
  
  dismiss() {
    this.activeModal.dismiss('Cross click');
  }
}
