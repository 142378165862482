import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Booking } from '../bookings/booking.model';
import { Event } from '../events/event.model';
import { CheckinFlowState } from './checkin-flow-state.model';

@Injectable({
  providedIn: 'root'
})
export class CheckinFlowService {

  public stateSource: BehaviorSubject<CheckinFlowState> = new BehaviorSubject(
      null
  );

  constructor() { }  

  public previousStep() {
    const currentState = this.stateSource.value;

    if(!(
      currentState.step === CheckinFlowState.STEP_CHECKIN_START || 
      currentState.step === CheckinFlowState.STEP_CHECKIN_DENY
    )) {
      throw `Invalid State, cannot go back from step ${currentState.step}`;
    }

    currentState.step = currentState.step - 1;
    this.stateSource.next(currentState);
  }
  
  public goToDenyStep() {
    const currentState = this.stateSource.value;
    currentState.step = CheckinFlowState.STEP_CHECKIN_DENY;
    this.stateSource.next(currentState);
  }

  public abortDenyStep() {
    const currentState = this.stateSource.value;
    
    if(!(
      currentState.step === CheckinFlowState.STEP_CHECKIN_DENY
    )) {
      throw `Invalid State, cannot go back from step ${currentState.step}`;
    }

    currentState.step = CheckinFlowState.STEP_CHECKIN_CONFIRMATION;
    this.stateSource.next(currentState);

  }

  public setFailure(error: {code: string, message: string}, booking?: Booking) {
    const currentState = this.stateSource.value;
    currentState.step = CheckinFlowState.STEP_CHECKIN_FAILURE;
    currentState.checkInFailureReason = error;
    currentState.booking = booking;
    this.stateSource.next(currentState);
  }

  public setEvent(event: Event) {
    const currentState = this.stateSource.value;
    currentState.event = event;
    currentState.step = CheckinFlowState.STEP_CHECKIN_START;
    this.stateSource.next(currentState);
  }
  
  public setBooking(code: string, booking: Booking) {
    const currentState = this.stateSource.value;
    currentState.step = CheckinFlowState.STEP_CHECKIN_CONFIRMATION;
    currentState.code = code;
    currentState.booking = booking;
    this.stateSource.next(currentState);
  }

  public setBookingDenied(reason: string, notes: string) {
    const currentState = this.stateSource.value;
    currentState.checkInDeniedReason = reason;
    currentState.checkInDeniedNotes = notes;
    this.stateSource.next(currentState);
  }

  // To be used if we already selected an event (e.g., checkin-flow started in the event-details page)
  public forEvent(event: Event) {
      this.stateSource.next(new CheckinFlowState(event, CheckinFlowState.STEP_CHECKIN_START))
      return this;
  }

  // To be used if we don't have any info about the event
  public init() {
      this.stateSource.next(new CheckinFlowState())
      return this;
  }
  
}
