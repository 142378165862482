<input
  type="text"
  class="form-control"
  (keydown.enter)="$event.preventDefault()"
  placeholder="Where do you want to go?"
  i18n-placeholder="location input|Placeholder for input location"
  [value]="location?.address"
  (focus)="$event.srcElement.value = ''"
  (blur)="$event.srcElement.value = location?.address"
  autocorrect="off"
  autocapitalize="off"
  spellcheck="off"
  type="text"
  #search
/>
