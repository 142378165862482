import { Component, Input, OnInit } from '@angular/core';
import { Booking } from '../../bookings/booking.model';
import { Event } from '../../events/event.model';
import { CheckinFlowService } from '../checkin-flow.service';
import { CheckinModalComponent } from '../checkin-modal/checkin-modal.component';

@Component({
  selector: 'app-checkin-confirmation',
  templateUrl: './checkin-confirmation.component.html',
  styleUrls: ['./checkin-confirmation.component.scss']
})
export class CheckinConfirmationComponent implements OnInit {
  public event: Event;
  public booking: Booking;

  public warning?: string;

  @Input() modal: CheckinModalComponent;

  constructor(public checkinFlowService: CheckinFlowService) {
    this.event = this.checkinFlowService.stateSource.value.event;
    this.booking = this.checkinFlowService.stateSource.value.booking;
  }

  ngOnInit(): void {
  }

  refuseCheckIn(): void {
    this.checkinFlowService.goToDenyStep();
  }

  restart(): void {  
    this.checkinFlowService.forEvent(this.event);
  }

  dismiss(): void {
    this.modal.dismiss();
  }
}
