<div class="modal-header bg-success border-0">
    <h5 class="modal-title text-white" id="checkInTitle">
        <i class="fa fa-check pr-2" aria-hidden="true"></i>
        <span i18n>Check-in validated!</span>
    </h5>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="dismiss()">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body p-0 bg-success">
    <div *ngIf="booking.isEarly" class="alert alert-warning rounded-0 shadow p-4" role="alert">
        <i class="fa fa-clock-o pr-2" aria-hidden="true"></i>
        <ng-container i18n>This participant arrived {{ -booking.minutesFromScheduleStart }} minutes <strong>earlier</strong> than expected.</ng-container>
    </div>
    <div *ngIf="booking.isLate" class="alert alert-warning rounded-0 shadow p-4" role="alert">
        <i class="fa fa-clock-o pr-2" aria-hidden="true"></i>
        <ng-container i18n>This participant arrived {{ booking.minutesFromScheduleStart }} minutes <strong>late</strong>.</ng-container>
    </div>
    <div class="px-4 py-5">
        <div class="container bg-white shadow px-5 py-5">
        <div class="row mb-4 pb-2 border-bottom">
            <div class="col heading-title text-primary"><i class="fa fa-tag pr-2" aria-hidden="true"></i>{{ event.name }}</div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-3"><small class="text-uppercase text-muted font-weight-bold" i18n>Name</small></div>
            <div class="col"><span class="lead">{{ booking.first_name }} {{ booking.last_name }}</span></div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-3"><small class="text-uppercase text-muted font-weight-bold" i18n># Participants</small></div>
            <div class="col"><span class="lead">{{ booking.people_nb }}</span></div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-3"><small class="text-uppercase text-muted font-weight-bold" i18n>Schedule</small></div>
            <div class="col"><span class="lead">{{ booking.schedule_start_datetime | date: 'shortTime' }}</span></div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-3"><small class="text-uppercase text-muted font-weight-bold" i18n>Table</small></div>
            <div class="col"><span class="lead">{{ booking.table_joining_code }} ({{ booking.people_nb }} participants)</span></div>
        </div>
        <div class="row mt-4">
            <div class="col-lg-3"><small class="text-uppercase text-muted font-weight-bold" i18n>Notes</small></div>
            <div class="col">
                <pre *ngIf="booking.notes; else noNotes">{{ booking.notes }}</pre>
                <ng-template #noNotes><span class="badge badge-info" i18n="no notes in booking">No notes</span></ng-template>
            </div>
        </div>
    </div>
    </div>
</div>
<div class="modal-footer bg-success">
    <div class="container-fluid">
        <div class="row justify-content-end">
            <!-- TODO: feature not yet supported -->
            <div class="col-12 col-lg-4 my-2 my-lg-0" *ngIf="false">
                <button type="button" class="btn btn-danger btn-multiline px-3 w-100 h-100" data-dismiss="modal" (click)="refuseCheckIn()" i18n>Deny booking</button>
            </div>
            <div class="col-12 col-lg-4 my-2 my-lg-0">
                <button type="submit" class="btn btn-white btn-multiline w-100 h-100" data-dismiss="modal" (click)="restart()"><i class="fa fa-refresh" aria-hidden="true"></i><span i18n>Restart check-in</span></button>
            </div>
        </div>
    </div>
</div>