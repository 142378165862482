import { Injectable } from '@angular/core';
import { Serializer } from '../shared/serializer';
import { Point } from './point.model';

export class Place {
  constructor(
    public id: string,
    public name: string,
    public tags: string[],
    public location: Point,
    public address: string,
    public phone_number?: string,
    public description?: string
  ) { }

  getPhoneNumberUrlFormat() {
    if (this.phone_number === null) {
      return null;
    }

    // Simplify URI tel:<number> format seems to be a good idea.
    // See https://stackoverflow.com/a/58278785
    // Naive implementation below
    return (
      this.phone_number
      .trim()
      .replace(/ /g, "")
      .replace("(0)", "")
    )
  }
}

@Injectable({
  providedIn: 'root',
})
export class PlaceSerializer extends Serializer<Place, Place> {
  deserialize(place: Place): Place {
    return new Place(
      place.id,
      place.name,
      place.tags,
      place.location,
      place.address,
      place.phone_number,
      place.description,
    );
  }
}
