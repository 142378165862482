import { Injectable } from "@angular/core";
import { Serializer } from '../shared/serializer';

export class Document {
    constructor(
        public id: string,
        public created_datetime: Date,
        public published_datetime: Date,
        public text: string,
        public type: string,
        public type_display: string,
        public version: number,
    ) { }
}

@Injectable({
    providedIn: 'root',
})
export class DocumentSerializer extends Serializer<Document, Document> {
    constructor() {
      super();
    }

    deserialize(item: Document): Document {
        return new Document(
            item.id,
            item.created_datetime,
            item.published_datetime,
            item.text,
            item.type,
            item.type_display,
            item.version,
        );
    }
}
