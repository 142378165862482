<div class="card border-dark mb-3">
    <div class="card-header bg-dark text-white">
        <span class="alert-inner--icon"><i class="ni ni-check-bold"></i></span>
        <span class="ml-2" i18n>Table</span>
        
        <button type="button" class="close" data-dismiss="alert" aria-label="Close" (click)="close()" *ngIf="showCloseButton">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="card-body">
        <h6 class="card-title" i18n>You have associated this booking with a table:</h6>
        <div class="card-text">
            <!-- Show table info -->
            
            <ul>
                <li i18n>the <strong>{{ table.schedule_start_datetime | date: 'longDate' }}</strong> at <strong>{{ table.schedule_start_datetime | date: 'shortTime' }}</strong></li>
                <li i18n>with <strong>{{ table.people_nb }} participants confirmed</strong></li>
            </ul>
            <p class="small mb-0" i18n>
                Your booking will join this table once finalized.
            </p>
        </div>
    </div>
</div>