<div [ngSwitch]="event.cannot_be_booked_reason">
    <div *ngSwitchCase="'too_early'">
        <h4 class="text-warning" i18n="Event cannot be booked yet title">Too early...</h4>
        <p i18n="Event cannot be booked yet paragraph">It is too soon to book this event. Come back on the {{ event.min_booking_datetime | date: 'longDate' }}.</p>
    </div>
    <div *ngSwitchCase="'too_late'">
        <h4 class="text-warning" i18n="Event cannot be booked anymore title">Too late! :-(</h4>
        <p i18n="Event cannot be booked anymore paragraph">You missed this event! How about another one?</p>
    </div>
    <div *ngSwitchCase="'no_schedule_can_be_booked'">
        <h4 class="text-warning" i18n="Event fully booked title">Sold out! :-(</h4>
        <p i18n="Event fully booked paragraph">This event is fully booked! Maybe next time?</p>
    </div>
    <div *ngSwitchDefault>
        <h4 class="text-warning" i18n="Event cannot be booked title">Event not available</h4>
        <p i18n="Event cannot be booked paragraph">Sorry, this event cannot be booked.</p>
    </div>
</div>
