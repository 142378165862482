import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { CookieConsent } from '../consent.model';
import { CookieService } from '../cookie.service';
import { UntypedFormControl, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { GOOGLE_ANALYTICS_CONSENT_KEY } from 'src/app/gtag/gtag.constants';

@Component({
  selector: 'app-cookie-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class CookieSettingsComponent implements OnInit {
  static DISMISS_REASON_CANCEL: number = 2;

  @Input() userConsent?: CookieConsent;

  form: UntypedFormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    fb: UntypedFormBuilder
  ) {
    this.form = fb.group({
      "google-analytics": new UntypedFormControl(false),
    });
  }

  ngOnInit(): void {
    // set UI values according to consented keys
    const consents = this.userConsent === null ? null : this.userConsent.consents;
    console.debug(`DEBUG[cookie/settings]: current user consent = ${JSON.stringify(consents)}`)

    const googleAnalytics = consents === null ? true : consents.includes(GOOGLE_ANALYTICS_CONSENT_KEY);
    const formValue = {
      "google-analytics": googleAnalytics,
    };

    this.form.patchValue(formValue);
    console.debug(`DEBUG[cookie/settings]: form state = ${JSON.stringify(formValue)}`);
  }

  onSave() {
    // get consents from form
    const givenConsents = Object.keys(this.form.value).filter(k => this.form.value[k]);    
    const consents = CookieService.getMandatoryConsents().concat(givenConsents);
    console.debug(`DEBUG[cookie/settings]: user submitted constents for ${JSON.stringify(consents)}`);
    
    this.activeModal.close(consents);
  }

  dismiss() {
    this.activeModal.dismiss(CookieSettingsComponent.DISMISS_REASON_CANCEL);
  }

}
