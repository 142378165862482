import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { UntypedFormGroup, UntypedFormControl, UntypedFormBuilder } from '@angular/forms';
import { EventsQuery } from '../../events/events-query.model';
import { ngbDateToString } from 'src/app/shared/utils/dates';
import { Location } from 'src/app/location/location.model';
import { Tag } from '../../events/tag.model';
import { TagsService } from '../../events/tags.service';
import { LocationService } from 'src/app/location/location.service';
import { Subscription } from 'rxjs';
import { DEFAULT_SEARCH_DISTANCE } from '../event-filters.constants';

@Component({
  selector: 'app-search-event-compact',
  templateUrl: './search-event-compact.component.html',
  styleUrls: ['./search-event-compact.component.scss'],
})
export class SearchEventCompactComponent implements OnInit, OnDestroy {
  searchForm: UntypedFormGroup;
  location: Location;
  tags: Tag[];

  locationSubscription: Subscription;

  constructor(
    private calendar: NgbCalendar,
    private router: Router,
    private fb: UntypedFormBuilder,
    private tagsService: TagsService,
    private locationService: LocationService,
  ) {
    this.searchForm = fb.group({
      when: new UntypedFormControl(this.calendar.getToday()),
      what: new UntypedFormControl('any'),
    });
  }

  ngOnInit() {
    this.tagsService.loadTags().subscribe((tags: Tag[]) => {
      this.tags = tags;
    });

    this.locationSubscription = this
      .locationService
      .locationSubject
      .subscribe(location => {
        this.location = location;
      });
  }

  ngOnDestroy(): void {
    this.locationSubscription.unsubscribe();
  }

  onSearch() {
    const query: EventsQuery = new EventsQuery(
      this.location?.latitude,
      this.location?.longitude,
      this.location ? DEFAULT_SEARCH_DISTANCE : undefined,
      ngbDateToString(this.searchForm.value.when),

      // // To Date
      // Changed in https://gitlab.com/restaudeal/restaudeal/-/issues/368
      // Was `ngbDateToString(this.searchForm.value.when)` (exact date)
      // but it is too strict for our first events
      // (would not yield any results if user does not explicitly set the date of the event).
      undefined,
      this.searchForm.value.what,
      false
    );

    this.router.navigate(['/events'], {
      queryParams: query.toQueryParams(),
    });
  }
}
