<main class="mb-5" style="min-height: 100vh">
    <div class="position-relative">
      <section class="section section-lg section-shaped jumbotron">
        <div class="container text-center py-4" *ngIf="event; else loadingEvent">
            <h1>{{ event.name }}</h1>
            <p class="lead">
                <span *ngIf="!event.is_single_day" class="small" i18n> From </span>
                <span class="badge badge-pill badge-secondary">{{
                    event.start_datetime | date: 'longDate'
                }}</span>
                <span *ngIf="!event.is_single_day">
                    <span class="small" i18n> to </span>
                    <span class="badge badge-pill badge-secondary">{{
                        event.end_datetime | date: 'longDate'
                    }}</span>
                </span>
            </p>
        </div>
        <ng-template #loadingEvent>
            <div class="d-flex justify-content-center my-5 pt-5 mx-auto" style="min-height: 5.5rem;">
                <div class="spinner-border" role="status">
                <span class="sr-only">Loading...</span>
                </div>
            </div>
        </ng-template>
        <!-- SVG separator -->
        <div class="separator separator-bottom separator-skew">
          <svg
            x="0"
            y="0"
            viewBox="0 0 2560 60"
            preserveAspectRatio="none"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg"
          >
            <polygon class="fill-white" points="2560 0 2560 60 0 60"></polygon>
          </svg>
        </div>
        <!-- Quickfix for background transparency -->
        <div class="jumbotron-bg"></div>
      </section>
      <!-- TODO show checkin prompt if event is today -->
      <section>
        <div
            class="container"
            style="min-height: 17.5rem"
        >
            <div class="row">
                <div class="col-12">
                    <div class="alert alert-info mb-5" role="alert">
                        <p class="display-4 text-white" i18n><i class="fa fa-info-circle" aria-hidden="true"></i> This section is under development</p>
                        <hr class="my-2" />
                        <p class="lead" i18n>This is a preview. Soon you will find here many new features! ;)</p>
                    </div>
                </div>
            </div>
            
            <ng-container class="row" *ngIf="bookings && event; else loading">
                <div class="row">
                    <div class="col-12"><hr class="w-100"/></div>
                    <div class="col-12">
                        <nav class="nav nav-pills flex-column flex-sm-row">
                            <a class="btn flex-sm-fill text-sm-center nav-link mx-1" routerLink="." fragment="orders" [ngClass]="{'active': tab === 'orders'}" (click)="setTab('orders')" i18n>Orders</a>
                            <a class="btn flex-sm-fill text-sm-center nav-link mx-1" routerLink="." fragment="bookings" [ngClass]="{'active': tab === 'bookings'}" (click)="setTab('bookings')" i18n>Bookings</a>
                            <a class="btn flex-sm-fill text-sm-center nav-link mx-1" (click)="showCheckInModal()" i18n>Check-in customers</a>
                        </nav>
                    </div>
                    <div class="col-12"><hr class="w-100"/></div>
                </div>
                <div class="row">
                    <ng-container [ngSwitch]="tab">
                        <div class="col-12" id="orders" *ngSwitchCase="'orders'">
                            <h2 class="mt-2" i18n>Orders</h2>
                            <p i18n>To help you prepare the meals we summarize here what's been ordered and if there are special remarks.</p>
                            <app-management-orders-list class="col-12" [bookings]="bookings" [event]="event"></app-management-orders-list>
                        </div>
                        
                        <div class="col-12" id="bookings" *ngSwitchCase="'bookings'">
                            <h2 class="mt-2" i18n>Bookings</h2>
                            <p i18n>To help you welcome your guests you can find here the reservations.</p>
                            <app-management-booking-list class="col-12" [bookings]="bookings"></app-management-booking-list>
                        </div>
                    </ng-container>
                </div>
            </ng-container>
            <ng-template #loading>
                <div class="row">
                    <div class="d-flex justify-content-center my-5 mx-auto">
                        <div class="spinner-border" role="status">
                            <span class="sr-only">Loading...</span>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
      </section>
    </div>
</main>
