import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { PaginatedListResponse } from 'src/app/shared/interfaces/paginated-list-response';
import { ApiResponseBooking, Booking, BookingSerializer } from './booking.model';
import { Event } from '../../events/event.model';


export class BookingNotFoundError implements Error {
  constructor(public code: string, public event_id: string) {
    this.name = `BookingNotFoundError(code=${code}, event_id=${event_id})`;
    this.message = `Could not find a booking with code="${code}" for event="${event_id}"`;
  }

  name: string;
  message: string;
}

export class CheckInError implements Error {
  constructor(public code: string, public reason: {code: string, message: string}, public booking?: Booking) {
    this.name = `CheckInError(code=${code}, reason="${reason.code}", booking=${booking?.id})`;
    this.message = `Could not validate booking code="${code}"`;
  }

  name: string;
  message: string;
}

export class CheckInCancellationError implements Error {
  constructor(public code: string, public event_id: string, public cancel_reason: string, public error_reason: string) {
    this.name = `CheckInCancellationError(code=${code}, event_id=${event_id}, cancel_reason=${cancel_reason}, error_reason=${error_reason})`;
    this.message = `Could not cancel booking check-in with code="${code}" for event="${event_id}" given the cancel_reason="${cancel_reason} due to error_reason=${error_reason}"`;
  }

  name: string;
  message: string;
}

type ApiResponseEventCheckIn = {
  valid: boolean,
  invalid_reason: {
    code: string,
    message: string,
  }
  booking?: ApiResponseBooking,
}

export type BookingsOrdering = (
  typeof BookingService.ORDER_BY_NAME |
  typeof BookingService.ORDER_BY_SCHEDULE_TABLE |
  typeof BookingService.ORDER_BY_SCHEDULE_NAME
)

@Injectable({
  providedIn: 'root'
})
export class BookingService {
  static readonly ORDER_BY_NAME = "order_by_schedule_name";
  static readonly ORDER_BY_SCHEDULE_TABLE = "order_by_schedule_table";
  static readonly ORDER_BY_SCHEDULE_NAME = "order_by_schedule_table";

  static readonly DEFAULT_ORDERING = BookingService.ORDER_BY_SCHEDULE_NAME;

  bookings: Booking[] = [];
  count: number;
  next: string;
  previous: string;

  constructor(
    private http: HttpClient,
    private bookingSerializer: BookingSerializer,
  ) { }

  getBookings(event: Event, orderBy?: BookingsOrdering): Observable<Booking[]> {
    let parameters = new HttpParams();

    const eventId = event.id;
    parameters = parameters.set("event_id", eventId);

    if(!orderBy) {
      orderBy = BookingService.DEFAULT_ORDERING;
    }

    switch (orderBy) {
      case BookingService.ORDER_BY_NAME:
        parameters = parameters.set("ordering", "user__last_name,user__first_name");
        break;
      case BookingService.ORDER_BY_SCHEDULE_TABLE:
        parameters = parameters.set("ordering", "schedule__start_datetime,table__joining_code,user__last_name,user__first_name");
        break;
      case BookingService.ORDER_BY_SCHEDULE_NAME:
        parameters = parameters.set("ordering", "schedule__start_datetime,user__last_name,user__first_name");
        break;
    
      default:
        throw new Error(`Unknown ordering, was '${orderBy}'`);
    }

    return this.http
      .get<PaginatedListResponse<ApiResponseBooking>>('/api/v1/management/bookings/', { params: parameters })
      .pipe(
        map((response) => {
          this.count = response.count;
          this.next = response.next;
          this.previous = response.previous;
          this.bookings = [];

          this.bookingSerializer.event = event;
          for (const booking of response.results) {
            this.bookings.push(this.bookingSerializer.deserialize(booking));
          }

          // TODO: handle paginated responses
          return this.bookings;
        })
      );
  }

  checkinBooking(event_id: string, code: string): Observable<Booking> {
    return this.http
      .get('/api/v1/management/events/' + event_id + '/check-in/' + code)
      .pipe(map((response: ApiResponseEventCheckIn) => {
        const booking = response.booking ? this.bookingSerializer.deserialize(response.booking) : null;

        if(!response.valid) {
          if(response.invalid_reason.code === "not_found") {
            throw new BookingNotFoundError(code, event_id);
          }
          throw new CheckInError(code, response.invalid_reason, booking);
        }

        return booking;
      }), 
      catchError((err: HttpErrorResponse) => {
        if(err.status === 404) {
          return throwError(() => new BookingNotFoundError(code, event_id));
        } else {
          return throwError(() => err);
        }
      }));
  }

  // TODO: not yet supported
  cancelCheckIn(event_id: string, code: string, reason: string, notes?: string): Observable<string> {
    const body = {
      "reason": reason,
      "notes": notes,
    }
    
    return this.http
      .post('/api/v1/management/events/' + event_id + '/cancel-check-in/' + code, body)
      .pipe(map((response: any) => {
        const booking = response.booking ? this.bookingSerializer.deserialize(response.booking) : null;

        if(!response.valid) {
          if(response.reason === "not_found") {
            throw new BookingNotFoundError(code, event_id);
          }
          
          throw new CheckInCancellationError(code, event_id, reason, response.invalid_reason);
        }

        return response.code;
      }),
      catchError((err: HttpErrorResponse) => {
        if(err.status === 404) {
          return throwError(() => new BookingNotFoundError(code, event_id));
        } else {
          return throwError(() => err);
        }
      }));
  }
}
