import { Place, PlaceSerializer } from './place.model';
import { Deal } from './deal.model';
import { Menu, ApiResponseMenu, MenuSerializer } from './menu.model';
import { Schedule, ScheduleSerializer } from './schedule.model';
import { Tag } from './tag.model';
import { Injectable } from '@angular/core';
import { Serializer } from '../shared/serializer';
import { extractDate } from '../shared/utils/dates';
import { Section } from './section.model';
import { Choice } from './choice.model';

export class Discount {
  public currentAmount: number = 0;
  public newAmountIncrement: number = 0;
  public amount: number = 0;  // aka newAmountTotal
  public currentUnlockedDeals: Deal[] = [];
  public unlockedDeals: Deal[] = []; // all unlocked deals (current + new)
  public newUnlockedDeals: Deal[] = [];
  public nextDeals: Deal[] = [];
  public remainingPeopleForNextDeal: number = 0;

  /**
   * Price per person including this discount.
   */
  public eventPrice: number;

  /**
   * Total price with the extra participants (including the discounts potentially unlocked).
   */
  public bookingPrice: number;
  public bookingOriginalPrice: number;
  public totalDiscount: number;
  public nextAmount: number;
  public nextEventPrice: number;
  public maxTotalRemainingDiscount = 0;

  constructor(
    /**
     * Reference event.
     */
    public event: Event,  // NOTE: only the list of deals is used here.

    /**
     * new = selection (booking context)
     */
    public newParticipants: number,

    /**
     * Reference number of participants if different than the ones defined in event.
     * This is used to build discounts that have been already unlocked.
     */
    public currentParticipants?: number,
  ) {
    if(currentParticipants == null) {
      if(event.participants_nb !== undefined) {
        currentParticipants = event.participants_nb;
      } else {
        // recompute it from the deals marked as unlocked and people to unlock the next deal
        currentParticipants = 0;
        for(const deal of event.previous_deals) {
          currentParticipants += deal.people_nb;
        }
        if(event.next_deals.length > 0) {
          currentParticipants += event.next_deals[0].people_nb - this.event.people_nb_to_next_deal;
        }
      }
    }

    let newAmountTotal = 0;
    let allUnlockingParticipants: number = currentParticipants + newParticipants;

    // Unstack next deals progressively to calculate discount properties
    // (consume all unlocking participants until no more available or no other deal to unlock)
    for (const deal of event.getDeals()) {
      allUnlockingParticipants -= deal.people_nb;

      if (allUnlockingParticipants >= 0) {
        // we can fill this deal with the total number of participants
        this.unlockedDeals.push(deal);
        newAmountTotal += deal.amount;

        if(allUnlockingParticipants >= currentParticipants) {
          // this deal is currently unlocked
          this.currentAmount += deal.amount;
          this.currentUnlockedDeals.push(deal);
        } else {
          // this deal is NOT currently unlocked
          this.newAmountIncrement += deal.amount;
          this.newUnlockedDeals.push(deal);
        }
      } else {
        // NOT enough participants left to unlock this deal.
        // This deal is among the next deals.
        this.nextDeals.push(deal);

        if (this.nextDeals.length === 1) {
          // Next deal to unlock.
          // Calculate remaining number of people to reach it
          this.remainingPeopleForNextDeal = -allUnlockingParticipants;
        }
      }
    }

    // amount only is not very clear, keeping it for compatibility.
    this.amount = newAmountTotal;

    this.eventPrice = event.original_price - this.amount;

    this.bookingOriginalPrice = newParticipants * event.original_price;
    this.bookingPrice = newParticipants * this.eventPrice;
    this.totalDiscount = this.bookingOriginalPrice - this.bookingPrice;

    if(this.nextDeals.length > 0) {
      this.nextAmount = this.amount + this.nextDeals[0].amount;
      this.nextEventPrice = event.original_price - this.nextAmount;
    }
  }

  isIntermediateDiscount() {
    return !this.isFullPublicPrice() && !this.isRestaudealBasePrice() && this.amount > 0 && this.nextDeals.length > 0;
  }

  isBestDiscount() {
    return this.nextDeals.length == 0;
  }

  isNoDiscount() {
    return this.amount == 0;
  }

  isRestaudealBasePrice() {
    // True if this is either a full public price or a Restaudeal starting price
    return this.unlockedDeals[this.unlockedDeals.length - 1].people_nb === 1;
  }

  isFullPublicPrice() {
    // Full Public price is defined if the first deal has people_nb=0
    return this.amount == 0 && this.unlockedDeals[0].people_nb === 0;
  }

  isExclusiveBasePrice() {
    // Exclusive event (no public price reference)
    return !this.isFullPublicPrice() && this.isNoDiscount();
  }
}

// NOTE: must include a "base price deal" (previous_deals = unlocked deals).
export class Event {
  public is_single_day: boolean;

  constructor(
    public id: string,
    public name: string,
    public description: string,
    public short_description: string,
    public best_price: number,
    public original_price: number,
    public current_price: number,
    public icon: string,
    public icon_url: string,
    public image_url: string,
    public tags: Tag[],

    public created_datetime: Date,
    public start_datetime: Date,
    public end_datetime: Date,
    public max_participants_nb: number,
    public participants_nb: number,
    public people_nb_to_next_deal: number,

    public place: Place,
    public previous_deals: Deal[],
    public next_deals: Deal[],

    public schedules: Schedule[],
    public is_takeaway: boolean = false,
    public hot: boolean = false,

    public can_be_booked: boolean,
    public cannot_be_booked_reason: string | null,
    public min_booking_datetime: Date,
    public max_booking_datetime: Date,

    public min_people_nb_per_table: number,
    public booking_capacity_warning: number,
    public booking_capacity_deny: number,

    public menu: Menu | null,
    public menu_preference_required: boolean,
  ) {
    this.is_single_day =
      start_datetime.getDate() === end_datetime.getDate() &&
      start_datetime.getMonth() === end_datetime.getMonth() &&
      start_datetime.getFullYear() === end_datetime.getFullYear();
  }

  getDeals() {
    // deals are ordered
    return this.previous_deals.concat(this.next_deals);
  }

  getCurrentDeal() {
    if(this.previous_deals.length === 0) {
      // No deal with people_nb = 0, this is an exclusive event without public price reference.
      return this.next_deals[0];
    } else {
      const d = this.previous_deals[this.previous_deals.length - 1];
      if(d.people_nb === 0) {
        // Deal with people_nb = 0 are the public price reference (regualar price without Restaudeal)
        return this.next_deals[0];
      } else {
        return d;
      }
    }
  }

  getDiscountFor(newParticipants: number): Discount {
    return new Discount(this, newParticipants);
  }

  getDiscountForDeal(deal: Deal): Discount {
    var participants = deal.people_nb;

    for (const d of this.getDeals()) {
      if(d === deal) {
        return new Discount(this, 0, participants);
      }

      participants += d.people_nb;
    }

    throw new Error(`Deal '${deal.id}' is NOT part of event ${this.id}`);
  }

  /**
   * Get all the dates for which event is active.
   * @returns array of Dates between start_datetime (included) and end_datetime (included)
   */
  getEventDates(): Date[] {
    let currentDate = extractDate(this.start_datetime);
    const endDate = extractDate(this.end_datetime);
    const res = [currentDate];

    while(currentDate < endDate) {
      currentDate = new Date(currentDate);
      currentDate.setDate(currentDate.getDate() + 1);
      res.push(currentDate);
    }

    return res;
  }

  nextBookingPrice() {
    if(this.can_be_booked) {
      return this.getDiscountFor(1).eventPrice;
    } else {
      return this.current_price;
    }
  }


  //////////////////////////////////////////////////////////////////////////////
  // Use choice ids to identify the corresponding objects in the Menu object
  //////////////////////////////////////////////////////////////////////////////
  private indexSectionChoiceById: Map<string, [Section, Choice]> | null = null;

  private buildIndexSectionChoiceById() {
      this.indexSectionChoiceById = new Map();
  
      if(this.menu === null || !this.menu.sections) return;

      for (const section of this.menu?.sections) {
          if(section.choices === null) continue;

          for (const choice of section.choices) {
              this.indexSectionChoiceById.set(choice.id, [section, choice]);
          }
      }
  }

  public getSectionChoiceById(choiceId: string): [Section, Choice] | undefined {
      if(this.indexSectionChoiceById === null) {
          this.buildIndexSectionChoiceById();
      }

      return this.indexSectionChoiceById?.get(choiceId);
  }
  //////////////////////////////////////////////////////////////////////////////
}

@Injectable({
  providedIn: 'root',
})
export class EventSerializer extends Serializer<Event, Event> {
  constructor(
    private scheduleSerializer: ScheduleSerializer,
    private placeSerializer: PlaceSerializer,
    private menuSerializer: MenuSerializer,
  ) { super(); }

  deserialize(event: Event): Event {
    const schedules = event.schedules?.map((schedule) =>
      this.scheduleSerializer.deserialize(schedule)
    ).sort((a, b) =>
      (a.start_datetime.valueOf() - b.start_datetime.valueOf())
    );
    const place = this.placeSerializer.deserialize(event.place);
    const menu = event.menu ? this.menuSerializer.deserialize(event.menu as unknown as ApiResponseMenu) : null;  // QUICKFIX: needed until we have ApiResponseEvent

    return new Event(
      event.id,
      event.name,
      event.description,
      event.short_description,
      event.best_price,
      event.original_price,
      event.current_price,
      event.icon,
      event.icon_url,
      event.image_url,
      event.tags,
      new Date(event.created_datetime),
      new Date(event.start_datetime),
      new Date(event.end_datetime),
      event.max_participants_nb,
      event.participants_nb,
      event.people_nb_to_next_deal,
      place,
      event.previous_deals,
      event.next_deals,
      schedules,
      event.is_takeaway,
      event.hot,
      event.can_be_booked,
      event.cannot_be_booked_reason,
      new Date(event.min_booking_datetime),
      new Date(event.max_booking_datetime),
      event.min_people_nb_per_table,
      event.booking_capacity_warning,
      event.booking_capacity_deny,
      menu,
      event.menu_preference_required,
    );
  }
}
