import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { Event } from '../events/event.model';
import { Schedule } from '../events/schedule.model';
import { Item } from '../gtag/gtag.model';
import { GtagService } from '../gtag/gtag.service';
import { BookingFlowState } from './booking-flow-state.model';
import { Table } from '../tables/table.model';

@Injectable({
  providedIn: 'root',
})
export class BookingFlowService {
  public static buildItem(state: BookingFlowState) : Item {
    const check_in_datetime_str = state.selectedSchedule ? state.selectedSchedule.start_datetime.toISOString() : "";

    return {
      item_id: state.event.id,
      item_name: state.event.name,
      currency: "EUR",
      discount: state.discount.totalDiscount,
      item_brand: state.event.place.id,
      item_category: state.event.tags.length > 0 ? state.event.tags[0].slug : null,
      price: state.discount.bookingPrice,
      quantity: state.nbSelectedParticipants,
      lead_time: state.leadTime,
      check_in_date: check_in_datetime_str.substring(0, 10),
      check_in_time: check_in_datetime_str.substring(11, 16),
    };
  }

  public stateSource: BehaviorSubject<BookingFlowState> = new BehaviorSubject(
    null
  );

  isAuthenticated = undefined;

  constructor(
    private authService: AuthService,
    private gtagService: GtagService,
  ) {
    this.authService.user.subscribe((user) => {
      this.isAuthenticated = this.authService.isLoggedIn();
      const currentState = this.stateSource.value;

      if (currentState !== null) {
        // Booking flow state needs to be updated if the authentication state changes
        if (this.isAuthenticated && (currentState.step === 2 || currentState.step === 3)) {
          this.nextStep();
        }
        if (!this.isAuthenticated && currentState.step === 4) {
          // this seems to not be possible since we send the user back to the home if he logs out
          this.previousStep();
        }
      }
    });
  }

  private logBookingFlowState(currentState: BookingFlowState) {
    const item = BookingFlowService.buildItem(currentState);

    switch (currentState.step) {
      // TODO: remove magic numbers, use constants
      case 4:
        // start checkout when reaching the payment screen
        this.gtagService.beginCheckout({
          currency: "EUR",
          value: currentState.discount.bookingPrice,
          items: [item],
        });
        break;
      case 5:
        // register purchase
        this.gtagService.purchase({
          currency: "EUR",
          value: currentState.discount.bookingPrice,
          items: [item],
          // NOTE: transaction_id is important when taking into account refunds
          //       the transaction id is supposed to identify an order
          transaction_id: currentState.transactionId,
        });
        break;
    }
  }

  public previousStep() {
    const currentState = this.stateSource.value;

    if (currentState.step === 4) {
      // TODO: review logic here, maybe not much sense coming back to authentication step?
      // this seems to not be possible since we send the user back to the home if he logs out
      if (currentState.event.menu_preference_required) {
        currentState.step = this.isAuthenticated ? 2 : 3;
      } else {
        currentState.step = this.isAuthenticated ? 1 : 3;
      }
    } else {
      currentState.step = currentState.step - 1;
    }
    this.stateSource.next(currentState);
  }

  public nextStep() {
    const currentState = this.stateSource.value;

    if (
      (currentState.step === 1 && !currentState.event.menu_preference_required) || 
      currentState.step === 2
    ) {
      // show login step if required, otherwise move to payment
      currentState.step = this.isAuthenticated ? 4 : 3;
    } else {
      currentState.step = currentState.step + 1;
    }


    // log event into Google Analytics
    this.logBookingFlowState(currentState);

    this.stateSource.next(currentState);
  }

  public restart() {
    const currentState = this.stateSource.value;
    currentState.step = 1;
    this.stateSource.next(currentState);
  }

  public setNbSelectedParticipants(nbSelectedParticipants: number) {
    const currentState = this.stateSource.value;
    currentState.nbSelectedParticipants = nbSelectedParticipants;
    this.stateSource.next(currentState);
  }

  public setSelectedTable(selectedTable: Table | null) {
    const currentState = this.stateSource.value;
    currentState.selectedTable = selectedTable;

    // NOTE: we leave the current schedule selected if we remove the table
    if(selectedTable !== null) {
      this.setSelectedSchedule(this.getScheduleByTable(selectedTable, currentState.event.schedules));
    }

    this.stateSource.next(currentState);
  }

  public setSelectedSchedule(selectedSchedule: Schedule) {
    const currentState = this.stateSource.value;
    currentState.selectedSchedule = selectedSchedule;
    this.setNbSelectedParticipants(
      Math.min(
        currentState.nbSelectedParticipants,
        currentState.selectedSchedule.max_people_nb
      )
    );
    this.stateSource.next(currentState);
  }

  public setNotes(notes: string) {
    const currentState = this.stateSource.value;
    currentState.notes = notes;
    this.stateSource.next(currentState);
  }

  private getScheduleByTable(table: Table, schedules: Schedule[]): Schedule {
    // find schedule corresponding to the Schedule ID in the table
    for(const s of schedules) {
      if(s.id === table.schedule_id) {
        return s
      }
    }

    throw new Error(`Could NOT find schedule with id=${table.schedule_id} and associated to table with id=${table.id}. This should never happen.`);
  }

  public forEvent(event: Event) {
    // NOTE: a new booking session ID is created each time we change event
    this.stateSource.next(new BookingFlowState(event));
    return this;
  }
}
