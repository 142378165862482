import { Component, Input, OnInit } from '@angular/core';
import { Table } from '../table.model';

@Component({
  selector: 'app-table-card-compact',
  templateUrl: './table-card-compact.component.html',
  styleUrls: ['./table-card-compact.component.scss']
})
export class TableCardCompactComponent implements OnInit {

  /**
   * How to display the table information.
   * If "created" or not set, will display only the table join code.
   */
  @Input() showTableAs: "linked" | "joined" | "created" = "created";

  /**
   * Table to display.
   */
  @Input() table: Table;

  /**
   * Number of participants selected by the user.
   * Displayed only if the table is joined.
   */
  @Input() nbSelectedParticipants: number;

  constructor() { }

  ngOnInit(): void {
  }

}
