<div *ngIf="groupByDate; else flatView" class="mt-5">
  <!-- Displaying as single column on lg screens. Making card smaller to improve design -->
  <div class="expand-row-lg mt-5 mx-0 mx-lg-5 mx-xl-0" *ngFor="let entry of eventDateList">
    <div class="small text-muted">{{ entry.date | date: 'fullDate' }}</div>
    <hr class="mt-1 mb-3"/>
    <div class="row align-items-center py-3 px-0 px-lg-5 px-xl-0">
      <app-event-compact
        class="col-xl-6 mb-5 mb-xl-1 d-flex align-self-stretch"
        [event]="event"
        [style]="'two-columns'"
        *ngFor="let event of entry.events"
      ></app-event-compact>
    </div>
  </div>
</div>
<ng-template #flatView>
  <!-- Displaying as single column on lg screens. Making card smaller to improve design -->
  <div class="row expand-row-lg mt-5 mx-0 mx-lg-5 mx-xl-0 align-items-center py-3 px-0 px-lg-5 px-xl-0">
    <app-event-compact
      class="col-xl-6 d-flex align-self-stretch"
      [event]="event"
      [style]="'two-columns'"
      *ngFor="let event of events"
    ></app-event-compact>
  </div>
</ng-template>