import {
  AfterViewInit,
  Component,
  NgZone,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter,
  Input,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { LocationService } from '../location.service';
import { Location } from '../location.model';
import {Observable, Subscription} from "rxjs";
import {GoogleMapsApiLoaderService} from "../maps-api-loader.service";
import {isPlatformBrowser} from "@angular/common";

@Component({
  selector: 'app-location-input',
  templateUrl: './location-input.component.html',
  styleUrls: ['./location-input.component.scss'],
})
export class LocationInputComponent implements AfterViewInit {
  @ViewChild('search')
  public searchElementRef: ElementRef;
  apiLoaded: Observable<boolean>;
  locationSubscription: Subscription;
  location: Location;
  @Input() lat: number;
  @Input() lng: number;
  isBrowser: boolean;

  constructor(
    private locationService: LocationService,
    private mapsAPILoader: GoogleMapsApiLoaderService,
    private ngZone: NgZone,
    @Inject(PLATFORM_ID) private platformId
  ) {
    this.isBrowser = isPlatformBrowser(platformId);

    this.location = locationService.getLocation();

    this.apiLoaded = this.mapsAPILoader.load();
    this.apiLoaded.subscribe((loaded) => {
      if (loaded && !this.location) {
        this.initLocation();
      }
    });
  }

  ngAfterViewInit(): void {
    if (this.isBrowser) {
      this.loadMaps();
    }
  }

  loadMaps() {
    this.locationSubscription = this
      .locationService
      .locationSubject
      .subscribe(location => {
        this.location = location;
      });

    this.apiLoaded.subscribe((loaded) => {
      if (loaded) {
        this.initAutocomplete();
      }
    });
  }

  initLocation(): void {
    if (this.lat && this.lng) {
      // If a latitude and a longitude were given, load this location
      // inside the input
      this.locationService.setAddress(this.lat, this.lng);
    } else {
      // Otherwise, set the current location as the input location
      this.locationService.setCurrentAddress();
    }
  }

  initAutocomplete(): void {
    // Load Places Autocomplete
    const autocomplete = new google.maps.places.Autocomplete(
      this.searchElementRef.nativeElement,
      {
        types: ['(cities)'],
        componentRestrictions: { country: 'FR' },
      }
    );

    // When the user selects a new location,
    // set it as the input's location
    autocomplete.addListener('place_changed', () => {
      this.ngZone.run(() => {
        // Get the place result
        const place: google.maps.places.PlaceResult = autocomplete.getPlace();

        // Verify result
        if (place.geometry === undefined || place.geometry === null) {
          return;
        }

        // Set latitude, longitude and address
        this.location = new Location(
          place.geometry.location.lat(),
          place.geometry.location.lng(),
          place.formatted_address
        );
        this.locationService.setLocation(this.location);
      });
    });
  }
}
