import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Table } from '../table.model';

@Component({
  selector: 'app-table-card',
  templateUrl: './table-card.component.html',
  styleUrls: ['./table-card.component.scss']
})
export class TableCardComponent implements OnInit {

  @Input() table: Table;
  @Input() showCloseButton: boolean = false;

  @Output() closeEventEmitter: EventEmitter<TableCardComponent> = new EventEmitter();

  constructor() { }

  ngOnInit(): void { }

  close(): void {
    this.closeEventEmitter.emit(this);
  }
}
