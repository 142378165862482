<!-- Supporting only Offers with a single menu for now... -->
<div [formGroup]="form" *ngFor="let section of participantChoice.menu?.sections; let sectionIndex = index" class="menu-section">
    <ng-container *ngIf="section.hasChoices; else textOnlySection">
        <div class="form-group" *ngIf="getChoiceControl(participantIndex, sectionIndex) as control">
            <label for="{{ getChoiceKey(participantIndex, sectionIndex) }}"
                class="d-block heading text-uppercase font-weight-bold mt-2 mb-1">
                {{ section.name }}<span class="text-danger font-weight-bold" *ngIf="section.isChoiceRequired">*</span>
            </label>
            <select
                id="{{ getChoiceKey(participantIndex, sectionIndex) }}"
                formControlName="{{ getChoiceKey(participantIndex, sectionIndex) }}"
                class="form-control text-default custom-select custom-select-lg"
                [class.is-invalid]="control.invalid && (control.dirty || control.touched)"
                placeholder="Select an item...">
                <option i18n="menu section selection placeholder" [ngValue]="null" *ngIf="section.isChoiceRequired && enabled">Select an item...</option>
                <option i18n="menu section selection no preference" [ngValue]="NO_PREFERENCE" *ngIf="!section.isChoiceRequired">I will decide at the restaurant...</option>
                <option *ngFor="let choice of section.choices" [ngValue]="choice">
                    {{ choice.name }}<ng-container *ngIf="choice.minPeopleNb > 1" i18n> (min {{ choice.minPeopleNb }} person per group)</ng-container>
                    <span *ngIf="choice.extraCost" class="font-italic"> (+{{ choice.extraCost }} €)</span>
                </option>
            </select>
            <div
                class="invalid-feedback"
                *ngIf="control.invalid && (control.dirty || control.touched)"
            >
                <span class="alert-inner--icon mr-2"><i class="fa fa-exclamation-circle"></i></span>
                <ng-container *ngIf="control.hasError('required')" i18n>This choice is mandatory. Please select an option.</ng-container>
                <ng-container *ngIf="control.hasError('noMinPeople')" i18n>This choice requires at least {{ participantChoice.getChoiceFor(sectionIndex).minPeopleNb }} orders in a group to be considered valid.</ng-container>
            </div>
        </div>
        <!-- TODO: if value is null (no preference), show message that choice is subject to restaurant availablity -->
        <!-- TODO: single choice validation errors (e.g., item sold out, no alcohol if minor) -->
    </ng-container>
    <ng-template #textOnlySection>
        <div class="heading text-uppercase font-weight-bold mt-2 mb-1">{{ section.title }}</div>
        <div class="pt-4 pl-5 pl-md-2 font-italic">{{ section.description }}</div>
    </ng-template>
</div>

<!-- TODO: single participant choice validation errors (e.g., incompatible with options selected) -->
<!-- NOTE: this is already handled on a single field -->
<!-- <ng-container *ngIf="form.hasError('noMinPeople')" i18n>A choice does not meet the minimum required number of orders in a group to be considered valid.</ng-container> -->
