import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EventsService } from '../events/events.service';
import { Event } from '../events/event.model';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { delay } from 'rxjs/operators';
import { dateToString } from '../shared/utils/dates';
import { EventsQuery } from '../events/events-query.model';
import { GtagService } from '../gtag/gtag.service';
import { Page } from '../shared/components/seo/page.model';
import { SeoImage } from '../shared/components/seo/seo.model';
import { settings } from '../shared/settings';
import { SeoService } from '../shared/components/seo/seo.service';

@Component({
  selector: 'app-explore',
  templateUrl: './explore.component.html',
  styleUrls: ['./explore.component.scss'],
})
export class ExploreComponent implements OnInit, OnDestroy, Page {
  public static readonly RELAXED_DISTANCE = 50000;

  private routeQueryParamsSubscription: Subscription;

  @Input() isCollapsed = false; // NOTE: mobile only

  eventsQuery: EventsQuery;
  events: Event[];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private eventsService: EventsService,
    private gtagService: GtagService,
    private seoService: SeoService,
  ) {}

  /////////////////////////////////////////////////////////////////////////////
  // SEO METADATA
  /////////////////////////////////////////////////////////////////////////////
  getSeoTitle(): string {
    return $localize `:explore page SEO title|A short title describing the event search page:Restaudeal | Find Exclusive Collaborative Restaurant Offers Near You!`
  }

  getSeoDescription(): string {
    return $localize `:explore page SEO description|A description of the event search page:Get ready to indulge in delicious cuisine with our platform's exclusive discounts and collaborative offers at restaurants. Find events based on your location, date and cuisine preferences.`;
  }

  getSeoImage(): SeoImage {
    return settings.DEFAULT_SEO_IMAGE;
  }

  getSeoUrl(): string | null {
    return null;  // Defer to SeoService
  }
  
  renderSeoMetadata(): void {
    this.seoService.renderSeoMetadata(this);
  }
  /////////////////////////////////////////////////////////////////////////////

  private static processQueryParams(eventsQuery: EventsQuery) {
    if(!eventsQuery.dist && eventsQuery.lat && eventsQuery.lng) {
      // implied if not defined
      eventsQuery.dist = ExploreComponent.RELAXED_DISTANCE;
    }

    return eventsQuery;
  }

  ngOnInit() {
    // NOTE: for now we only support a static description.
    //       In future we should support a dynamic description where the input location.
    //       will be reflected in the SEO metadata 
    //       (e.g., if specified location in URL is Antibes, then title = Restaudeal | Find exclusive deals in Antibes)
    this.renderSeoMetadata();

    this.routeQueryParamsSubscription = this.route.queryParams
      .pipe(delay(0))
      .subscribe((params: Params) => {
        this.eventsQuery = EventsQuery.fromQueryParams(params);
        this.updateEvents();
      });
  }

  onFilterChanged(eventsQuery: EventsQuery) {
    this.router.navigate(['/events'], {
      queryParams: eventsQuery.toQueryParams(),
    });
  }

  updateEvents() {
    this.eventsService
      .loadEvents(this.eventsQuery)
      .subscribe((events: Event[]) => {
        this.events = events;

        // TODO: build full item objects
        const items = events.map((e) => {
          return {
            item_id: e.id,
            item_name: e.name,
            item_brand: e.place.id,
          };
        });

        this.gtagService.viewItemList({
          item_list_id: "explore",
          item_list_name: "explore",
          items: items,
        });
      });
  }

  relaxFilter() {
    const eq = new EventsQuery(
      this.eventsQuery.lat,
      this.eventsQuery.lng,
      ExploreComponent.RELAXED_DISTANCE,
      dateToString(new Date()),
      undefined,
      undefined,
      false
    );

    this.router.navigate(['/events'], {
      queryParams: eq.toQueryParams(),
    });
  }

  toggleCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  ngOnDestroy() {
    this.routeQueryParamsSubscription.unsubscribe();
  }
}
