import { Injectable } from "@angular/core";
import { DateMap, addToArrayInMap, addToSetInMap } from "src/app/shared/utils/map";
import { Serializer } from "../../shared/serializer";
import { EARLY_CHECKIN_THRESHOLD__MINUTES, LATE_CHECKIN_THRESHOLD__MINUTES } from './booking.settings';
import { ParticipantChoices, ParticipantChoicesSerializer } from '../../booking/booking.model';
import { Event } from '../../events/event.model';


export type Table = {
    people_nb: number,
    reference_user_first_name: string,
    reference_user_last_name: string,
    joining_code: string,
}


export class Booking {
    constructor(
        public created_datetime: Date,
        public id: string,
        public code: string,
        public code_used_datetime: Date,
        public is_cancelled: boolean,
        public people_nb: number,
        public event_id: string,
        public schedule_start_datetime: Date,
        public schedule_end_datetime: Date,
        public table_joining_code: string,
        public table_people_nb: number,
        public table_reference_user_id: string,
        public table_reference_user_first_name: string,
        public table_reference_user_last_name: string,
        public first_name: string,
        public last_name: string,
        public notes: string,
        public status_code: string,
        public status_message: string,
        public participant_choices: ParticipantChoices | null,
    ) {

    }

    get minutesFromScheduleStart(): number {
        // Negative if guest arrived before schedule start time, positive if after it
        return Math.round((new Date().valueOf() - this.schedule_start_datetime.valueOf()) / (60*1000));
    }

    get isEarly(): boolean {
        return -this.minutesFromScheduleStart >= EARLY_CHECKIN_THRESHOLD__MINUTES;
    }

    get isLate(): boolean {
        return this.minutesFromScheduleStart >= LATE_CHECKIN_THRESHOLD__MINUTES;
    }

    getTable(): Table {
        return {
            "people_nb": this.table_people_nb,
            "reference_user_first_name": this.table_reference_user_first_name,
            "reference_user_last_name": this.table_reference_user_last_name,
            "joining_code": this.table_joining_code,
        };
    }
}


export type ApiResponseParticipant = {
    alias?: string,
    order: number,
    choices: {
        id: string,
        name: string,
        description: string | null,
    }[],
};


export type ApiResponseBooking = {
    created_datetime: string,
    id: string,
    code: string,
    event: {
        id: string,
        name: string
    },
    code_used_datetime?: string,
    is_cancelled?: boolean,
    people_nb: number,
    phone_number: string,  // NOT IN USE! (should be part of /bookings/<booking-id>/contact API since sensitive info)
    schedule: {
        id: string,
        start_datetime: string,
        end_datetime: string,
    },
    table: {
        id: string,
        created_datetime: string,
        joining_code: string,
        people_nb: number,
        reference_user_id: string,
        reference_user_first_name: string,
        reference_user_last_name: string,
    },
    user: {
        id: string,
        first_name: string,
        last_name: string,
        email: string,  // NOT IN USE! (should be part of /bookings/<booking-id>/contact API since sensitive info)
    },
    // NOT SUPPORTED BY API (ideally notes should be placed here, not in payment)
    // NOTE: using payment value as fallback
    notes?: string,
    // See backend\src\rd_bookings\models.py for supported values
    status_code: string,
    status_message: string,
    // USED AS FALLBACK FOR notes
    // NOTE: everything else is not used by UI
    payment: {
        id: string,
        people_nb: number,
        notes: string,
        status: string,
        participants: ApiResponseParticipant[],
    }
}


@Injectable({
    providedIn: 'root',
})
export class BookingSerializer extends Serializer<ApiResponseBooking, Booking> {
    private _event: Event;

    /**
     * Bookings listed in the management UI are always associated to an event page.
     * This is needed to properly handle the menu choices and correctly display the section names.
     * @param event event to which the booking being deserialized refer to.
     */
    public set event(v : Event) {
        this._event = v;
    }


    public get event() : Event {
        return this._event;
    }

    
    constructor(
        private participantChoicesDeserializer: ParticipantChoicesSerializer
    ) {
      super();
    }


    deserialize(booking: ApiResponseBooking): Booking {
        if(!this._event) throw new Error("Cannot deserialize bookings without a reference Event object");
        this.participantChoicesDeserializer.event = this.event;

        return new Booking(
            new Date(booking.created_datetime),
            booking.id,
            booking.code,
            new Date(booking.code_used_datetime),
            booking.is_cancelled || false,
            booking.people_nb,
            booking.event?.id,
            new Date(booking.schedule.start_datetime),
            new Date(booking.schedule.end_datetime),
            booking.table.joining_code,
            booking.table.people_nb,
            booking.table.reference_user_id,
            booking.table.reference_user_first_name,
            booking.table.reference_user_last_name,
            booking.user.first_name,
            booking.user.last_name,
            booking.notes || booking.payment.notes,  // FALLBACK since notes are not part of Booking API response
            booking.status_code,
            booking.status_message,
            this.participantChoicesDeserializer.deserialize(booking.payment.participants),
        )
    }
}


export function indexBookingTableJoiningCodesByDate(map: DateMap<Set<string>>, booking: Booking) {
    addToSetInMap(map, booking.schedule_start_datetime, booking.table_joining_code);
}


export function indexTablesByTableJoiningCode(map: Map<string, Table>, booking: Booking) {
    if(!map.has(booking.table_joining_code)) {
        const table: Table = booking.getTable();
        map.set(booking.table_joining_code, table);
    }
}


export function indexBookingsByTableJoiningCode(map: Map<string, Booking[]>, booking: Booking) {
    addToArrayInMap(map, booking.table_joining_code, booking);
}
