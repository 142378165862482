import { Component, OnInit } from '@angular/core';
import { EventsService } from '../events/events.service';
import { Event } from '../events/event.model';
import { EventsQuery } from '../events/events-query.model';

@Component({
  selector: 'app-showroom',
  templateUrl: './showroom.component.html',
  styleUrls: ['./showroom.component.scss'],
})
export class ShowroomComponent implements OnInit {
  events: Event[];

  constructor(private eventsService: EventsService) {}

  ngOnInit() {
    this.eventsService
      .loadEvents(new EventsQuery())
      .subscribe((events: Event[]) => {
        this.events = events;
      });
  }
}
