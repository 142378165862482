import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { DatePipe } from '@angular/common';

export function dateToNgbDate(date: Date): NgbDate {
  if (!date) {
    return undefined;
  }
  return new NgbDate(date.getFullYear(), date.getMonth() + 1, date.getDate());
}

export function ngbDateToDate(ngbDate: NgbDate) {
  return new Date(ngbDate.year, ngbDate.month - 1, ngbDate.day);
}

export function dateToString(date: Date): string {
  const pipe = new DatePipe('en-US');
  return pipe.transform(date, 'yyyy-MM-dd');
}

export function ngbDateToString(ngbDate: NgbDate): string {
  return dateToString(ngbDateToDate(ngbDate));
}

export function extractDate(datetime: Date): Date {
  if (!datetime) {
    return undefined;
  }
  return new Date(datetime.getFullYear(), datetime.getMonth(), datetime.getDate());
}
