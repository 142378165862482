import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { map, Observable, timeout } from "rxjs";
import { Document, DocumentSerializer } from "./document.model";

@Injectable({
  providedIn: 'root',
})
export class DocumentService {
  
  // API available at: /api/v1/legal-documents/<str:type>
  static DOCUMENT_API = "/api/v1/legal-documents/"

  // TimeoutError is raised if we don't get a response after this time
  static FETCH_DOCUMENT_TIMEOUT__MS = 30*1000;

  constructor(
      private http: HttpClient,
      private documentSerializer: DocumentSerializer,
  ) {}

  fetchDocument(type: string): Observable<Document> {
    return this.http.get<Document>(DocumentService.DOCUMENT_API + type)
    .pipe(timeout(DocumentService.FETCH_DOCUMENT_TIMEOUT__MS))
    .pipe(
      map((document: Document) => {
        return this.documentSerializer.deserialize(document);
      })
    );
  }
}
