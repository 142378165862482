import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbCollapse, NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { Event } from 'src/app/events/event.model';
import { ToastService } from 'src/app/shared/components/toasts/toast.service';
import { SelectTableModalComponent } from 'src/app/tables/select-table-modal/select-table-modal.component';
import { Table } from '../../../tables/table.model';


export const TABLE_FOUND = "Table found";
export const GO_TO_TABLE_EVENT = "Go to event in table";

@Component({
  selector: 'app-booking-select-table',
  templateUrl: './booking-select-table.component.html',
  styleUrls: ['./booking-select-table.component.scss']
})
export class BookingSelectTableComponent implements OnInit {
  @Input() event: Event;

  // NOTE: selectedTable !== null ONLY IF the table has been validated with the user input.
  @Input() selectedTable?: Table | null = null;

  @Output() selectedTableEventEmitter = new EventEmitter<Table | null>();
  @Output() goToTableEventEventEmitter = new EventEmitter<Table | null>();

  isTableCodeHelpCollapsed: NgbCollapse;

  private modalRef: NgbModalRef | null = null;
  
  constructor(
    private readonly modalService: NgbModal,
    private readonly toastService: ToastService,
  ) { }

  ngOnInit(): void {
  }

  private showModalView(event: Event): NgbModalRef {
    const modalRef = this.modalService.open(SelectTableModalComponent, {
      windowClass: 'modal',
      centered: true,
    });
    modalRef.componentInstance.event = event;
    return modalRef;
  }

  openSelectTableModal(): void {
    this.modalRef = this.showModalView(this.event);

    this.modalRef.result.finally(() => this.modalRef = null);

    const modalComponent = this.modalRef.componentInstance;

    // TODO: analytics call
    modalComponent.selectedTableEventEmitter.pipe(finalize(this.cleanupModal)).subscribe(
      (t) => {
        this.selectedTable = t;
        this.toastService.success("Table successfully linked to booking.");
        this.modalRef.close(TABLE_FOUND);
        this.selectedTableEventEmitter.emit(this.selectedTable);
      },
    );
    // TODO: analytics call
    modalComponent.goToTableEventEventEmitter.pipe(finalize(this.cleanupModal)).subscribe(
      (t) => {
        this.modalRef.close(GO_TO_TABLE_EVENT);
        this.goToTableEventEventEmitter.emit(t);
      },
    );
  }

  private cleanupModal() {
    this.modalRef = null;
  }

  removeTable() {
    // TODO: analytics call
    this.selectedTable = null;
    this.selectedTableEventEmitter.emit(this.selectedTable);
  }
}
