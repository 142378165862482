import { Component, Input, OnInit } from '@angular/core';
import { BookingFlowState } from '../booking-flow-state.model';
import { Discount, Event } from '../../events/event.model';
import { User } from 'src/app/auth/user.model';
import { AuthService } from 'src/app/auth/auth.service';
import { EventShareService } from '../../events/event-share/event-share.service';

@Component({
  selector: 'app-booking-confirmation',
  templateUrl: './booking-confirmation.component.html',
  styleUrls: ['./booking-confirmation.component.scss'],
})
export class BookingConfirmationComponent implements OnInit {
  @Input() event: Event;
  @Input() state: BookingFlowState;

  user: User;

  constructor(
    private authService: AuthService,
    private eventShareService: EventShareService,
  ) {}

  ngOnInit(): void {
    this.user = this.authService.user.value;
  }

  shareEvent() {
    this.eventShareService.showModalView(this.event);
  }
}
