import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, NgForm, Validators } from '@angular/forms';
import { NgbActiveModal, NgbCollapse } from '@ng-bootstrap/ng-bootstrap';
import { finalize } from 'rxjs/operators';
import { Event } from 'src/app/events/event.model';
import { ToastService } from 'src/app/shared/components/toasts/toast.service';
import { Table } from '../table.model';
import { TableService, TableNotFoundError } from '../table.service';

@Component({
  selector: 'app-select-table-modal',
  templateUrl: './select-table-modal.component.html',
  styleUrls: ['./select-table-modal.component.scss']
})
export class SelectTableModalComponent implements OnInit {
  @Input() event: Event;

  // NOTE: selectedTable !== null ONLY IF the table has been validated with the user input.
  @Input() selectedTable?: Table | null = null;

  @Output() selectedTableEventEmitter = new EventEmitter<Table | null>();
  @Output() goToTableEventEventEmitter = new EventEmitter<Table | null>();

  // flag true if while we fetch a table via the table joining code (waiting response from API call) 
  resolvingTable: boolean = false;

  inputTableJoiningCode: string = "";

  joinTableForm!: FormGroup;

  constructor(
    public activeModal: NgbActiveModal,
    private tableService: TableService,
    private toastService: ToastService,
  ) { }

  ngOnInit(): void {
    this.joinTableForm = new FormGroup({
      tableCode: new FormControl(
        this.inputTableJoiningCode,
        [
          Validators.required,
        ]
      )
    })
  }

  onSubmit() {
    this.resolvingTable = true;

    this.tableService.loadTable(this.tableCode.value.trim(), this.event)
      .pipe(finalize(
        () => {
          this.resolvingTable = false;
        }
      ))
      .subscribe(
        t => {
          this.inputTableJoiningCode = t.joining_code;  // FIXME: makes sense only with test data
          this.selectedTable = t;

          if (t.event_id !== this.event.id) {
            const c = this.tableCode;
            c.setErrors(Object.assign(c.errors || {}, { "wrongEvent": true }));
          }

          if (this.joinTableForm.valid) {
            // the table is valid, emit to parent component for use and close modal view
            this.selectedTableEventEmitter.emit(this.selectedTable);
          }
        },
        (err) => {
          if (err instanceof TableNotFoundError) {
            const c = this.tableCode;
            c.setErrors(Object.assign(c.errors || {}, { "tableNotFound": true }));
            return;
          } else {
            const c = this.tableCode;
            c.setErrors(Object.assign(c.errors || {}, { "couldNotResolveTable": true }));

            // assuming all errors have an associated error message in the user's locale.
            this.toastService.error(err.message);
          }
        },
      );
  }

  goToTableEvent(table: Table) {
    this.goToTableEventEventEmitter.emit(table);
  }

  get tableCode() { return this.joinTableForm.get('tableCode'); }
}
