<div
    class="card bg-secondary mb-1"
    (click)="toggleCollapse()"
>
    <div class="card-header bg-secondary">
        <div class="float-right">
            <span *ngIf="isCollapsed" class="btn-inner--icon"><i class="fa fa-caret-down"></i></span>
            <span *ngIf="!isCollapsed" class="btn-inner--icon"><i class="fa fa-caret-up"></i></span>
        </div>
        <h5>{{booking.event.name}} &#64; {{booking.event.place.name}}</h5>
        <div>
            <span
                class="badge badge-pill badge-success mr-2"
                [ngClass]="{
                    'badge-success': !booking.is_cancelled,
                    'badge-danger': booking.is_cancelled
                }"
            >{{booking.getStatus()}}</span>
            <span class="badge badge-pill badge-secondary mr-2">{{booking.schedule.start_datetime | date: 'shortDate'}}</span>
            <span class="badge badge-pill badge-secondary mr-2">{{booking.people_nb}} <i class="ni ni-single-02"></i></span>
        </div>
    </div>
    <div
        class="card-body"
        [ngClass]="{ 'd-none': isCollapsed }"
        (click)="$event.stopPropagation()"
    >
        <div
            class="card-text"
        >
            <table class="table table-sm">
                <tbody>
                  <tr>
                    <th class="border-top-0" i18n>Booking code</th>
                    <td class="border-top-0">{{booking.code}}</td>
                  </tr>
                  <tr>
                    <th i18n>Status</th>
                    <td>{{booking.getStatus()}}</td>
                  </tr>
                  <tr>
                    <th i18n>Creation date</th>
                    <td>{{booking.created_datetime | date: 'longDate'}} {{booking.created_datetime | date: 'shortTime'}}</td>
                  </tr>
                  <tr>
                    <th i18n>Schedule</th>
                    <td>{{booking.schedule.start_datetime | date: 'longDate'}} {{booking.schedule.start_datetime | date: 'shortTime'}}</td>
                  </tr>
                  <tr>
                    <th i18n>Participants</th>
                    <td>{{booking.people_nb}}</td>
                  </tr>
                  <tr>
                    <th i18n>Price</th>
                    <td>
                        <del class="text-muted mr-2" *ngIf="booking.event.original_price !== booking.event.current_price">{{booking.event.original_price * booking.people_nb}}</del>
                        <span>{{booking.event.current_price * booking.people_nb}} EUR</span>
                    </td>
                  </tr>
                  <tr>
                    <th i18n>Table</th>
                    <td>
                        {{booking.table?.joining_code}}
                    </td>
                  </tr>
                  <tr>
                    <th i18n>Notes</th>
                    <td>
                        {{booking.payment?.notes}}
                    </td>
                  </tr>
                </tbody>
              </table>
        </div>
    </div>
</div>
